import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { TGetCompany, TUseGetCompanyById } from '../../types/api/company';

const url = '/api/company/:id';

const useGetCompanyById: TUseGetCompanyById = (id, options) => {
  const result = useQuery({
    queryKey: [url, id],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as TGetCompany),
    isLoading,
    isFetching,
  };
};

export default useGetCompanyById;
