import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'antd';

import { useGetStaticInfoByType, useCreateStatement } from '../../../../api';
import { AsnForm, AsnButton, Spinner, SuccessModal, WorkView } from '../../../../components';
import { validate_messages, PATHS, COLORS } from '../../../../constants';
import ChooseForm from '../ChooseForm';
import { ICreateTrainingVariables } from '../../../../types/company';
import JobForm from '../JobForm';
import { IWorkStatement, TGetStatement } from '../../../../types/api/company';

const { AZURE } = COLORS;

const Job: React.FC = () => {
  const [error, setError] = useState({
    responsibilities: false,
    skills: false,
  });
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();

  const { data, isFetching } = useGetStaticInfoByType('WORK', { enabled: true, staleTime: 1000 * 60 * 60 });
  const { mutate: createStatement, isLoading } = useCreateStatement();

  const onFinishFailed = (): void => {
    const responsibilities = form.getFieldError('responsibilities');
    const skills = form.getFieldError('skills');
    if (responsibilities?.length || skills?.length) {
      setError({ responsibilities: !!responsibilities?.length, skills: !!skills?.length });
    }
  };

  const onFinish = (values: ICreateTrainingVariables): void => {
    createStatement(
      { statementData: values, type: 'WORK' },
      {
        onSuccess: (): void => {
          setOpenSuccessModal(true);
        },
      },
    );
  };

  const handleAdd = (): void => {
    setOpenSuccessModal(false);
    form.resetFields();
  };

  const WorkViewModalData = {
    workStatement: {
      ...(form.getFieldsValue() as IWorkStatement),
      region: {
        title: data?.regions?.filter((region) => region.value === form.getFieldValue('regionId'))?.[0]?.label,
      },
      level: {
        title: data?.workLevel?.filter((level) => level.value === form.getFieldValue('levelId'))?.[0]?.label,
      },
      employment: {
        title: data?.employment?.filter((employment) => employment.value === form.getFieldValue('employmentId'))?.[0]
          ?.label,
      },
    },
    company: {
      photo: data?.company?.photo,
    },
  };
  const handleClose = (): void => {
    setOpenSuccessModal(false);
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
    form.resetFields();
  };

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <Row style={{ paddingTop: 20 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 20, pull: 2, push: 2 }}>
          <ChooseForm radioValue={'WORK'} />
          <AsnForm
            layout='vertical'
            validateMessages={validate_messages}
            onFinish={(val): void => onFinish(val as ICreateTrainingVariables)}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <JobForm data={data} error={error} setError={setError} />
            <Row justify={'end'} style={{ padding: '24px 0px', gap: 16 }}>
              <AsnButton onClick={(): void => setOpenViewModal(true)}>Դիտել</AsnButton>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Հրապարակել
              </Button>
            </Row>
          </AsnForm>
        </Col>
      </Row>
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => handleClose()}
          content={
            <>
              Շնորհավորում ենք Ձեր հայտարարությունը հրապարակվել է <span style={{ color: `${AZURE}` }}>ZINHUB.am</span>{' '}
              հարթակում
            </>
          }
          width='auto'
        >
          <Row style={{ marginTop: 20, gap: 16 }}>
            <AsnButton onClick={(): void => navigate(PATHS.COMPANY_ANNOUNCMENTS)}>Վերադառնալ իմ էջ</AsnButton>
            <Button type='primary' onClick={(): void => handleAdd()}>
              Տեղադրել այլ հայտարարություն
            </Button>
          </Row>
        </SuccessModal>
      )}
      {openViewModal && (
        <Modal
          centered
          open={openViewModal}
          onCancel={(): void => setOpenViewModal(false)}
          footer={false}
          width={'80vw'}
          bodyStyle={{ height: '80vh', overflow: 'auto', marginTop: 20 }}
        >
          <WorkView work={WorkViewModalData as TGetStatement} modal={true} />
        </Modal>
      )}
    </>
  );
};

export default Job;
