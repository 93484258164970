import React, { useState, useRef } from 'react';
import { Row, Col, Image, Upload, Space, Typography, Input, InputRef } from 'antd';

import { AsnForm } from '../../../components';
import { COLORS } from '../../../constants';
import { ReactComponent as AddBackgroundSvg } from '../../../assets/icons/addPhoto_bg.svg';
import { ReactComponent as AddPhotoSvg } from '../../../assets/icons/addPhoto.svg';
import { ReactComponent as EditSvg } from '../../../assets/icons/editCompany.svg';
import { ReactComponent as CloseSvg } from '../../../assets/icons/close.svg';
import { ReactComponent as CheckSvg } from '../../../assets/icons/check.svg';
import { IHeaderCompany } from '../../../types/company';
import { uploadPropsImage } from '../../../helpers';

const { Text, Paragraph } = Typography;
const { GRAY, WHITE } = COLORS;

const Header: React.FC<IHeaderCompany> = ({
  urlBg,
  setUrlBg,
  urlPhoto,
  setUrlPhoto,
  backgroundImage,
  photo,
  name,
  editCompanyName,
  setEditCompanyName,
}) => {
  const [editBg, setEditBg] = useState<boolean>(false);

  const formCompanyName = AsnForm.useFormInstance();

  const inputRef = useRef<InputRef | null>(null);

  const editUploadBackground = (e: { stopPropagation: () => void }): void => {
    e.stopPropagation();
    setEditBg(true);
  };

  const handleEdit = (): void => {
    setEditCompanyName(true);
    setTimeout(() => {
      inputRef.current?.focus({
        cursor: 'end',
      });
    }, 0);
  };

  const confirmName = (): void => {
    const error = formCompanyName.getFieldError('name');
    if (!error.length || error[0] === 'Խնդրում ենք հաստատել') {
      setEditCompanyName(false);
    }
  };

  const closeNameField = (): void => {
    formCompanyName.resetFields();
    setEditCompanyName(false);
  };

  return (
    <Row style={{ paddingTop: 35, position: 'relative', height: 390, marginBottom: 40 }}>
      <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
        <Image
          preview={false}
          src={urlBg ?? backgroundImage ?? '/assets/company_bg.png'}
          style={{ borderRadius: 10, objectFit: 'cover' }}
          height={'280px'}
          width={'100%'}
        />
        <Row style={{ position: 'absolute', top: 8, background: `${WHITE}`, right: 8 }}>
          <Upload {...uploadPropsImage(setUrlBg, setEditBg)}>
            {editBg || !backgroundImage ? (
              <Space
                style={{
                  cursor: 'pointer',
                  border: `1px solid  ${GRAY}`,
                  borderRadius: 4,
                  padding: '4px 8px',
                }}
              >
                <AddBackgroundSvg />
                <Text>Ավելացնել ետնանկար</Text>
              </Space>
            ) : (
              <Row style={{ padding: 12, background: `${WHITE}` }} onClick={editUploadBackground}>
                <EditSvg style={{ cursor: 'pointer' }} />
              </Row>
            )}
          </Upload>
        </Row>
        <Row style={{ position: 'absolute', bottom: 0, width: '100%', left: 40 }} align={'bottom'}>
          <Col
            style={{
              cursor: 'pointer',
              border: photo ? '' : `1px solid  ${GRAY}`,
              borderRadius: 6,
              maxWidth: 160,
              width: 160,
              height: 160,
              background: '#FFFF',
            }}
          >
            <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
              <Upload {...uploadPropsImage(setUrlPhoto)}>
                {!urlPhoto && !photo ? (
                  <AddPhotoSvg />
                ) : (
                  <Image preview={false} src={urlPhoto ?? photo} width={160} height={160} style={{ borderRadius: 6 }} />
                )}
              </Upload>
            </Row>
          </Col>
          <Col span={18} style={{ marginLeft: 16 }}>
            <Space direction={!editCompanyName ? 'horizontal' : 'vertical'} style={{ width: '100%' }}>
              {editCompanyName && (
                <AsnForm.Item
                  name='name'
                  rules={[
                    { required: true, message: 'Խնդրում ենք լրացնել Դաշտը' },
                    { min: 2, max: 50, message: 'Դաշտը պետք է պարունակի 2-50 նիշ' },
                  ]}
                  initialValue={name}
                  style={{ marginBottom: 0 }}
                  normalize={(val: string): string => val.replace(/\s+/g, ' ').trimStart()}
                >
                  <Input disabled={!editCompanyName} ref={inputRef} style={{ maxWidth: 280 }} />
                </AsnForm.Item>
              )}
              {!editCompanyName && (
                <Paragraph style={{ marginBottom: 0 }}>{formCompanyName?.getFieldValue('name') ?? name}</Paragraph>
              )}
              {!editCompanyName && <EditSvg style={{ cursor: 'pointer' }} onClick={handleEdit} />}
              {editCompanyName && (
                <Space
                  direction='horizontal'
                  style={{ maxWidth: 280, justifyContent: 'end', width: '100%', position: 'absolute' }}
                >
                  <CloseSvg style={{ cursor: 'pointer' }} onClick={(): void => closeNameField()} />
                  <CheckSvg style={{ cursor: 'pointer' }} onClick={(): void => confirmName()} />
                </Space>
              )}
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
