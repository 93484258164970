import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/plots';

import {
  IOtherStatisticByRegions,
  IStatisticByRegions,
  ITrainingStatisticByFields,
  ITrainingStatisticByRegions,
  IWorkStatisticByFields,
  IWorkStatisticByRegions,
} from '../../../api/admin/types/statistics';

const ChartPie: React.FC<{
  pieData:
    | ITrainingStatisticByRegions[]
    | IOtherStatisticByRegions[]
    | IWorkStatisticByRegions[]
    | IStatisticByRegions[]
    | ITrainingStatisticByFields[]
    | IWorkStatisticByFields[];
}> = ({ pieData }) => {
  const [data, setData] = useState<
    | ITrainingStatisticByRegions[]
    | IOtherStatisticByRegions[]
    | IWorkStatisticByRegions[]
    | IStatisticByRegions[]
    | ITrainingStatisticByFields[]
    | IWorkStatisticByFields[]
  >([]);

  useEffect(() => {
    setData(pieData);
  }, [pieData]);

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'spider',
      labelHeight: 28,
      content: '{name}\n{percentage}',
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};

export default ChartPie;
