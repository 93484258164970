import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { IStatisticsDataResult, TUseGetStatistics } from '../types/statistics';

const url = '/api/dashboard/info';

const useGetStatisticsInfo: TUseGetStatistics = (options) => {
  const result = useQuery({
    queryKey: [url],
    queryFn: () => client.get(url),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching } = result;

  return {
    data: isSuccess ? data : ({} as IStatisticsDataResult),
    isLoading,
    isFetching,
  };
};

export default useGetStatisticsInfo;
