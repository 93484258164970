import { Menu } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { TEXT_COLOR, AZURE } = COLORS;

const AsnLeftMenu = styled(Menu)`
  height: 100%;
  padding-top: 30px;
  .ant-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0;
    margin-inline: 0;
    margin-block: 0;
    height: 60px;
  }
  .ant-menu-title-content {
    font-size: 16px;
    color: ${TEXT_COLOR};
  }
  .ant-menu-item-selected {
    border-right: 2px solid ${AZURE};
    .ant-menu-title-content {
      font-weight: 600;
    }
  }
`;

export default AsnLeftMenu;
