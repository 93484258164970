import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { CompanyView } from '../../../../components';
import { useGetCompanyById } from '../../../../api';

const CompanyViewAdmin: React.FC = () => {
  const { id } = useParams();
  const { data: company, isFetching: isFetchingCompany } = useGetCompanyById(id as string, {
    enabled: Boolean(id),
  });

  return (
    <>
      {!isFetchingCompany ? (
        <CompanyView company={company} />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin tip='Loading' size='large' />
        </div>
      )}
    </>
  );
};
export default CompanyViewAdmin;
