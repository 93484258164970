import { useSwiper } from 'swiper/react';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { CircleButton } from '../../../components';

const SwiperButtonPrevCourse: React.FC = () => {
  const swiper = useSwiper();
  return (
    <CircleButton
      type='primary'
      shape='circle'
      onClick={(): void => swiper?.slidePrev()}
      icon={<ArrowLeftOutlined />}
    />
  );
};

export default SwiperButtonPrevCourse;
