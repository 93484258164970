import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image, Typography, Space, Badge, Button, Modal, Form } from 'antd';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';

import { AUTH_KEYS, COLORS, PATHS, STATUS, dateFormat } from '../../constants';
import AsnButton from '../Button';
import { ICourseView } from '../../types/components';
import LecturersCard from '../LecturersCard';
import useBlockAnnouncement from '../../api/admin/announcements/useBlockAnnoncement';
import RejectionReasonContent from '../Admin/Requests/RejectionReasonContent';
import FinishModal from '../FinishModal';
import ActionModal from '../ActionModal';
import SuccessModal from '../SuccessModal';
import { useDeleteStatementById } from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { IUserDetails } from '../../types/auth';
import { ReactComponent as EditSvg } from '../../assets/icons/editBlue.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/deleteRed.svg';
import { ReactComponent as SuccessSvg } from '../../assets/icons/successoOrange.svg';
import { ReactComponent as BackSvg } from '../../assets/icons/Back.svg';

const { Title, Paragraph } = Typography;
const { GREEN, TEXT_COLOR, AZURE, RED, RED_LIGHT, ORANGE_DARK, CORAL, GRAY } = COLORS;

const width = window.innerWidth;

const CourseView: React.FC<ICourseView> = ({ course, isPrivate, modal }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [openReject, setOpenReject] = useState<string>('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [openFinishModal, setOpenFinishMOdal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const localStorageUser = JSON.parse(localStorage.getItem(AUTH_KEYS.USER) as string) as IUserDetails;
  const role = localStorageUser?.role;
  const { user } = useAuth();
  const { mutate: blockAnnouncement } = useBlockAnnouncement({
    onSuccess: () => {
      setDisabledButton(false);
      setOpenReject('');
      void queryClient.invalidateQueries(['/api/statements/:id', course.id]);
    },
  });

  const onBlockCourse = (): void => {
    setOpenReject(course.id);
  };

  const onFinish = (value: { rejectionText: string }): void => {
    setDisabledButton(true);
    blockAnnouncement({
      id: openReject,
      ...value,
    });
  };

  const onClosRejectModal = (): void => {
    setOpenReject('');
    form.resetFields();
  };

  const { mutate: deleteCourse } = useDeleteStatementById();
  const navigate = useNavigate();

  const onDelete = (): void => {
    deleteCourse(course.id, {
      onSuccess: () => {
        setOpenSuccessModal(true);
      },
    });
  };

  const closeSuccessModal = (): void => {
    setOpenSuccessModal(false);
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
  };

  const onBackClick = (): void => {
    navigate(-1);
  };

  const handleNavigateCompany = (): void => {
    if (user?.id && user?.role !== 'COMPANY') {
      navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${course?.company?.id}`);
    }
    if (!user?.id) {
      navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${course?.company?.id}`);
    }
  };

  return (
    <>
      <Row style={{ paddingTop: 35 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          {!modal && (
            <div style={{ cursor: 'pointer', marginBottom: 16 }} onClick={onBackClick}>
              <BackSvg />
            </div>
          )}
          <Row style={{ marginBottom: 16 }} gutter={32}>
            <Col xxl={{ span: 16 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 15 }} sm={{ span: 24 }}>
              <Space
                direction={width <= 667 ? 'vertical' : 'horizontal'}
                style={{ alignItems: width <= 667 ? 'center' : 'flex-start', marginBottom: 48, width: '100%' }}
              >
                <Image
                  preview={false}
                  src={course?.company?.photo}
                  style={{ borderRadius: 6, objectFit: 'cover' }}
                  height={'160px'}
                  width={width <= 667 ? '80vw' : '160px'}
                />
                <Space direction='vertical' size={12} style={{ justifyContent: 'space-between' }}>
                  <Title
                    level={3}
                    style={{ marginBottom: 0 }}
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: <span style={{ fontSize: 16 }}>Տեսնել ավելին</span>,
                    }}
                  >
                    {course?.trainingStatement?.title}
                    {!role ? null : (
                      <Badge
                        text={
                          course?.status === 'BLOCKED'
                            ? 'Արգելափակված'
                            : course?.status === 'DONE'
                            ? 'Ավարտված'
                            : course?.status === 'INACTIVE'
                            ? 'Ընթացիկ'
                            : 'Նոր'
                        }
                        color={
                          course?.status === 'BLOCKED'
                            ? `${CORAL}`
                            : course?.status === 'DONE'
                            ? `${GRAY}`
                            : course?.status === 'INACTIVE'
                            ? `${AZURE}`
                            : `${GREEN}`
                        }
                        style={{ alignItems: 'center', color: `${TEXT_COLOR}`, fontWeight: 400, marginLeft: 16 }}
                      />
                    )}
                  </Title>
                  <Paragraph
                    style={{ color: `${AZURE}`, marginBottom: '0.5em', cursor: 'pointer' }}
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                    }}
                    onClick={(): void => handleNavigateCompany()}
                  >
                    {course?.company?.name}
                  </Paragraph>
                  {isPrivate && (
                    <>
                      <Space direction='horizontal' wrap>
                        {course?.status === 'ACTIVE' && (
                          <AsnButton
                            icon={<EditSvg />}
                            onClick={(): void => navigate(PATHS.COURSE_EDIT.replace(':id', course?.id))}
                          >
                            Խմբագրել
                          </AsnButton>
                        )}
                        {(course?.status === 'ACTIVE' || course?.status === 'INACTIVE') && (
                          <Button type='primary' onClick={(): void => setOpenFinishMOdal(true)}>
                            Ավարտել
                          </Button>
                        )}
                        {course?.status === 'ACTIVE' && (
                          <AsnButton
                            icon={<DeleteSvg />}
                            style={{ borderColor: `${RED}`, color: `${RED}` }}
                            color={RED_LIGHT}
                            onClick={(): void => setOpenDeleteModal(true)}
                          >
                            Ջնջել
                          </AsnButton>
                        )}
                      </Space>
                    </>
                  )}
                  {role === 'SUPER_ADMIN' || role === 'ADMIN' ? (
                    <Space direction='horizontal' align='center'>
                      {!(user?.permission === 'VIEW' && user.role === 'ADMIN') && (
                        <>
                          {course.status === STATUS.ACTIVE ? (
                            <AsnButton type='primary' onClick={onBlockCourse}>
                              Արգելափակել
                            </AsnButton>
                          ) : null}
                        </>
                      )}
                      <span
                        style={{
                          color: TEXT_COLOR,
                        }}
                      >{`Ստեղծված՝ ${dayjs(course?.trainingStatement?.createdAt ?? '')
                        .utc()
                        .format(dateFormat)}`}</span>
                    </Space>
                  ) : null}
                  {!role ? (
                    <Button
                      type='primary'
                      style={{ width: width <= 667 ? '100%' : 'auto' }}
                      href={`mailto:${course?.trainingStatement?.registrationLink}`}
                    >
                      Դիմել հիմա
                    </Button>
                  ) : null}
                </Space>
              </Space>
              <div
                className='list'
                dangerouslySetInnerHTML={{ __html: course?.trainingStatement?.description || '' }}
                style={{ fontSize: 16, color: `${TEXT_COLOR}`, wordBreak: 'break-word' }}
              />
            </Col>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 9 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Space
                direction='vertical'
                style={{ background: 'rgba(31, 130, 255, 0.04)', padding: '4%', width: '100%' }}
                size={12}
              >
                <Title level={5}>Դասընթացի մանրամասները</Title>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Ձևաչափը՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {course?.trainingStatement?.format?.title}
                  </Paragraph>
                </Space>
                {course?.trainingStatement?.region?.title && (
                  <Space>
                    <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>
                      Գտնվելու վայրը (Մարզ)՝
                    </Paragraph>
                    <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                      {course?.trainingStatement?.region?.title}
                    </Paragraph>
                  </Space>
                )}
                {course?.trainingStatement?.location && (
                  <Space>
                    <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Հասցե՝</Paragraph>
                    <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                      {course?.trainingStatement?.location}
                    </Paragraph>
                  </Space>
                )}
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Հեռախոս՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {course?.trainingStatement?.phone}
                  </Paragraph>
                </Space>
                <div
                  style={{
                    borderRadius: '2px',
                    background:
                      'linear-gradient(90deg, rgba(31, 130, 255, 0.20) 0%, #1F82FF 51.2%, rgba(31, 130, 255, 0.20) 100%)',
                    height: '2px',
                  }}
                />
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>Դասընթացի մեկնարկը՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {dayjs(course?.trainingStatement?.startDate)
                      .utc()
                      .format(dateFormat)}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>Դիմելու վերջնաժամկետ՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {dayjs(course?.trainingStatement?.applicationDeadline)
                      .utc()
                      .format(dateFormat)}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Տևողությունը՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {course?.trainingStatement?.duration}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Դասաժամեր՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {course?.trainingStatement?.classHours}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>
                    Դասընթացի Մակարդակ՝
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {course?.trainingStatement?.level?.title}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>
                    Դասընթացի Լեզու՝
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {course?.trainingStatement?.language?.title}
                  </Paragraph>
                </Space>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col xxl={{ span: 16 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 16 }} sm={{ span: 24 }}>
              <Title level={5}>Ծրագիր</Title>
              <div
                className='list'
                dangerouslySetInnerHTML={{ __html: course?.trainingStatement?.program || '' }}
                style={{ marginBottom: 48, fontSize: 16, color: `${TEXT_COLOR}` }}
              />
            </Col>
          </Row>
          <Title level={5}>Ի՞նչ եք սովորելու</Title>
          <Row gutter={[16, 16]}>
            {course?.trainingStatement?.topics?.map((topic, i) => (
              <Col
                span={8}
                key={i}
                xxl={{ span: 8 }}
                xl={{ span: 8 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <Space
                  direction='horizontal'
                  style={{
                    borderRadius: 4,
                    border: `1px solid ${ORANGE_DARK}`,
                    padding: '5px 5px 5px 10px',
                    width: '100%',
                    minHeight: 60,
                  }}
                >
                  <SuccessSvg />
                  <Paragraph
                    style={{ marginBottom: 0 }}
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                    }}
                  >
                    {topic}
                  </Paragraph>
                </Space>
              </Col>
            ))}
          </Row>
          <Row style={{ padding: '48px 0px 24px 0px' }}>
            <Col span={24}>
              {!!course?.trainingStatement?.teachers?.length &&
                course?.trainingStatement?.teachers?.map((teacher) => (
                  <LecturersCard key={teacher.id} lecturersData={teacher} />
                ))}
            </Col>
          </Row>
        </Col>
      </Row>
      {!role && width <= 667 ? (
        <div style={{ padding: '4%' }}>
          <Button
            type='primary'
            style={{ width: '100%' }}
            href={`mailto:${course?.trainingStatement?.registrationLink}`}
          >
            Դիմել հիմա
          </Button>
        </div>
      ) : null}
      <Modal centered open={openReject !== ''} onCancel={onClosRejectModal} footer={false} width={'40vw'}>
        <Form
          name='form_items'
          onFinish={onFinish}
          form={form}
          autoComplete='off'
          layout='vertical'
          style={{
            width: '100%',
            margin: '2rem 0 3rem',
          }}
        >
          <RejectionReasonContent
            rejectFn={onClosRejectModal}
            disabledButton={disabledButton}
            title='Համոզված եք, որ ցանկանում եք արգելափակել հայտարարությունը'
          />
        </Form>
      </Modal>
      {openFinishModal && (
        <FinishModal open={openFinishModal} setOpen={setOpenFinishMOdal} id={course?.id} type={'TRINING'} />
      )}
      {openDeleteModal && (
        <Modal centered open={openDeleteModal} onCancel={(): void => setOpenDeleteModal(false)} footer={false}>
          <ActionModal
            text={`Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել Ձեր  հայտարարությունը`}
            resolveFn={onDelete}
            rejectFn={(): void => setOpenDeleteModal(false)}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => closeSuccessModal()}
          content='Ձեր հայտարարությունը հաջողությամբ հեռացվել է'
        >
          <AsnButton style={{ marginTop: 20 }} onClick={(): void => navigate(PATHS.COMPANY_ANNOUNCMENTS)}>
            Վերադառնալ իմ էջ
          </AsnButton>
        </SuccessModal>
      )}
    </>
  );
};

export default React.memo(CourseView);
