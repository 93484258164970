import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../../client';
import { ISuccesMessage } from '../../../types/global';
export const url = 'api/auth/email/resend';

export interface IParamsAdmin {
  email: string;
}
export type TUseResendAdmin = () => UseMutationResult<AxiosResponse<ISuccesMessage>, AxiosError, IParamsAdmin, unknown>;

const useResendAdmin: TUseResendAdmin = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, IParamsAdmin, unknown>({
    mutationFn: (params) => {
      return client.post(url, params);
    },
  });

  return mutation;
};

export default useResendAdmin;
