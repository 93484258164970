import styled from 'styled-components';

export const LecturersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 80px 20px 40px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;
