import { MenuProps } from 'antd';
import { Rule } from 'antd/es/form';

import { ReactComponent as DataSvg } from '../assets/icons/data.svg';
import { ReactComponent as AnnouncementsSvg } from '../assets/icons/announcements.svg';
import { ReactComponent as LecturersSvg } from '../assets/icons/lecturers.svg';
import { ReactComponent as UsersGroupIcon } from '../assets/icons/users-group.svg';
import { ReactComponent as Requests } from '../assets/icons/requests.svg';
import { ReactComponent as Stories } from '../assets/icons/stories.svg';
import { ReactComponent as Statistics } from '../assets/icons/statistics.svg';

export const PATHS = {
  LANDING: '/',
  HELP: '/help',
  COURSE: '/course',
  COURSE_VIEW_PUBLIC: '/course/view/',
  OTHER: '/other',
  OTHER_VIEW_PUBLIC: '/other/view/',
  WORK: '/work',
  WORK_VIEW_PUBLIC: '/work/view/',
  COMPANY: '/company',
  COMPANY_ACTIVE_STATEMENTS: '/active-statements/:id',
  COMPANY_VIEW_PUBLIC: '/company/view',
  FORGOT_PASSWORD: '/forgot-password',
  SIGNIN: '/sign-in',
  SIGNUP: '/sign-up',
  CHANGE_PASSWORD: '/change-password',
  ADMIN: '/admin/users',
  ADMIN_MYINFO: '/admin/myinfo/:id',
  ADMIN_COMPANY_VIEW: '/admin/users/company/view/:id',
  ANNOUNCEMENTS: '/admin/announcements',
  ANNOUNCEMENTS_COURSE_VIEW: '/admin/announcements/view/course/:id',
  ANNOUNCEMENTS_OTHER_VIEW: '/admin/announcements/view/other/:id',
  ANNOUNCEMENTS_WORK_VIEW: '/admin/announcements/view/work/:id',
  REQUESTS: '/admin/requests',
  STORIES: '/admin/stories',
  STORIES_VIEW: '/admin/stories/:id',
  STORIES_CREATE: '/admin/stories/create',
  STORIES_EDIT: '/admin/stories/edit/:id',
  STATISTICS: '/admin/statistics',
  COMPANY_MYINFO: '/company/myinfo',
  COMPANY_MYINFO_EDIT: '/company/myinfo/edit',
  COMPANY_LECTURERS: '/company/lecturers',
  COMPANY_LECTURER_VIEW: '/company/lecturers/:id',
  COMPANY_LECTURERS_EDIT: '/company/lecturers/edit/:id',
  COMPANY_LECTURERS_CREATE: '/company/lecturers/create',
  COMPANY_ANNOUNCMENTS: '/company/announcements',
  RESTORE_PASSWORD: '/restore-password',
  ACCEPT_INVITATION: '/accept-invitation',
  VERIFY_EMAIL: '/confirm-company-email',
  COMPANY_CREATE_COURSE: '/company/announcements/create/course',
  COMPANY_CREATE_JOB: '/company/announcements/create/job',
  COMPANY_CREATE_OTHER: '/company/announcements/create/other',
  COURSE_VIEW: '/company/announcements/course/view/:id',
  JOB_VIEW: '/company/announcements/job/view/:id',
  OTHER_VIEW: '/company/announcements/other/view/:id',
  COURSE_EDIT: '/company/announcements/course/edit/:id',
  WORK_EDIT: '/company/announcements/work/edit/:id',
  OTHER_EDIT: '/company/announcements/work/other/:id',
  HOMEPAGE: '/homepage',
};

export const AUTH_KEYS = {
  USER: 'user',
  TOKEN: 'token',
};

export const COLORS = {
  BLUE: '#55969D',
  ORANGE: '#EAB65B',
  BLACK: '#333333',
  SHADOW_GRAY: '#444444',
  GRAY: '#9E9E9E',
  WHITE: '#FFFFFF',
  DARK_GRAY: '#545B5B',
  CREAM: 'rgba(255, 136, 47, 0.02)',
  AZURE: '#1F82FF',
  ALICE_BLUE: '#f0f8ff',
  WATER: '#D2E6FF',
  CORAL: '#FF4D4F',
  GREEN: '#52C41A',
  RED: '#F03738',
  RED_LIGHT: '#FFE5E4',
  ORANGE_DARK: '#FF882F',
  BLUE4: '#00008B',
  TEXT_COLOR: '#0f2b61',
};

export const menuItems: MenuProps['items'] = [
  {
    label: 'ՈՒՍՈՒՑՈՒՄ',
    key: PATHS.COURSE,
  },
  {
    label: 'ԱՇԽԱՏԱՆՔ',
    key: PATHS.WORK,
  },
  {
    label: 'ԱՅԼ',
    key: PATHS.OTHER,
  },
  {
    label: 'ԳՈՐԾԸՆԿԵՐՆԵՐ',
    key: PATHS.COMPANY,
  },
];

export const validate_messages = {
  required: 'Խնդրում ենք լրացնել դաշտը',
  string: {
    range: 'Դաշտը պետք է պարունակի ${min}-${max} նիշ',
    len: 'Դաշտը պետք է պարունակի ${len} նիշ',
    min: 'Դաշտը պետք է պարունակի առնվազն ${min} նիշ',
    max: 'Դաշտը պետք է պարունակի մինչև ${max} նիշ',
  },
};

export const passwordRegexp = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,60}$/;
export const urlRegexp = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+|([a-zA-Z0-9-]+))(\/.*)?$/;

export const passwordRules = [
  { required: true },
  {
    pattern: passwordRegexp,
    message: 'Գաղտնաբառը պետք է պարունակի առնվազն մեկ թիվ եւ մեկ տառ, 8-60 նիշ',
  },
];

export const emailRules: Rule[] = [
  { required: true, message: 'Խնդրում ենք լրացնել Էլ․հասցեն' },
  {
    type: 'email',
    message: 'Խնդրում ենք մուտքագրել էլ․ հասցեն հետևյալ ֆորմատով՝ yourname@domain.com ',
  },
];

export const menuItemsCompany: MenuProps['items'] = [
  {
    key: '/company/myinfo',
    label: 'Տվյալներ',
    icon: <DataSvg />,
  },
  {
    key: '/company/announcements',
    label: 'Հայտարարություններ',
    icon: <AnnouncementsSvg />,
  },
  {
    key: '/company/lecturers',
    label: 'Դասավանդողներ',
    icon: <LecturersSvg />,
  },
];

export const adminItems: MenuProps['items'] = [
  {
    label: 'Օգտվողներ',
    key: '/admin/users',
    icon: <UsersGroupIcon />,
  },
  {
    label: 'Հայտեր',
    key: '/admin/requests',
    icon: <Requests />,
  },
  {
    label: 'Հայտարարություններ',
    key: '/admin/announcements',
    icon: <AnnouncementsSvg />,
  },
  {
    label: 'Հաջողված պատմություն',
    key: '/admin/stories',
    icon: <Stories />,
  },
  {
    label: 'Վիճակագրություն',
    key: '/admin/statistics',
    icon: <Statistics />,
  },
];

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DONE: 'DONE',
  BLOCKED: 'BLOCKED',
};

export const PERMISSION = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};
export const tableDateFormat = 'DD/MM/YYYY HH:mm';
export const dateFormat = 'DD/MM/YYYY';

export const companyItems: MenuProps['items'] = [
  {
    key: '1',
    label: 'ԲՈԼՈՐԸ',
  },
  {
    key: 'TRINING',
    label: 'ՈՒՍՈՒՑՈՒՄ',
  },
  {
    key: 'WORK',
    label: 'ԱՇԽԱՏԱՆՔ',
  },
  {
    key: 'OTHER',
    label: 'ԱՅԼ',
  },
];
export const statuses = [
  {
    label: 'Արգելափակված',
    value: 'BLOCKED',
  },
  {
    label: 'Ավարտված',
    value: 'DONE',
  },
  {
    label: 'Ընթացիկ',
    value: 'INACTIVE',
  },
  {
    label: 'Նոր',
    value: 'ACTIVE',
  },
];
