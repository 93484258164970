import styled from 'styled-components';

export const CompanyContainer = styled.div`
  padding: 20px 15vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;
  .ant-checkbox-group {
    margin-top: 5rem;
  }

  @media (max-width: 1670px) {
    padding: 20px 10vw;
  }
  @media (max-width: 1350px) {
    padding: 20px 5vw;
  }
  @media (max-width: 667px) {
    flex-direction: column;
  }
`;
export const ItemContainer = styled.div`
  width: 100%;
`;
