import React from 'react';
import { Row, Col, Typography, Image } from 'antd';

const { Paragraph } = Typography;
const Info: React.FC<{ width: number }> = ({ width }) => {
  return (
    <Row
      style={{
        backgroundColor: '#F8FBFF',
        padding: '32px 4% 10px 4%',
      }}
    >
      <Col>
        <div
          style={{
            width: '100%',
          }}
        >
          <Image
            preview={false}
            src={'./assets/homepage.png'}
            width={'100%'}
            style={{ marginBottom: width <= 667 ? 8 : 4, objectFit: 'contain', maxHeight: 490 }}
          />
        </div>
      </Col>
      <Col
        span={14}
        style={{ paddingRight: 32 }}
        xxl={{ span: 14, push: 2 }}
        xl={{ span: 14, push: 2 }}
        lg={24}
        md={24}
        sm={24}
        xs={24}
      >
        <Paragraph>Հարգելի՛ այցելու,</Paragraph>
        <Paragraph>Ուրախ ենք ողջունել Ձեզ ԶԻՆՀԱԲ հարթակում։</Paragraph>
        <Paragraph>
          ԶԻՆՀԱԲ հարթակը նպատակ ունի համատեղել բոլորիս ներուժը զինծառայողների և նրանց ընտանիքների վերասոցիալականացման
          գործում, մասնավորապես, աջակցելու նրանց կրթության և ուսուցման, աշխատանքի և զբաղվածության, հարակից այլ
          ծառայություններ ստանալու գործում։
        </Paragraph>
        <Paragraph>
          ԶԻՆՀԱԲ հարթակում առաջարկվող ծառայություններից կարող են օգտվել ինչպես ԶԻՆԱՊԱՀ-ի շահառուները, այնպես էլ
          պատերազմի մասնակիցները և պարտադիր զինվորական ծառայությունից զորացրված երիտասարդները։
        </Paragraph>
        <Paragraph>
          ԶԻՆՀԱԲ հարթակից կտեղեկանաք նաև իրենց նպատակներին հասած Ձեր ընկերների հաջողության պատմություններին,
          հնարավորություն կունենաք գտնել նոր ընկերներ, Ձեր մասնակցությամբ ընդլայնել ԶԻՆՀԱԲ-ի համայնքը և իրար փոխանցել
          միայն դրական լիցքեր և գործելու մոտիվացիա։
        </Paragraph>
        <Paragraph>
          ԶԻՆՀԱԲ-ը ստեղծվել է ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության, ԶԻՆԱՊԱՀ-ի՝ Զինծառայողների ապահովագրության
          ազգային հիմնադրամի և Ձեռնարկությունների ինկուբատոր հիմնադրամի (ՁԻՀ) հետ համատեղ նախաձեռնությամբ,
          համագործակցելով մի շարք այլ հասարակական կազմակերպությունների, հիմնադրամների, ինչպես նաև մասնավոր հատվածի մեր
          գործընկերների հետ։
        </Paragraph>
        <Paragraph>Հարթակը բաց է բոլորի համար։</Paragraph>
        <Paragraph>
          Դե ինչ, <span style={{ fontWeight: 600 }}>միասին ուժեղ ենք և կարող</span>
        </Paragraph>
        <Paragraph>Սիրով՝</Paragraph>
        <Paragraph>ԶԻՆՀԱԲ համայնք</Paragraph>
      </Col>
    </Row>
  );
};

export default Info;
