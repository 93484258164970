import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
import { IParamsUpdateLecturers } from '../../admin/types/lecturers.tsx/types';
export const url = '/api/teacher';
const useUpdateLecturers = (options = {}): UseMutationResult<void, unknown, IParamsUpdateLecturers, unknown> =>
  useMutation(async (params: IParamsUpdateLecturers) => {
    await client.put(`${url}/${params.id}`, params.lecturersData);
  }, options);
export default useUpdateLecturers;
