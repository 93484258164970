import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Row, Col, Button, Space, Pagination, Popover, Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { PATHS, companyItems, COLORS } from '../../../constants';
import { useGetAllStatements, useGetStaticInfoByType } from '../../../api';
import { ReactComponent as FilterSvg } from '../../../assets/icons/filter.svg';
import { AsnButton, AsnForm, AsnSearch, MainCard, Spinner } from '../../../components';
import { IParamsGetAllStatements } from '../../../types/api/company';
import { IFilterVariables } from '../../../types/company';

import { filterItems } from './filterItems';

const { TEXT_COLOR } = COLORS;

const Announcements: React.FC = () => {
  const [params, setParams] = useState<IParamsGetAllStatements>({
    offset: 0,
    search: '',
    type: [],
  });
  const [search, setSearch] = useState<string>('');

  const { type } = params;

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const navigate = useNavigate();
  const [form] = AsnForm.useForm();

  const { data, isLoading } = useGetAllStatements(params, { enabled: true });

  const { data: staticData, isLoading: isLoadingStatic } = useGetStaticInfoByType(type[0], {
    enabled: true,
    staleTime: 1000 * 60 * 60,
  });

  const handlePagination = (page: number): void => {
    setParams({
      ...params,
      offset: (page - 1) * 10,
    });
  };

  const onSearch = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setParams({
      ...params,
      search: e.currentTarget.value,
      offset: 0,
    });
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      setParams({
        ...params,
        offset: 0,
        search: '',
      });
    }
    setSearch(e.target.value);
  };

  const onChangeTabs = (info: { key: string }): void => {
    const { key } = info;
    if (key === '1') {
      setParams({
        offset: 0,
        search: '',
        type: [],
      });
    } else {
      setParams({
        offset: 0,
        search: '',
        type: [key],
      });
    }
    form.resetFields();
    setSearch('');
  };

  const handleCreate = (): void => {
    if (!params?.type?.length || params?.type[0] === 'TRINING') {
      navigate(PATHS.COMPANY_CREATE_COURSE);
    }
    if (params?.type[0] === 'WORK') {
      navigate(PATHS.COMPANY_CREATE_JOB);
    }
    if (params?.type[0] === 'OTHER') {
      navigate(PATHS.COMPANY_CREATE_OTHER);
    }
  };

  const onFilter = (values: IFilterVariables): void => {
    setParams({ ...params, ...values });
    setOpenPopover(false);
  };

  const onCancelFilter = (): void => {
    form.resetFields();
    setParams({ ...params, ...(form.getFieldsValue() as IFilterVariables) });
    setOpenPopover(false);
  };

  return (
    <>
      <Row>
        <Col span={22} pull={1} push={1} xxl={{ span: 20, pull: 2, push: 2 }}>
          <Menu
            expandIcon={false}
            mode='horizontal'
            items={companyItems}
            onSelect={onChangeTabs}
            style={{ fontSize: 16, marginBottom: 16, color: `${TEXT_COLOR}` }}
            defaultSelectedKeys={['1']}
          />
          <Space direction='vertical' style={{ width: '100%' }}>
            <AsnSearch
              suffix={<SearchOutlined />}
              style={{ width: '30vw', maxWidth: 450 }}
              onPressEnter={onSearch}
              onChange={onChange}
              value={search}
            />
            <Row justify={'end'} style={{ gap: 16 }}>
              <Button type='primary' onClick={handleCreate}>
                Ավելացնել հայտարարություն
              </Button>
              {!!params?.type?.length && (
                <Popover
                  content={
                    <AsnForm layout='vertical' form={form} onFinish={(val): void => onFilter(val as IFilterVariables)}>
                      <Collapse
                        items={filterItems(staticData, type?.[0])}
                        accordion
                        bordered={false}
                        expandIconPosition={'end'}
                        style={{ width: 350 }}
                      />
                      <Space
                        direction='horizontal'
                        style={{ width: '100%', paddingTop: 16, justifyContent: 'center' }}
                        align='center'
                      >
                        <AsnButton onClick={(): void => onCancelFilter()}>Զրոյացնել</AsnButton>
                        <Button type='primary' htmlType='submit'>
                          Ֆիլտրել
                        </Button>
                      </Space>
                    </AsnForm>
                  }
                  trigger='click'
                  placement='bottom'
                  arrow={false}
                  open={openPopover}
                  onOpenChange={(open: boolean): void => setOpenPopover(open)}
                >
                  <AsnButton icon={<FilterSvg />} style={{ width: 60, justifyContent: 'center' }}></AsnButton>
                </Popover>
              )}
            </Row>
          </Space>
          {isLoading || isLoadingStatic ? (
            <Spinner />
          ) : (
            <Space direction='vertical' style={{ width: '100%', paddingBottom: 16 }}>
              {data?.result?.map((info, i) => (
                <div key={i}>
                  <MainCard info={info} />
                </div>
              ))}
            </Space>
          )}
          {!isLoading && !isLoadingStatic && !!data?.result?.length && data?.count > 10 && (
            <Row justify={'center'} style={{ padding: '32px 0px' }}>
              <Pagination
                total={data?.count}
                showSizeChanger={false}
                current={params?.offset / 10 + 1}
                onChange={handlePagination}
              />
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Announcements);
