import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Typography } from 'antd';

import { COLORS, PATHS, STATUS, tableDateFormat } from '../../../constants';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as RejectIcon } from '../../../assets/icons/reject-stories.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-stories.svg';
import { IStoriesData } from '../../../api/admin/types/stories/types';
import { useAuth } from '../../../hooks/useAuth';

const { Paragraph } = Typography;
const { WHITE, BLACK } = COLORS;
const buttonsStyle = { background: 'none', border: 'none', cursor: 'pointer' };

export const columnsStories = (
  setIsDeletedStoryId: React.Dispatch<React.SetStateAction<string>>,
  setIsActivatedStoryId: React.Dispatch<React.SetStateAction<{ id: string; status: string }>>,
): ColumnsType<IStoriesData> => {
  const navigate = useNavigate();
  const { user } = useAuth();
  return [
    {
      title: 'Վերնագիր',
      dataIndex: 'successTitle',
      ellipsis: true,
      render: (successTitle) => (
        <Paragraph
          ellipsis={true}
          style={{
            width: '12vw',
            minWidth: '150px',
          }}
        >
          {successTitle}
        </Paragraph>
      ),
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      filters: [
        {
          text: 'Ակտիվ',
          value: STATUS.ACTIVE,
        },
        {
          text: 'Պասիվ',
          value: STATUS.INACTIVE,
        },
      ],
      render: (status) => <div>{status === 'ACTIVE' ? 'Ակտիվ' : 'Պասիվ'}</div>,
    },
    {
      title: 'Ստեղծման ամսաթիվ',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: string): React.ReactNode => <div>{dayjs(createdAt).format(tableDateFormat)}</div>,
    },
    {
      title: 'Հրապարակման ամսաթիվ',
      dataIndex: 'publishedAt',
      sorter: true,
      render: (publishedAt: string): React.ReactNode => (
        <> {publishedAt && <div>{dayjs(publishedAt).format(tableDateFormat)}</div>} </>
      ),
    },
    {
      title: 'Ում կողմից',
      dataIndex: 'adminName',
    },
    {
      title: 'Գործողություն',
      key: 'operation',
      render: (row: IStoriesData) => (
        <div style={{ width: '130px' }}>
          {!(user?.permission === 'EDIT' && user.role === 'ADMIN') && (
            <>
              {row.status === 'ACTIVE' ? (
                <Tooltip
                  placement='topLeft'
                  title={'Հեռացնել'}
                  arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: WHITE,
                    color: BLACK,
                  }}
                >
                  <button
                    style={{ ...buttonsStyle }}
                    onClick={(): void =>
                      setIsActivatedStoryId({
                        id: row.id,
                        status: 'activate',
                      })
                    }
                  >
                    <RejectIcon />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip
                  placement='topLeft'
                  title={'Հրապարակել'}
                  arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: WHITE,
                    color: BLACK,
                  }}
                >
                  <button
                    style={{ ...buttonsStyle }}
                    onClick={(): void =>
                      setIsActivatedStoryId({
                        id: row.id,
                        status: 'passivate',
                      })
                    }
                  >
                    <SuccessIcon />
                  </button>
                </Tooltip>
              )}
            </>
          )}
          <Tooltip
            placement='topLeft'
            title={'Խմբագրել'}
            arrow={false}
            overlayInnerStyle={{
              backgroundColor: WHITE,
              color: BLACK,
            }}
          >
            <button
              style={{ ...buttonsStyle }}
              onClick={(): void => navigate(PATHS.STORIES_EDIT.replace(':id', row.id))}
            >
              <EditIcon />
            </button>
          </Tooltip>
          <Tooltip
            placement='topLeft'
            title={'Ջնջել'}
            arrow={false}
            overlayInnerStyle={{
              backgroundColor: WHITE,
              color: BLACK,
            }}
          >
            <button style={{ ...buttonsStyle }} onClick={(): void => setIsDeletedStoryId(row.id)}>
              <DeleteIcon />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];
};
