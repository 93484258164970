import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { TCompanyStaticValues, TUseGetCompanyInfo } from '../../types/api/static';

const url = '/api/static/company/info';

const useGetCompanyInfo: TUseGetCompanyInfo = (options) => {
  const result = useQuery({
    queryKey: [url],
    queryFn: () => client.get(url),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as TCompanyStaticValues),
    isLoading,
    isFetching,
  };
};

export default useGetCompanyInfo;
