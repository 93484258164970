import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { AcceptEmail, TUseAcceptEmail } from '../../types/api/auth';
import { ISuccesMessage } from '../../types/global';

const url = '/api/auth/email/resend';

const useResendEmail: TUseAcceptEmail = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, AcceptEmail, unknown>({
    mutationFn: (values) => {
      return client.post(url, values);
    },
  });

  return mutation;
};

export default useResendEmail;
