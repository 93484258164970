import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image, Typography, Space, Badge, Button, Modal, Form } from 'antd';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';

import { AUTH_KEYS, COLORS, STATUS, dateFormat, PATHS } from '../../constants';
import AsnButton from '../Button';
import { ReactComponent as EditSvg } from '../../assets/icons/editBlue.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/deleteRed.svg';
import { IWorkView } from '../../types/components';
import { IUserDetails } from '../../types/auth';
import { useAuth } from '../../hooks/useAuth';
import useBlockAnnouncement from '../../api/admin/announcements/useBlockAnnoncement';
import RejectionReasonContent from '../Admin/Requests/RejectionReasonContent';
import FinishModal from '../FinishModal';
import { useDeleteStatementById } from '../../api';
import ActionModal from '../ActionModal';
import SuccessModal from '../SuccessModal';
import { ReactComponent as BackSvg } from '../../assets/icons/Back.svg';

const { Title, Paragraph } = Typography;
const { GREEN, TEXT_COLOR, AZURE, RED, RED_LIGHT, CORAL, GRAY } = COLORS;

const width = window.innerWidth;

const WorkView: React.FC<IWorkView> = ({ work, isPrivate, modal }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [openReject, setOpenReject] = useState<string>('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [openFinishModal, setOpenFinishMOdal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const localStorageUser = JSON.parse(localStorage.getItem(AUTH_KEYS.USER) as string) as IUserDetails;
  const role = localStorageUser?.role;
  const { user } = useAuth();

  const navigate = useNavigate();
  const { mutate: deleteWork } = useDeleteStatementById();

  const onDelete = (): void => {
    deleteWork(work.id, {
      onSuccess: () => {
        setOpenSuccessModal(true);
      },
    });
  };

  const closeSuccessModal = (): void => {
    setOpenSuccessModal(false);
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
  };

  const { mutate: blockAnnouncement } = useBlockAnnouncement({
    onSuccess: () => {
      setDisabledButton(false);
      setOpenReject('');
      void queryClient.invalidateQueries(['/api/statements/:id', work.id]);
    },
  });

  const onBlockWork = (): void => {
    setOpenReject(work.id);
  };

  const onFinish = (value: { rejectionText: string }): void => {
    setDisabledButton(true);
    blockAnnouncement({
      id: openReject,
      ...value,
    });
  };

  const onClosRejectModal = (): void => {
    setOpenReject('');
    form.resetFields();
  };
  const onBackClick = (): void => {
    if (role === 'SUPER_ADMIN' || role === 'ADMIN') {
      navigate(PATHS.ANNOUNCEMENTS);
    } else if (role === undefined) {
      navigate(-1);
    } else {
      navigate(PATHS.COMPANY_ANNOUNCMENTS);
    }
  };

  const handleNavigateCompany = (): void => {
    if (user?.id && user?.role !== 'COMPANY') {
      navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${work?.company?.id}`);
    }
    if (!user?.id) {
      navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${work?.company?.id}`);
    }
  };

  return (
    <>
      <Row style={{ paddingTop: 35, marginBottom: 48 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          {!modal && (
            <div style={{ cursor: 'pointer', marginBottom: 16 }} onClick={onBackClick}>
              <BackSvg />
            </div>
          )}
          <Row style={{ marginBottom: 16 }}>
            <Col
              xxl={{ span: 16 }}
              xl={{ span: 16 }}
              lg={{ span: 16 }}
              md={{ span: 15 }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Space
                direction={width <= 667 ? 'vertical' : 'horizontal'}
                style={{ alignItems: width <= 667 ? 'center' : 'flex-start', marginBottom: 48, width: '100%' }}
              >
                <Image
                  preview={false}
                  src={work?.company?.photo}
                  style={{ borderRadius: 6, objectFit: 'cover' }}
                  height={'160px'}
                  width={width <= 667 ? '80vw' : '160px'}
                />
                <Space direction='vertical' size={12}>
                  <Title
                    level={3}
                    style={{ marginBottom: 0 }}
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: <span style={{ fontSize: 16 }}>Տեսնել ավելին</span>,
                    }}
                  >
                    {work?.workStatement?.title}
                    {!role ? null : (
                      <Badge
                        text={
                          work?.status === 'BLOCKED'
                            ? 'Արգելափակված'
                            : work?.status === 'DONE'
                            ? 'Ավարտված'
                            : work?.status === 'INACTIVE'
                            ? 'Ընթացիկ'
                            : 'Նոր'
                        }
                        color={
                          work?.status === 'BLOCKED'
                            ? `${CORAL}`
                            : work?.status === 'DONE'
                            ? `${GRAY}`
                            : work?.status === 'INACTIVE'
                            ? `${AZURE}`
                            : `${GREEN}`
                        }
                        style={{ alignItems: 'center', color: `${TEXT_COLOR}`, fontWeight: 400, marginLeft: 16 }}
                      />
                    )}
                  </Title>
                  <Paragraph
                    style={{ color: `${AZURE}`, marginBottom: '0.5em', cursor: 'pointer' }}
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                    }}
                    onClick={(): void => handleNavigateCompany()}
                  >
                    {work?.company?.name}
                  </Paragraph>
                  {isPrivate && (
                    <>
                      <Space direction='horizontal' wrap>
                        {work?.status === 'ACTIVE' && (
                          <AsnButton
                            icon={<EditSvg />}
                            onClick={(): void => navigate(PATHS.WORK_EDIT.replace(':id', work?.id))}
                          >
                            Խմբագրել
                          </AsnButton>
                        )}
                        {(work?.status === 'ACTIVE' || work?.status === 'INACTIVE') && (
                          <Button type='primary' onClick={(): void => setOpenFinishMOdal(true)}>
                            Ավարտել
                          </Button>
                        )}
                        {work?.status === 'ACTIVE' && (
                          <AsnButton
                            icon={<DeleteSvg />}
                            style={{ borderColor: `${RED}`, color: `${RED}` }}
                            color={RED_LIGHT}
                            onClick={(): void => setOpenDeleteModal(true)}
                          >
                            Ջնջել
                          </AsnButton>
                        )}
                      </Space>
                    </>
                  )}
                  {role === 'SUPER_ADMIN' || role === 'ADMIN' ? (
                    <Space direction='horizontal' align='center'>
                      {!(user?.permission === 'VIEW' && user.role === 'ADMIN') && (
                        <>
                          {work.status === STATUS.ACTIVE ? (
                            <AsnButton type='primary' onClick={onBlockWork}>
                              Արգելափակել
                            </AsnButton>
                          ) : null}
                        </>
                      )}
                      <span
                        style={{
                          color: TEXT_COLOR,
                        }}
                      >{`Ստեղծված՝ ${dayjs(work?.workStatement?.createdAt)
                        .utc()
                        .format(dateFormat)}`}</span>
                    </Space>
                  ) : null}
                  {!role ? (
                    <Button
                      type='primary'
                      style={{ width: width <= 667 ? '100%' : 'auto' }}
                      href={`mailto:${work?.workStatement?.registrationLink}`}
                    >
                      Դիմել հիմա
                    </Button>
                  ) : null}
                </Space>
              </Space>
              <Space direction='vertical' style={{ marginBottom: width <= 667 ? 24 : 0 }}>
                <Title level={5} style={{ marginBottom: 0, fontSize: 16 }}>
                  Աշխատանքի նկարագրություն
                </Title>
                <Paragraph style={{ marginBottom: 0, fontSize: 16 }}>{work?.workStatement?.description}</Paragraph>
              </Space>
            </Col>
            <Col
              xxl={{ span: 8 }}
              xl={{ span: 8 }}
              lg={{ span: 8 }}
              md={{ span: 8 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Space
                direction='vertical'
                style={{ background: 'rgba(31, 130, 255, 0.04)', padding: 16, width: '100%' }}
                size={12}
              >
                <Title level={5}>Մանրամասները</Title>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>
                    Գտնվելու վայրը (Մարզ)՝
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {work?.workStatement?.region?.title}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Հասցե՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {work?.workStatement?.location}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Աշխատավարձ՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {work?.workStatement?.salary} դրամ
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Հեռախոս՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>{work?.workStatement?.phone}</Paragraph>
                </Space>
                <div
                  style={{
                    borderRadius: '2px',
                    background:
                      'linear-gradient(90deg, rgba(31, 130, 255, 0.20) 0%, #1F82FF 51.2%, rgba(31, 130, 255, 0.20) 100%)',
                    height: '2px',
                  }}
                />
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>
                    Դիմելու վերջնաժամկետ՝
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {dayjs(work?.workStatement?.applicationDeadline)
                      .utc()
                      .format(dateFormat)}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Տևողությունը՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {work?.workStatement?.duration}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>
                    Զբաղվածություն՝
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {work?.workStatement?.employment?.title}
                  </Paragraph>
                </Space>
                <Space>
                  <Paragraph style={{ fontWeight: 600, marginBottom: 0, wordBreak: 'normal' }}>Մակարդակ՝</Paragraph>
                  <Paragraph style={{ marginBottom: 0, wordBreak: 'normal' }}>
                    {work?.workStatement?.level?.title}
                  </Paragraph>
                </Space>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col xxl={{ span: 16 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 16 }} sm={{ span: 24 }}>
              <Title level={5} style={{ marginBottom: 24 }}>
                Պարտականություններ
              </Title>
              <div
                className='list'
                dangerouslySetInnerHTML={{ __html: work?.workStatement?.responsibilities || '' }}
                style={{ fontSize: 16, color: `${TEXT_COLOR}` }}
              />
              <Title level={5} style={{ paddingTop: 48, marginBottom: 24 }}>
                Անհրաժեշտ հմտություններ
              </Title>
              <div
                className='list'
                dangerouslySetInnerHTML={{ __html: work?.workStatement?.skills || '' }}
                style={{ fontSize: 16, color: `${TEXT_COLOR}` }}
              />
              {work?.workStatement?.additionalNotes && (
                <>
                  <Title level={5} style={{ paddingTop: 48, marginBottom: 24 }}>
                    Հավելյալ նշումներ
                  </Title>
                  <Paragraph>{work?.workStatement?.additionalNotes}</Paragraph>
                </>
              )}
              {work?.workStatement?.whatWeOffer && (
                <>
                  <Title level={5} style={{ paddingTop: 48, marginBottom: 24 }}>
                    Ինչ ենք մենք առաջարկում
                  </Title>
                  <Paragraph>{work?.workStatement?.whatWeOffer}</Paragraph>
                </>
              )}
            </Col>
          </Row>
        </Col>
        {!role && width <= 667 ? (
          <div style={{ padding: '4%' }}>
            <Button type='primary' style={{ width: '100%' }} href={`mailto:${work?.workStatement?.registrationLink}`}>
              Դիմել հիմա
            </Button>
          </div>
        ) : null}
      </Row>
      <Modal centered open={openReject !== ''} onCancel={onClosRejectModal} footer={false} width={'40vw'}>
        <Form
          name='form_items'
          onFinish={onFinish}
          form={form}
          autoComplete='off'
          layout='vertical'
          style={{
            width: '100%',
            margin: '2rem 0 3rem',
          }}
        >
          <RejectionReasonContent
            rejectFn={onClosRejectModal}
            disabledButton={disabledButton}
            title='Համոզված եք, որ ցանկանում եք արգելափակել հայտարարությունը'
          />
        </Form>
      </Modal>
      {openFinishModal && (
        <FinishModal open={openFinishModal} setOpen={setOpenFinishMOdal} id={work?.id} type={'WORK'} />
      )}
      {openDeleteModal && (
        <Modal centered open={openDeleteModal} onCancel={(): void => setOpenDeleteModal(false)} footer={false}>
          <ActionModal
            text={`Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել Ձեր  հայտարարությունը`}
            resolveFn={onDelete}
            rejectFn={(): void => setOpenDeleteModal(false)}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => closeSuccessModal()}
          content='Ձեր հայտարարությունը հաջողությամբ հեռացվել է'
        >
          <AsnButton style={{ marginTop: 20 }} onClick={(): void => navigate(PATHS.COMPANY_ANNOUNCMENTS)}>
            Վերադառնալ իմ էջ
          </AsnButton>
        </SuccessModal>
      )}
    </>
  );
};

export default WorkView;
