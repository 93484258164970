import React from 'react';
import { Space, Typography, Radio } from 'antd';

import { COLORS } from '../../constants';
import { IRadioCard } from '../../types/components';

const { Paragraph, Title } = Typography;
const { AZURE } = COLORS;

const RadioCard: React.FC<IRadioCard> = ({ svg, title, description, value }) => {
  return (
    <Space
      style={{ border: `2px solid ${AZURE}`, padding: 16, borderRadius: 10, width: '100%', height: '100%' }}
      direction='vertical'
    >
      <Space direction='horizontal' style={{ width: '100%', justifyContent: 'space-between' }}>
        <Space>
          {svg}
          <Title level={5} style={{ marginBottom: 0 }}>
            {title}
          </Title>
        </Space>
        <Radio value={value} />
      </Space>
      <Paragraph style={{ marginBottom: 0 }}>{description}</Paragraph>
    </Space>
  );
};

export default RadioCard;
