import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
const url = 'api/admin/success-stories';

const useDeleteStories = (options = {}): UseMutationResult<void, unknown, string, unknown> => {
  return useMutation(async (id: string) => {
    await client.delete(`${url}/${id}`);
  }, options);
};
export default useDeleteStories;
