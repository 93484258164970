import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { TGetStatement, TUseGetStatementById } from '../../types/api/company';

const url = '/api/statements/:id';

const useGetStatementById: TUseGetStatementById = (id, options) => {
  const result = useQuery({
    queryKey: [url, id],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as TGetStatement),
    isLoading,
    isFetching,
  };
};

export default useGetStatementById;
