import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetCompanyById } from '../../../../api';
import { CompanyView, Spinner } from '../../../../components';

const CompanyViewPublic: React.FC = () => {
  const [filterParams] = useSearchParams();
  const id = filterParams.get('id');
  const { data: company, isFetching: isFetchingCompany } = useGetCompanyById(id as string, {
    enabled: Boolean(id),
  });

  if (isFetchingCompany) {
    return <Spinner />;
  }

  return <CompanyView company={company} publicRoute={true} />;
};

export default CompanyViewPublic;
