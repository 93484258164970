import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { GetLecturers, TUseGetLecturersById } from '../../admin/types/lecturers.tsx/types';
export const url = 'api/teacher/:id';

const useGetLecturersById: TUseGetLecturersById = (id, options) => {
  const result = useQuery({
    queryKey: [url, id],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data,
  });
  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as GetLecturers),
    isLoading,
    isFetching,
  };
};

export default useGetLecturersById;
