import { useQuery } from '@tanstack/react-query';

import { TGetUser, TUseGetUserById } from '../../../types/admin/users/company';
import client from '../../client';

const url = '/api/user/:id';

const useGetUserById: TUseGetUserById = (id, options) => {
  const result = useQuery({
    queryKey: [url, id],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data,
  });

  const { data, isLoading, isSuccess, isFetching } = result;

  return {
    data: isSuccess ? data : ({} as TGetUser),
    isLoading,
    isFetching,
  };
};

export default useGetUserById;
