import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { ISuccesMessage } from '../../types/global';
import { TStatementVariables, TuseCreateStatement } from '../../types/api/company';

const url = '/api/statements';

const useCreateStatement: TuseCreateStatement = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TStatementVariables, unknown>({
    mutationFn: (params) => {
      return client.post(url, params);
    },
  });

  return mutation;
};

export default useCreateStatement;
