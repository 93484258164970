import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import { PATHS } from '../constants';
import { useAuth } from '../hooks/useAuth';
import { MainLayout } from '../components';

import ScrollToTop from './ScrollToTop';

export const PublicRoutesAuth: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();
  if (user && user.role === 'COMPANY') {
    return <Navigate to={PATHS.COMPANY_MYINFO} />;
  }

  if (user && (user?.role === 'SUPER_ADMIN' || user?.role === 'ADMIN')) {
    return <Navigate to={PATHS.ADMIN} />;
  }

  return (
    <ScrollToTop>
      {pathname === '/' ? (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ) : (
        <Outlet />
      )}
    </ScrollToTop>
  );
};
