import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Button, Col, Typography, Input, Space, Modal, Checkbox } from 'antd';

import { AsnForm, AsnButton } from '../../../components';
import { useSignUp } from '../../../api';
import { COLORS, PATHS, validate_messages, passwordRules, emailRules } from '../../../constants';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { SignUpVariables } from '../../../types/api/auth';
import { rulesPassword } from '../../../helpers';

const { Title, Paragraph } = Typography;
const { AZURE, TEXT_COLOR } = COLORS;

const SignUp: React.FC = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: signUp } = useSignUp();

  const onFinish = (values: SignUpVariables): void => {
    delete values?.privacyPolicy;
    signUp(
      { ...values, name: values?.name?.trimEnd(), taxAccount: values?.taxAccount },
      {
        onSuccess: () => {
          setOpenSuccessModal(true);
        },
      },
    );
  };

  const onCancelModal = (): void => {
    navigate(PATHS.LANDING);
    setOpenSuccessModal(false);
  };

  return (
    <div>
      <Row align={'middle'} justify={'space-between'} style={{ padding: '22px 80px' }}>
        <Logo onClick={(): void => navigate(PATHS.LANDING)} style={{ cursor: 'pointer' }} />
        <Button type='text' style={{ fontSize: 20 }} onClick={(): void => navigate(PATHS.SIGNIN)}>
          Մուտք
        </Button>
      </Row>
      <Row
        style={{
          backgroundImage: 'url(./assets/auth_left.png)',
          backgroundSize: 'contain',
          height: 'calc(100vh - 100px)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Col push={4} span={20}>
          <Row align={'top'} justify={'center'} style={{ height: '100%' }}>
            <Space direction='vertical'>
              <Title level={2} style={{ marginBottom: 30 }}>
                Գրանցել կազմակերպություն
              </Title>
              <AsnForm
                form={form}
                layout='vertical'
                validateMessages={validate_messages}
                onFinish={(values): void => onFinish(values as SignUpVariables)}
                style={{ maxWidth: '460px', width: '42vw' }}
              >
                <AsnForm.Item
                  name='name'
                  label='Կազմակերպության անվանում'
                  rules={[{ required: true }, { min: 2, max: 50 }]}
                  normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
                >
                  <Input />
                </AsnForm.Item>
                <AsnForm.Item
                  name='taxAccount'
                  label='ՀՎՀՀ'
                  rules={[{ required: true, message: 'Խնդրում ենք լրացնել ՀՎՀՀն' }, { len: 8 }]}
                  normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
                >
                  <Input />
                </AsnForm.Item>
                <AsnForm.Item name='email' label='Էլ․հասցե' rules={[...emailRules]}>
                  <Input />
                </AsnForm.Item>
                <AsnForm.Item
                  name='phone'
                  label='Հեռախոսահամար'
                  rules={[{ required: true }, { min: 8, max: 15 }]}
                  normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
                >
                  <Input />
                </AsnForm.Item>
                <AsnForm.Item
                  name='password'
                  label='Գաղտնաբառ'
                  rules={[...passwordRules]}
                  normalize={(value: string): string => value.trim()}
                >
                  <Input.Password />
                </AsnForm.Item>
                <AsnForm.Item
                  name='repeatPassword'
                  label='Կրկնել գաղտնաբառը'
                  rules={rulesPassword('password')}
                  dependencies={['password']}
                  normalize={(value: string): string => value.trim()}
                >
                  <Input.Password />
                </AsnForm.Item>
                <AsnForm.Item
                  name='privacyPolicy'
                  valuePropName='checked'
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error('Գաղտնիության քաղաքականությունը ընդունելը պարտադիր է')),
                    },
                  ]}
                >
                  <Checkbox>
                    Համաձայն եմ Zinhub Հարթակի{' '}
                    <a
                      download
                      href='https://apizinhub.analysed.ai/files/non-disclosure-policy.pdf'
                      target='_blank'
                      rel='noreferrer'
                      style={{ color: `${TEXT_COLOR}`, textDecoration: 'underline' }}
                    >
                      {' '}
                      Գաղտնիության քաղաքականության դրույթներին
                    </a>
                  </Checkbox>
                </AsnForm.Item>
                <Row justify={'end'} style={{ gap: 16, padding: '16px 0px' }}>
                  <AsnButton type='default' onClick={(): void => navigate(PATHS.LANDING)}>
                    Չեղարկել
                  </AsnButton>
                  <Button type='primary' htmlType='submit'>
                    Գրանցվել
                  </Button>
                </Row>
              </AsnForm>
            </Space>
          </Row>
        </Col>
      </Row>
      {openSuccessModal && (
        <Modal
          open={openSuccessModal}
          centered
          footer={false}
          onCancel={onCancelModal}
          bodyStyle={{ padding: '40px 0px' }}
          style={{ maxWidth: 750 }}
          width={'45vw'}
        >
          <Paragraph style={{ fontSize: '20px', margin: '4px', textAlign: 'center' }}>
            Ձեր Գրանցման հայտն ուղարկվել է ստուգման։ Դուք կստանաք հաստատման կամ մերժման հաղորդագրություն{' '}
            <span style={{ color: `${AZURE}`, textDecoration: 'underline' }}>{form.getFieldValue('email')}</span>{' '}
            էլեկտրոնային հասցեին
          </Paragraph>
        </Modal>
      )}
    </div>
  );
};

export default SignUp;
