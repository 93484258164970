import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../../client';
import { ISuccesMessage } from '../../../types/global';
import { IParamsLecturers } from '../../admin/types/lecturers.tsx/types';
export const url = 'api/teacher';

export type TUseCreateLecturers = () => UseMutationResult<
  AxiosResponse<ISuccesMessage>,
  AxiosError,
  IParamsLecturers,
  unknown
>;

const useCreateLecturers: TUseCreateLecturers = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, IParamsLecturers, unknown>({
    mutationFn: (params) => {
      return client.post(url, params);
    },
  });

  return mutation;
};

export default useCreateLecturers;
