import React from 'react';
import { Typography } from 'antd';

import { IParticipationStatistic } from '../../../api/admin/types/statistics';

import { CardContainer } from './styled';
const { Text, Title } = Typography;

const InvolvementCard: React.FC<{ item: IParticipationStatistic }> = ({ item }) => {
  return (
    <CardContainer>
      <Text
        style={{
          fontSize: '16px',
        }}
      >
        {item?.title === 'Այլ' ? 'Ծառայություն' : item.title}
      </Text>
      <Title level={2}>{`${item?.percent}%`}</Title>
    </CardContainer>
  );
};

export default InvolvementCard;
