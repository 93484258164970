import React from 'react';

import { useCheckTrainingCount } from '../../../helpers';
import { useGetLandingPageData } from '../../../api';
import { Spinner } from '../../../components';

import Info from './Info';
import Courses from './Courses';
import SuccessStories from './SuccessStories';
import Principals from './Principals';
import CoWorkers from './CoWorkers';
import Statistics from './Statistics';

const width = window.innerWidth;

const Landing: React.FC = () => {
  const count = useCheckTrainingCount(width);
  const { data, isFetching } = useGetLandingPageData({ enabled: true });

  if (isFetching) {
    return (
      <div style={{ height: width <= 667 ? '100vh' : '10vh' }}>
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <Info width={width} />
      <Courses courses={data?.result?.trainings} count={count} width={width} />
      <SuccessStories successStories={data?.result?.successStories} width={width} />
      <Principals />
      <CoWorkers width={width} />
      <Statistics statistics={data?.result?.statistics} />
    </>
  );
};

export default Landing;
