import styled from 'styled-components';

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: auto;
    height: 205px;
    width: 225px;
  }

  .contentField {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: auto;
    height: 270px;
    width: 400px;
  }
  .contentCourseLevel {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: auto;
    height: 205;
    width: 220px;
  }

  @media (max-width: 667px) {
    .contentField {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-x: auto;
      height: 270px;
      width: 320px;
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5 {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 667px) {
    .ant-space {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
