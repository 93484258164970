import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Popover, Space, Typography } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useSearchParams } from 'react-router-dom';

import AsnSearch from '../AsnSearch';
import { IFilterData, IFilterParams, IParamsGetAllStatementsPublicList } from '../../types/api/public';
import AsnButton from '../Button';
import { ReactComponent as DropdownTop } from '../../assets/icons/dropdown-top.svg';
import { ReactComponent as DropdownBottom } from '../../assets/icons/dropdown-bottom.svg';
import { COLORS } from '../../constants';

import { ButtonContainer, FilterContainer, PopupContainer } from './styled';

interface IPropsFilters {
  params: IParamsGetAllStatementsPublicList;
  setParams: React.Dispatch<React.SetStateAction<IParamsGetAllStatementsPublicList>>;
  filterData: IFilterData;
  filterParams: IFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<IFilterParams>>;
  isLoading: boolean;
  type: string;
}

const { AZURE } = COLORS;
const { Title, Text } = Typography;

const PublicFilters: React.FC<IPropsFilters> = ({
  params,
  setParams,
  filterData,
  filterParams,
  setFilterParams,
  isLoading,
  type,
}) => {
  const [search, setSearch] = useState<string>('');
  const [openRegion, setOpenRegion] = useState(false);
  const [openCourseLevel, setOpenCourseLevel] = useState(false);
  const [openCourseFormat, setOpenCourseFormat] = useState(false);
  const [openFiledContent, setOpenFiledContent] = useState(false);
  const [openEmploymentContent, setOpenEmploymentContent] = useState(false);
  const [regionsChange, setRegionsChange] = useState<CheckboxValueType[]>([]);
  const [courseFormatChange, setCourseFormatChange] = useState<CheckboxValueType[]>([]);
  const [courseLevelChange, setCourseLevelChange] = useState<CheckboxValueType[]>([]);
  const [filedOfStudyChange, setFiledOfStudyChange] = useState<CheckboxValueType[]>([]);
  const [filedOfEmploymentChange, setFiledOfEmploymentChange] = useState<CheckboxValueType[]>([]);
  const [filterParamsPage, setFilterParamsPage] = useSearchParams();
  const pageParams = filterParamsPage.get('page');

  const onClearFilter = (): void => {
    setFilterParams({
      regionIds: [],
      formatIds: [],
      levelIds: [],
      filedStudyIds: [],
      employmentIds: [],
    });
    setRegionsChange([]);
    setCourseFormatChange([]);
    setCourseLevelChange([]);
    setFiledOfStudyChange([]);
    setFiledOfEmploymentChange([]);
  };

  const onSearch = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setParams({
      ...params,
      search: e.currentTarget.value,
      offset: 0,
    });
    setSearch(e.currentTarget.value);
    if (pageParams) {
      filterParamsPage.delete('page');
      setFilterParamsPage(filterParamsPage);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      setParams({
        ...params,
        offset: 0,
        search: '',
      });
      setSearch('');
    }
    setSearch(e.target.value);
    if (pageParams) {
      filterParamsPage.delete('page');
      setFilterParamsPage(filterParamsPage);
    }
  };

  const onFilterAssessment = (filter: string): void => {
    setParams({
      ...params,
      offset: 0,
    });
    if (pageParams) {
      filterParamsPage.delete('page');
      setFilterParamsPage(filterParamsPage);
    }
    switch (filter) {
      case 'regionContent':
        setFilterParams({
          ...filterParams,
          regionIds: regionsChange,
        });
        setOpenRegion(false);
        break;
      case 'courseFormatContent':
        setFilterParams({
          ...filterParams,
          formatIds: courseFormatChange,
        });
        setOpenCourseFormat(false);
        break;
      case 'courseLevelContent':
        setFilterParams({
          ...filterParams,
          levelIds: courseLevelChange,
        });
        setOpenCourseLevel(false);
        break;
      case 'filedContent':
        if (type === 'TRINING') {
          setFilterParams({
            ...filterParams,
            filedStudyIds: filedOfStudyChange,
          });
        } else if (type === 'WORK') {
          setFilterParams({
            ...filterParams,
            filedWorkIds: filedOfStudyChange,
          });
        }
        setOpenFiledContent(false);
        break;
      case 'workEmploymentContent':
        setFilterParams({
          ...filterParams,
          employmentIds: filedOfEmploymentChange,
        });
        setOpenEmploymentContent(false);
        break;
    }
  };

  const onClearFilterAssessment = (filter: string): void => {
    setParams({
      ...params,
      offset: 0,
    });
    if (pageParams) {
      filterParamsPage.delete('page');
      setFilterParamsPage(filterParamsPage);
    }
    switch (filter) {
      case 'regionContent':
        setRegionsChange([]);
        setFilterParams({
          ...filterParams,
          regionIds: [],
        });
        setOpenRegion(false);
        break;
      case 'courseFormatContent':
        setCourseFormatChange([]);
        setFilterParams({
          ...filterParams,
          formatIds: [],
        });
        setOpenCourseFormat(false);
        break;
      case 'courseLevelContent':
        setCourseLevelChange([]);
        setFilterParams({
          ...filterParams,
          levelIds: [],
        });
        setOpenCourseLevel(false);

        break;
      case 'filedContent':
        setFiledOfStudyChange([]);

        if (type === 'TRINING') {
          setFilterParams({
            ...filterParams,
            filedStudyIds: [],
          });
        } else if (type === 'WORK') {
          setFilterParams({
            ...filterParams,
            filedWorkIds: [],
          });
        }
        setOpenFiledContent(false);
        break;
      case 'workEmploymentContent':
        setFiledOfEmploymentChange([]);
        setFilterParams({
          ...filterParams,
          employmentIds: [],
        });
        setOpenEmploymentContent(false);
        break;
    }
  };

  const regionContent = (
    <PopupContainer>
      <Checkbox.Group onChange={(value: CheckboxValueType[]): void => setRegionsChange(value)} value={regionsChange}>
        <div className='content'>
          {filterData?.regions?.map((region) => (
            <Checkbox key={region.value} value={region.value}>
              {region.label}
            </Checkbox>
          ))}
        </div>
      </Checkbox.Group>
      <ButtonContainer>
        <AsnButton onClick={(): void => onClearFilterAssessment('regionContent')}>Չեղարկել</AsnButton>
        <AsnButton type='primary' onClick={(): void => onFilterAssessment('regionContent')}>
          Ֆիլտրել
        </AsnButton>
      </ButtonContainer>
    </PopupContainer>
  );
  const courseFormatContent = (
    <PopupContainer>
      <Checkbox.Group
        onChange={(value: CheckboxValueType[]): void => setCourseFormatChange(value)}
        value={courseFormatChange}
      >
        <div className='contentCourseLevel'>
          {filterData?.courseFormat?.map((region) => (
            <Checkbox key={region.value} value={region.value}>
              {region.label}
            </Checkbox>
          ))}
        </div>
      </Checkbox.Group>
      <ButtonContainer>
        <AsnButton onClick={(): void => onClearFilterAssessment('courseFormatContent')}>Չեղարկել</AsnButton>
        <AsnButton type='primary' onClick={(): void => onFilterAssessment('courseFormatContent')}>
          Ֆիլտրել
        </AsnButton>
      </ButtonContainer>
    </PopupContainer>
  );

  const courseLevelContent = (
    <PopupContainer>
      <Checkbox.Group
        onChange={(value: CheckboxValueType[]): void => setCourseLevelChange(value)}
        value={courseLevelChange}
      >
        <div className='contentCourseLevel'>
          {filterData?.courseLevel?.map((region) => (
            <Checkbox key={region.value} value={region.value}>
              {region.label}
            </Checkbox>
          ))}
        </div>
      </Checkbox.Group>
      <ButtonContainer>
        <AsnButton onClick={(): void => onClearFilterAssessment('courseLevelContent')}>Չեղարկել</AsnButton>
        <AsnButton type='primary' onClick={(): void => onFilterAssessment('courseLevelContent')}>
          Ֆիլտրել
        </AsnButton>
      </ButtonContainer>
    </PopupContainer>
  );

  const filedContent = (
    <PopupContainer>
      <Checkbox.Group
        onChange={(value: CheckboxValueType[]): void => setFiledOfStudyChange(value)}
        value={filedOfStudyChange}
      >
        <div className='contentField'>
          {filterData?.filedOfStudy?.map((region) => (
            <Checkbox key={region.value} value={region.value}>
              {region.label}
            </Checkbox>
          ))}
        </div>
      </Checkbox.Group>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '1rem',
        }}
      >
        <AsnButton onClick={(): void => onClearFilterAssessment('filedContent')}>Չեղարկել</AsnButton>
        <AsnButton type='primary' onClick={(): void => onFilterAssessment('filedContent')}>
          Ֆիլտրել
        </AsnButton>
      </div>
    </PopupContainer>
  );

  const workEmploymentContent = (
    <PopupContainer>
      <Checkbox.Group
        onChange={(value: CheckboxValueType[]): void => setFiledOfEmploymentChange(value)}
        value={filedOfEmploymentChange}
      >
        <div className='contentCourseLevel'>
          {filterData?.employment?.map((region) => (
            <Checkbox key={region.value} value={region.value}>
              {region.label}
            </Checkbox>
          ))}
        </div>
      </Checkbox.Group>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: '1rem',
        }}
      >
        <AsnButton onClick={(): void => onClearFilterAssessment('workEmploymentContent')}>Չեղարկել</AsnButton>
        <AsnButton type='primary' onClick={(): void => onFilterAssessment('workEmploymentContent')}>
          Ֆիլտրել
        </AsnButton>
      </div>
    </PopupContainer>
  );

  return (
    <FilterContainer>
      <AsnSearch
        suffix={<SearchOutlined />}
        style={{ maxWidth: 450 }}
        onPressEnter={onSearch}
        onChange={onChange}
        value={search}
      />
      {!isLoading && (
        <Space
          direction='horizontal'
          style={{
            gap: '1.5rem',
            alignItems: 'center',
          }}
        >
          {filterData?.regions !== undefined ? (
            <Popover
              content={regionContent}
              open={openRegion}
              onOpenChange={(open): void => setOpenRegion(open)}
              trigger='click'
              placement='bottom'
            >
              <Title level={5}>
                Մարզ
                {openRegion ? <DropdownTop /> : <DropdownBottom />}
              </Title>
            </Popover>
          ) : null}
          {filterData?.courseLevel !== undefined ? (
            <Popover
              content={courseLevelContent}
              open={openCourseLevel}
              onOpenChange={(open): void => setOpenCourseLevel(open)}
              trigger='click'
              placement='bottom'
            >
              <Title level={5}>
                Մակարդակ
                {openCourseLevel ? <DropdownTop /> : <DropdownBottom />}
              </Title>
            </Popover>
          ) : null}
          {filterData?.courseFormat !== undefined ? (
            <Popover
              content={courseFormatContent}
              open={openCourseFormat}
              onOpenChange={(open): void => setOpenCourseFormat(open)}
              trigger='click'
              placement='bottom'
            >
              <Title level={5}>
                Ձևաչափ
                {openCourseFormat ? <DropdownTop /> : <DropdownBottom />}
              </Title>
            </Popover>
          ) : null}
          {filterData?.employment !== undefined ? (
            <Popover
              content={workEmploymentContent}
              open={openEmploymentContent}
              onOpenChange={(open): void => setOpenEmploymentContent(open)}
              trigger='click'
              placement='bottom'
            >
              <Title level={5}>
                Զբաղվածություն
                {openEmploymentContent ? <DropdownTop /> : <DropdownBottom />}
              </Title>
            </Popover>
          ) : null}
          {filterData?.filedOfStudy !== undefined ? (
            <Popover
              content={filedContent}
              open={openFiledContent}
              onOpenChange={(open): void => setOpenFiledContent(open)}
              trigger='click'
              placement='bottom'
            >
              <Title level={5}>
                Ոլորտ
                {openFiledContent ? <DropdownTop /> : <DropdownBottom />}
              </Title>
            </Popover>
          ) : null}
          {(filterParams.employmentIds && filterParams.employmentIds?.length > 0) ||
          filterParams.filedStudyIds?.length > 0 ||
          (filterParams.formatIds && filterParams.formatIds.length > 0) ||
          filterParams.levelIds.length > 0 ||
          filterParams.regionIds.length > 0 ||
          (filterParams.filedWorkIds && filterParams.filedWorkIds?.length > 0) ? (
            <Text
              underline
              style={{
                color: `${AZURE}`,
                fontWeight: 600,
                cursor: 'pointer',
              }}
              className='more'
              onClick={onClearFilter}
            >
              Չեղարկել
            </Text>
          ) : null}
        </Space>
      )}
    </FilterContainer>
  );
};

export default PublicFilters;
