import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/charts';

import { IStatusesByMonths } from '../../../api/admin/types/statistics';

const CompaniesByStatus: React.FC<{ companyStatusesByMonths: IStatusesByMonths[] }> = ({ companyStatusesByMonths }) => {
  const [data, setData] = useState<IStatusesByMonths[]>([]);

  useEffect(() => {
    setData(companyStatusesByMonths);
  }, [companyStatusesByMonths]);

  const config = {
    data,
    xField: 'month',
    yField: 'count',
    seriesField: 'type',
    isGroup: true,
    dodgePadding: 0.1,
    columnStyle: {
      radius: [2, 2, 0, 0],
    },
  };
  return <Column {...config} />;
};

export default CompaniesByStatus;
