import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
import { IParamsApprove } from '../types/requests/types';
const url = 'api/company/:id/accept';

const useApproveRequests = (options = {}): UseMutationResult<void, unknown, IParamsApprove, unknown> => {
  return useMutation(
    async (params: IParamsApprove) => {
      await client.patch(url.replace(':id', params.id));
    },
    {
      ...options,
    },
  );
};

export default useApproveRequests;
