import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetStatementById } from '../../../api';
import { CourseView, Spinner } from '../../../components';

const CourseViewPublic: React.FC = () => {
  const [filterParams] = useSearchParams();
  const id = filterParams.get('id');
  const { data: statement, isFetching } = useGetStatementById(id as string, { enabled: Boolean(id) });
  if (isFetching) {
    return <Spinner />;
  }
  return <CourseView course={statement} />;
};

export default CourseViewPublic;
