import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../../client';
import { ISuccesMessage } from '../../../types/global';
import { IParamsAdmin } from '../types/users/admin';
export const url = 'api/auth/invite';

export type TUseCreateAdmin = () => UseMutationResult<AxiosResponse<ISuccesMessage>, AxiosError, IParamsAdmin, unknown>;

const useCreateAdmin: TUseCreateAdmin = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, IParamsAdmin, unknown>({
    mutationFn: (params) => {
      return client.post(url, params);
    },
  });

  return mutation;
};

export default useCreateAdmin;
