import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { IDataAdmin, TUseGetStaticData } from '../types/users/admin';

export const url = 'api/user';

const useGetAdminsList: TUseGetStaticData = (params, options) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.post(url, params),
    ...options,
    select: (data) => data?.data,
  });
  const { data, isLoading, isSuccess, isFetching } = result;

  return {
    data: isSuccess ? data : ({} as IDataAdmin),
    isLoading,
    isFetching,
  };
};
export default useGetAdminsList;
