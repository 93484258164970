import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
import { IParamsAnnouncement } from '../types/announcemenst/types';
const url = 'api/statements/:id/block';

const useBlockAnnouncement = (options = {}): UseMutationResult<void, unknown, IParamsAnnouncement, unknown> => {
  return useMutation(
    async (params: IParamsAnnouncement) => {
      await client.patch(url.replace(':id', params.id), { rejectionText: params.rejectionText });
    },
    {
      ...options,
    },
  );
};

export default useBlockAnnouncement;
