import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
import { IParamsUpdate } from '../types/users/admin';
export const url = 'api/user';

const useUpdateAdmin = (options = {}): UseMutationResult<void, unknown, IParamsUpdate, unknown> =>
  useMutation(async (params: IParamsUpdate) => {
    await client.put(`${url}/${params.id}`, params.userData);
  }, options);
export default useUpdateAdmin;
