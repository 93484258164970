import React from 'react';
import { TabsProps } from 'antd';

import AsnTab from '../../../components/Tab';
import AdminTable from '../../../components/Admin/Users/Admin';
import CompanyTable from '../../../components/Admin/Users/Company';

import { UsersContainer } from './styled';

const Users: React.FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Ընկերություն',
      children: <CompanyTable />,
    },
    {
      key: '2',
      label: 'Ադմինիստրատոր',
      children: <AdminTable />,
    },
  ];

  return (
    <UsersContainer>
      <AsnTab width='100%' defaultActiveKey='1' items={items} />
    </UsersContainer>
  );
};

export default Users;
