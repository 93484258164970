import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Radio, Typography } from 'antd';

import { RadioCard } from '../../../components';
import { COLORS, PATHS } from '../../../constants';
import { ReactComponent as CourseSvg } from '../../../assets/icons/course.svg';
import { ReactComponent as WorkSvg } from '../../../assets/icons/work.svg';
import { ReactComponent as OtherSvg } from '../../../assets/icons/other.svg';
import { ReactComponent as BackSvg } from '../../../assets/icons/Back.svg';
import { IChooseForm } from '../../../types/company';

const { Title } = Typography;
const { AZURE } = COLORS;

const ChooseForm: React.FC<IChooseForm> = ({ radioValue }) => {
  const navigate = useNavigate();

  const handleBack = (): void => {
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
  };

  return (
    <Row style={{ border: `0.5px solid ${AZURE}`, padding: 24, borderRadius: 10, marginBottom: 24 }}>
      <div style={{ cursor: 'pointer' }} onClick={handleBack}>
        <BackSvg />
      </div>
      <Title level={4} style={{ width: '100%', textAlign: 'center', marginBottom: 24 }}>
        Տեղադրել հայտարարություն
      </Title>
      <Row style={{ width: '100%' }} gutter={[24, 0]}>
        <Radio.Group style={{ width: '100%', display: 'flex' }} value={radioValue}>
          <Col span={8} style={{ cursor: 'pointer' }} onClick={(): void => navigate(PATHS.COMPANY_CREATE_COURSE)}>
            <RadioCard
              svg={<CourseSvg />}
              title='ՈՒՍՈՒՑՈՒՄ'
              description='Ես ուսումնական կենտրոն եմ, առաջարկում եմ դասընթաց'
              value='TRINING'
            />
          </Col>
          <Col span={8} style={{ cursor: 'pointer' }} onClick={(): void => navigate(PATHS.COMPANY_CREATE_JOB)}>
            <RadioCard
              svg={<WorkSvg />}
              title='ԱՇԽԱՏԱՆՔ'
              description='Ես գործատու եմ, առաջարկում եմ աշխատանք'
              value='WORK'
            />
          </Col>
          <Col span={8} style={{ cursor: 'pointer' }} onClick={(): void => navigate(PATHS.COMPANY_CREATE_OTHER)}>
            <RadioCard
              svg={<OtherSvg />}
              title='ԱՅԼ'
              description='Ես ընկերություն եմ  առաջարկում եմ   միջոցառում-ծառայություն'
              value='OTHER'
            />
          </Col>
        </Radio.Group>
      </Row>
    </Row>
  );
};

export default ChooseForm;
