import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { CheckToken, SuccessCheckToken, TUseCheckToken } from '../../types/api/auth';

const url = '/api/auth/invite/check';

const useCheckToken: TUseCheckToken = () => {
  const mutation = useMutation<AxiosResponse<SuccessCheckToken>, AxiosError, CheckToken, unknown>({
    mutationFn: (values) => {
      return client.post(url, values);
    },
  });

  return mutation;
};

export default useCheckToken;
