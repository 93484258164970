import React from 'react';
import { Space, Typography } from 'antd';

import AsnButton from '../Button';
import { COLORS } from '../../constants';

interface IActionModal {
  text: string;
  resolveFn: () => void;
  rejectFn: () => void;
}

const { Text } = Typography;

const ActionModal: React.FC<IActionModal> = ({ text, resolveFn, rejectFn }) => {
  return (
    <Space
      direction='vertical'
      style={{
        display: 'flex',
        padding: '40px',
      }}
      size={40}
    >
      <Text
        style={{
          display: 'flex',
          textAlign: 'center',
          color: COLORS.TEXT_COLOR,
          fontWeight: '500',
          fontSize: 16,
        }}
      >
        {text}
      </Text>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        size={40}
      >
        <AsnButton onClick={rejectFn}>Չեղարկել</AsnButton>
        <AsnButton type='primary' onClick={resolveFn}>
          Հաստատել
        </AsnButton>
      </Space>
    </Space>
  );
};

export default ActionModal;
