import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { IGetAllStatementsPublic, TUseGetAllStatementsPublic } from '../../types/api/public';

const url = '/api/statements/public/all';

const useGetAllStatementsPublic: TUseGetAllStatementsPublic = (params, options) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.post(url, { ...params, limit: 10 }),
    ...options,
    select: (data) => data?.data,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as IGetAllStatementsPublic),
    isLoading,
    isFetching,
  };
};

export default useGetAllStatementsPublic;
