import styled from 'styled-components';

export const StoriesCardContainer = styled.div`
  border: 0.5px solid rgba(85, 150, 157, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5rem;

  @media (max-width: 1464px) {
    gap: 3rem;
  }
  @media (max-width: 1385px) {
    gap: 1rem;
  }
`;

export const TextContainer = styled.div`
  padding: 1rem 0 3rem 1rem;
  display: flex;
  flex-direction: column;
`;

export const StoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.75rem 5rem 4rem 1.5rem;
  gap: 20vh;
`;
