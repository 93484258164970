import styled from 'styled-components';
import { Input } from 'antd';

import { COLORS } from '../../constants';

const { AZURE, GRAY } = COLORS;

const AsnSearch = styled(Input)`
  &.ant-input-affix-wrapper {
    svg {
      path {
        fill: ${GRAY};
      }
    }
  }
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    svg {
      path {
        fill: ${AZURE};
      }
    }
  }
  &.ant-input-affix-wrapper-focused {
    svg {
      path {
        fill: ${AZURE};
      }
    }
  }
`;

export default AsnSearch;
