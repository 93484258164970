import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';

import { COLORS, tableDateFormat } from '../../../../constants';
import { ReactComponent as ResolvedIcon } from '../../../../assets/icons/resolved.svg';
import { ReactComponent as RejectedIcon } from '../../../../assets/icons/rejected.svg';
import { ICompanyListPending } from '../../../../api/admin/types/users/company';

const { WHITE, BLACK } = COLORS;
export const columnsPending = (
  setOpenApprove: React.Dispatch<React.SetStateAction<string>>,
  setOpenReject: React.Dispatch<React.SetStateAction<string>>,
): ColumnsType<ICompanyListPending> => {
  return [
    {
      title: 'Անվանում',
      dataIndex: 'name',
    },
    {
      title: 'ՀՎՀՀ',
      dataIndex: 'taxAccount',
    },
    {
      title: 'Ստեղծման ամսաթիվ',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: string): React.ReactNode => <div>{dayjs(createdAt).format(tableDateFormat)}</div>,
    },
    {
      title: ' Հեռախոս',
      dataIndex: 'phone',
    },
    {
      title: 'Գործողություն',
      key: 'operation',
      render: (row: ICompanyListPending) => (
        <div>
          <Tooltip
            placement='topLeft'
            title={'Հաստատել'}
            arrow={false}
            overlayInnerStyle={{
              backgroundColor: WHITE,
              color: BLACK,
            }}
          >
            <button
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              onClick={(): void => setOpenApprove(row.id)}
            >
              <ResolvedIcon />
            </button>
          </Tooltip>
          <Tooltip
            placement='topLeft'
            title={'Մերժել'}
            arrow={false}
            overlayInnerStyle={{
              backgroundColor: WHITE,
              color: BLACK,
            }}
          >
            <button
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              onClick={(): void => setOpenReject(row.id)}
            >
              <RejectedIcon />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];
};
