import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { IRetRejectionReason, TUseGetRejectionReasonById } from '../types/announcemenst/types';
export const url = 'api/statements/:id/rejection-reason';

const useGetRejectionReasonById: TUseGetRejectionReasonById = (id, options) => {
  const result = useQuery({
    queryKey: [url, id],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data,
  });
  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as IRetRejectionReason),
    isLoading,
    isFetching,
  };
};

export default useGetRejectionReasonById;
