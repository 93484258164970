import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { IGetAllSCompanyPublic, TUseGetAllCompanyPublic } from '../../types/api/public';

const url = '/api/company/public/all';

const useGetAllCompanyPublic: TUseGetAllCompanyPublic = (params, options) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.post(url, { ...params, limit: 10 }),
    ...options,
    select: (data) => data?.data,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as IGetAllSCompanyPublic),
    isLoading,
    isFetching,
  };
};

export default useGetAllCompanyPublic;
