import React from 'react';
import { Col, Image, Row, Space, Typography } from 'antd';

import { ILecturersData } from '../../api/admin/types/lecturers.tsx/types';
import { COLORS } from '../../constants';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as GitLabIcon } from '../../assets/icons/gitlab.svg';
import { ReactComponent as GitHubIcon } from '../../assets/icons/github.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as BehanceIcon } from '../../assets/icons/behance.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin.svg';

const { AZURE } = COLORS;

const { Link, Title, Paragraph } = Typography;
const width = window.innerWidth;

const LecturersCard: React.FC<{ lecturersData: ILecturersData }> = ({ lecturersData }) => {
  return (
    <Row>
      <Col span={24}>
        <Title level={4}>Դասավանդող</Title>
        <Row
          style={{
            borderRadius: '10px',
            border: '0.5px solid rgba(85, 150, 157, 0.20)',
            padding: '13px',
            width: '100%',
          }}
          gutter={0}
        >
          <Col xxl={7} xl={7} lg={8} md={7} sm={12} xs={24} style={{ paddingRight: 16 }}>
            <Space direction='vertical' style={{ justifyContent: 'space-between', height: '100%' }}>
              <Space direction='vertical'>
                <Title
                  level={4}
                  style={{ color: `${AZURE}`, minHeight: 66 }}
                  ellipsis={{ rows: 2, expandable: true, symbol: <span style={{ fontSize: 16 }}>Տեսնել ավելին</span> }}
                >
                  {lecturersData?.fullName}
                </Title>
                <Paragraph
                  style={{ fontSize: 16, minHeight: 52 }}
                  ellipsis={{ rows: 2, expandable: true, symbol: <span style={{ fontSize: 14 }}>Տեսնել ավելին</span> }}
                >
                  {lecturersData?.profession}
                </Paragraph>
              </Space>
              <div style={{ minHeight: 80 }}>
                {!!lecturersData?.websites?.links?.length && !!lecturersData?.websites?.links[0]?.length && (
                  <>
                    <Title
                      level={5}
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      Սոցիալական կայքեր
                    </Title>
                    <Space direction='horizontal' wrap>
                      {lecturersData?.websites?.links?.map((link, index) => {
                        return (
                          <React.Fragment key={link + index}>
                            {link?.includes('facebook') ? (
                              <Link href={link} target='_blank'>
                                <FacebookIcon />
                              </Link>
                            ) : link?.includes('gitlab') ? (
                              <Link href={link} target='_blank'>
                                <GitLabIcon />
                              </Link>
                            ) : link?.includes('github') ? (
                              <Link href={link} target='_blank'>
                                <GitHubIcon />
                              </Link>
                            ) : link?.includes('instagram') ? (
                              <Link href={link} target='_blank'>
                                <InstagramIcon />
                              </Link>
                            ) : link?.includes('behance') ? (
                              <Link href={link} target='_blank'>
                                <BehanceIcon />
                              </Link>
                            ) : link?.includes('linkedin') ? (
                              <Link href={link} target='_blank'>
                                <LinkedinIcon />
                              </Link>
                            ) : (
                              <Link href={link} target='_blank'>
                                Հղում
                              </Link>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Space>
                  </>
                )}
              </div>
            </Space>
          </Col>
          <Col span={11} xxl={10} xl={10} lg={9} md={10} sm={24} xs={24} style={{ paddingRight: 16 }}>
            <Paragraph style={{ fontWeight: 600 }}>ԱՇԽԱՏԱՆՔԱՅԻՆ ՓՈՐՁ</Paragraph>
            <Paragraph ellipsis={width <= 667 ? false : { rows: 9, expandable: true }}>
              {lecturersData?.experience}
            </Paragraph>
          </Col>
          <Col span={7} xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
            <Image
              preview={false}
              src={lecturersData?.photo}
              style={{ borderRadius: 6, objectFit: 'contain' }}
              height={'240px'}
              width={'100%'}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LecturersCard;
