import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Typography, Input } from 'antd';

import { COLORS, PATHS, emailRules, validate_messages } from '../../../constants';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { AsnForm } from '../../../components';
import { useSignIn } from '../../../api';
import { ISignIn } from '../../../types/auth';

const { AZURE } = COLORS;
const { Title } = Typography;

const SignIn: React.FC = () => {
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: signIn } = useSignIn();

  const onFinish = (values: ISignIn): void => {
    signIn(values);
  };

  return (
    <>
      <Row align={'middle'} justify={'space-between'} style={{ padding: '22px 80px' }}>
        <Logo onClick={(): void => navigate(PATHS.LANDING)} style={{ cursor: 'pointer' }} />
        <Button type='text' style={{ fontSize: 20 }} onClick={(): void => navigate(PATHS.SIGNUP)}>
          Գրանցել ընկերություն
        </Button>
      </Row>
      <Row
        style={{
          backgroundImage: 'url(./assets/auth_left.png)',
          backgroundSize: 'contain',
          height: 'calc(100vh - 100px)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Col push={4} span={20}>
          <Row align={'middle'} justify={'center'} style={{ height: '70vh' }}>
            <AsnForm
              form={form}
              layout='vertical'
              onFinish={(values): void => onFinish(values as ISignIn)}
              style={{ maxWidth: '460px', width: '42vw' }}
              validateMessages={validate_messages}
            >
              <Title level={2} style={{ marginBottom: 30 }}>
                Մուտք գործել
              </Title>
              <AsnForm.Item name='email' label='Էլ․հասցե' rules={[...emailRules]}>
                <Input />
              </AsnForm.Item>
              <AsnForm.Item
                name='password'
                label='Գաղտնաբառ'
                rules={[{ required: true }]}
                style={{ marginBottom: 0 }}
                normalize={(value: string): string => value.trim()}
              >
                <Input.Password />
              </AsnForm.Item>
              <Button
                type='text'
                style={{ color: `${AZURE}`, padding: 0, marginBottom: 80 }}
                onClick={(): void => navigate(PATHS.FORGOT_PASSWORD)}
              >
                Մոռացե՞լ եք գաղտնաբառը
              </Button>
              <Row justify={'end'}>
                <Button type='primary' htmlType='submit'>
                  Մուտք գործել
                </Button>
              </Row>
            </AsnForm>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SignIn;
