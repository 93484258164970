import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
const url = '/api/teacher';

const useDeleteLecturers = (options = {}): UseMutationResult<void, unknown, string, unknown> => {
  return useMutation(async (params: string) => {
    await client.delete(`${url}/${params}`);
  }, options);
};
export default useDeleteLecturers;
