import React from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { ImageContainer } from '../Error403';
import AsnButton from '../Button';
import error from '../../assets/icons/error500.png';
import serverDisconnect from '../../assets/icons/server-disconnect.png';

const { Title } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5vw;

  @media (max-width: 600px) {
    bottom: 35vw;
    .ant-typography {
      font-size: 24px !important;
    }
  }
  @media (min-width: 800px) {
    bottom: 30vw;
  }
  @media (min-width: 900px) {
    bottom: 20vw;
  }
  @media (min-width: 1300px) {
    bottom: 10vw;
  }
  @media (min-width: 1300px) {
    bottom: 5vw;
  }
`;

const Error500: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '10vw',
        position: 'relative',
      }}
    >
      <ImageContainer
        style={{
          width: '15vw',
          height: '15vw',
        }}
        src={error}
      ></ImageContainer>
      <ImageContainer
        style={{
          width: '80vw',
        }}
        src={serverDisconnect}
      />
      <Container>
        <Title level={1}>Ներքին սերվերի խնդիր</Title>
        <AsnButton
          type='primary'
          onClick={(): void => navigate('/')}
          style={{
            marginTop: '2rem',
          }}
        >
          Վերադառնալ գլխավոր էջ
        </AsnButton>
      </Container>
    </div>
  );
};

export default Error500;
