import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
import { IParamsBlok } from '../types/users/admin';
export const url = 'api/user';

const useBlockUser = (options = {}): UseMutationResult<void, unknown, IParamsBlok, unknown> => {
  return useMutation(
    async (params: IParamsBlok) => {
      await client.patch(`${url}/${params.id}`);
    },
    {
      ...options,
    },
  );
};

export default useBlockUser;
