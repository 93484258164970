import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Pagination, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';

import PublicCompanyCard from '../PublicCompanyCard';
import Spinner from '../Spinner';
import { ITabsComponentsProps } from '../../types/public/types';
import AsnSearch from '../AsnSearch';

const PublicActiveLists: React.FC<ITabsComponentsProps> = ({ company, isFetching, params, setParams, count }) => {
  const [search, setSearch] = useState<string>('');
  const [filterParams] = useSearchParams();
  const pageParams = filterParams.get('page');

  useEffect(() => {
    if (pageParams) {
      setParams({
        ...params,
        offset: (+pageParams - 1) * 10,
      });
    }
  }, [pageParams]);

  const handlePagination = (page: number): void => {
    setParams({
      ...params,
      offset: (page - 1) * 10,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      setParams({
        ...params,
        offset: 0,
        search: '',
      });
      setSearch('');
    }
    setSearch(e.target.value);
  };

  const onSearch = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setParams({
      ...params,
      search: e.currentTarget.value,
      offset: 0,
    });
    setSearch(e.currentTarget.value);
  };
  if (isFetching) {
    return <Spinner />;
  }
  return (
    <div>
      <AsnSearch
        suffix={<SearchOutlined />}
        style={{ maxWidth: 450 }}
        onPressEnter={onSearch}
        onChange={onChange}
        value={search}
      />
      {company?.map((item) => <PublicCompanyCard key={item.id} info={item} />)}
      {count <= 10 ? null : (
        <Row justify={'center'} style={{ padding: '32px 0px' }}>
          <Pagination
            total={count}
            showSizeChanger={false}
            current={params?.offset / 10 + 1}
            onChange={handlePagination}
          />
        </Row>
      )}
    </div>
  );
};

export default PublicActiveLists;
