import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/plots';

import { IStatisticByRegions } from '../../../api/admin/types/statistics';

const CompanyByRegion: React.FC<{
  pieData: IStatisticByRegions[];
}> = ({ pieData }) => {
  const [data, setData] = useState<IStatisticByRegions[]>([]);

  useEffect(() => {
    setData(pieData);
  }, [pieData]);

  const config = {
    appendPadding: 10,
    data,
    angleField: 'count',
    colorField: 'type',
    radius: 0.9,
    // label: {
    //   type: 'spider',
    //   labelHeight: 28,
    //   content: '{name}\n{percentage}',
    // },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};

export default CompanyByRegion;
