import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col, Typography, Input, Modal } from 'antd';

import { COLORS, PATHS, emailRules } from '../../../constants';
import { AsnForm } from '../../../components';
import { useForgotPassword } from '../../../api';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';

const { Title, Paragraph } = Typography;
const { AZURE } = COLORS;

const ForgotPassword: React.FC = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: forgotPassword } = useForgotPassword();
  const onFinish = (values: { email: string }): void => {
    forgotPassword(values, {
      onSuccess: () => {
        setOpenSuccessModal(true);
      },
    });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: 'url(./assets/auth_right.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          position: 'relative',
          height: '100%',
        }}
      >
        <Row style={{ position: 'absolute', top: 22, left: 80, zIndex: 2 }}>
          <Logo onClick={(): void => navigate(PATHS.LANDING)} style={{ cursor: 'pointer' }} />
        </Row>
        <Row>
          <Col span={19}>
            <Row align={'middle'} justify={'center'} style={{ height: '90vh' }}>
              <AsnForm
                form={form}
                layout='vertical'
                onFinish={(values): void => onFinish(values as { email: string })}
                style={{ maxWidth: '460px', width: '42vw' }}
              >
                <Title level={2} style={{ marginBottom: 30 }}>
                  Մոռացե՞լ եք գաղտնաբառը
                </Title>
                <Paragraph style={{ marginBottom: 16 }}>
                  Խնդրում ենք մուտքագրել էլ․ հասցեն, որն օգտագործում եք “zinhub.am” մուտք գործելու համար։
                </Paragraph>
                <AsnForm.Item name='email' label='Email' rules={[...emailRules]} style={{ marginBottom: 0 }}>
                  <Input />
                </AsnForm.Item>
                <Button
                  type='text'
                  style={{ color: `${AZURE}`, padding: 0, marginBottom: 40 }}
                  onClick={(): void => navigate(PATHS.SIGNIN)}
                >
                  Վերադառնալ մուտք գործելու համար
                </Button>
                <Row justify={'end'}>
                  <Button type='primary' htmlType='submit'>
                    Վերականգնել գաղտնաբառը
                  </Button>
                </Row>
              </AsnForm>
            </Row>
          </Col>
        </Row>
      </div>
      {openSuccessModal && (
        <Modal
          open={openSuccessModal}
          centered
          footer={false}
          onCancel={(): void => setOpenSuccessModal(false)}
          bodyStyle={{ padding: '40px 0px' }}
          style={{ maxWidth: 750 }}
          width={'45vw'}
        >
          <Paragraph style={{ fontSize: '20px', margin: '4px', textAlign: 'center' }}>
            Գաղտնաբառի փոփոխման վերաբերյալ հաղորդագրությունը ուղարկված է{' '}
            <span style={{ color: `${AZURE}`, textDecoration: 'underline' }}>{form.getFieldValue('email')}</span>{' '}
            հասցեին
          </Paragraph>
        </Modal>
      )}
    </>
  );
};

export default ForgotPassword;
