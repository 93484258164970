import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import client from '../../client';
import { IParamsUpdateStories } from '../types/stories/types';

export const url = 'api/admin/success-stories';

const useUpdateStories = (options = {}): UseMutationResult<void, AxiosError, IParamsUpdateStories, unknown> =>
  useMutation(async (params: IParamsUpdateStories) => {
    await client.put(`${url}/${params.id}`, params.storiesData);
  }, options);
export default useUpdateStories;
