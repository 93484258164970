import { FormInstance, Rule, RuleObject } from 'antd/es/form';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload';

import { passwordRegexp } from '../constants';
import { TUploadProps } from '../types/company';

export const rulesPassword = (field: string): Rule[] => [
  {
    required: true,
    message: 'Խնդրում ենք լրացնել Կրկնել գաղտնաբառը',
  },
  { pattern: passwordRegexp, message: 'Գաղտնաբառը պետք է պարունակի առնվազն մեկ թիվ եւ մեկ տառ, 8-60 նիշ' },

  ({ getFieldValue }): RuleObject => ({
    async validator(_, value): Promise<void> {
      if (getFieldValue(field) === value) {
        return await Promise.resolve();
      }
      return await Promise.reject(new Error('Ձեր մուտքագրած երկու գաղտնաբառերը չեն համընկնում!'));
    },
  }),
];

export const trimSpaces = (val: string): string => val?.replace(/\s+/g, ' ').trim();

export const trimAllSpaces = (val: string): string => val?.replace(/\s+/g, '').trim();

export const uploadPropsImage: TUploadProps = (setUrl, setEdit?) => {
  return {
    maxCount: 1,
    showUploadList: false,
    accept: '.jpeg, .png, .jpg, .webp, .JPEG, .PNG, .JPG',
    customRequest: (options: UploadRequestOption<unknown>): void => {
      const { file } = options;
      const reader = new FileReader();
      reader.onload = (): void => {
        const result = reader.result as string;
        if (result !== null) {
          setUrl(result);
          if (setEdit) {
            setEdit(false);
          }
        }
      };
      reader.readAsDataURL(file as RcFile);
    },
  };
};

export const handleEditorErrors: (
  form: FormInstance,
  field: string,
  data: string,
  min: number,
  max: number,
) => boolean = (form, field, data, min, max): boolean => {
  let bool;
  if (
    data?.replace(/<p[^>]*>|<\/p>|<ul[^>]*>|<\/ul>|<li[^>]*>|<\/li>/g, '')?.length > min ||
    data?.replace(/<p[^>]*>|<\/p>|<ul[^>]*>|<\/ul>|<li[^>]*>|<\/li>/g, '')?.length < max
  ) {
    form?.setFields([
      {
        name: [field],
        errors: [],
      },
    ]);
    bool = false;
  }
  if (data?.replace(/<p[^>]*>|<\/p>|<ul[^>]*>|<\/ul>|<li[^>]*>|<\/li>/g, '').length > 0) {
    form?.setFields([
      {
        name: [field],
        errors: [],
      },
    ]);
    bool = false;
  }
  if (
    data?.replace(/<p[^>]*>|<\/p>|<ul[^>]*>|<\/ul>|<li[^>]*>|<\/li>/g, '').length < min ||
    data?.replace(/<p[^>]*>|<\/p>|<ul[^>]*>|<\/ul>|<li[^>]*>|<\/li>/g, '').length > max
  ) {
    form?.setFields([
      {
        name: [field],
        errors: [`Դաշտը պետք է պարունակի ${min}-${max} նիշ`],
      },
    ]);
    bool = true;
  }
  if (!data?.replace(/<p[^>]*>|<\/p>|<ul[^>]*>|<\/ul>|<li[^>]*>|<\/li>/g, '').length && min !== 0) {
    form?.setFields([
      {
        name: [field],
        errors: [`Խնդրում ենք լրացնել դաշտը`],
      },
    ]);
    bool = true;
  }
  return bool as boolean;
};
export const useCheckTrainingCount = (width: number): number => {
  let count = 0;
  if (width > 1300) {
    count = 5;
  }
  if (width > 1140 && width < 1300) {
    count = 4;
  }
  if (width > 830 && width < 1140) {
    count = 3;
  }
  if (width > 530 && width < 830) {
    count = 2;
  }
  if (width > 300 && width < 530) {
    count = 1;
  }

  return count;
};
