import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { TUseDeleteById } from '../../types/api/company';
import { ISuccesMessage } from '../../types/global';

const url = '/api/statements/:id';

const useDeleteStatementById: TUseDeleteById = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, string, unknown>({
    mutationFn: (id) => {
      return client.delete(url.replace(':id', id));
    },
  });

  return mutation;
};

export default useDeleteStatementById;
