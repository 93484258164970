import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { GetAdmin, TUseGetAdminById } from '../types/users/admin';
export const url = 'api/user/:id';

const useGetAdminById: TUseGetAdminById = (id, options) => {
  const result = useQuery({
    queryKey: [url, id],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data,
  });
  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as GetAdmin),
    isLoading,
    isFetching,
  };
};

export default useGetAdminById;
