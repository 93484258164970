import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { TStaticValues, TUseGetTypeInfo } from '../../types/api/static';

const url = '/api/static/all/info';

const useGetStaticInfoByType: TUseGetTypeInfo = (type, options) => {
  const result = useQuery({
    queryKey: [url, type],
    queryFn: () => client.post(url, { statementType: type }),
    ...options,
    select: (data) => data?.data?.result,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as TStaticValues),
    isLoading,
    isFetching,
  };
};

export default useGetStaticInfoByType;
