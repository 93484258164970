import React from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router';

import { ImageContainer } from '../Error403';
import AsnButton from '../Button';
import error from '../../assets/icons/error404.png';

const { Title } = Typography;

const Error404: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImageContainer src={error}></ImageContainer>
      <Title level={1}>Էջը չի գտնվել</Title>
      <AsnButton
        type='primary'
        onClick={(): void => navigate('/')}
        style={{
          marginTop: '2rem',
        }}
      >
        Վերադառնալ գլխավոր էջ
      </AsnButton>
    </div>
  );
};

export default Error404;
