import React from 'react';
import { Form, Input, Radio, Typography } from 'antd';

import { ReactComponent as EditedIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye.svg';
import AsnButton from '../../../Button';
import { emailRules } from '../../../../constants';
import useGetAdminById from '../../../../api/admin/users/useGetAdminById';

import { Container, CustomRadio, RadioContainer } from './styled';

const { Title } = Typography;

const AddAdminModalContent: React.FC<{
  openEditedAdminId?: string;
}> = ({ openEditedAdminId }) => {
  const form = Form.useFormInstance();

  const { data } = useGetAdminById(openEditedAdminId as string, {
    enabled: !!openEditedAdminId,
  });

  if (openEditedAdminId !== '') {
    form.setFieldsValue({
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      organization: data?.organization,
      permission: data?.permission,
    });
  }

  return (
    <div
      style={{
        padding: '3rem 4.5rem 1rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Title
        level={3}
        style={{
          marginBottom: '24px',
          fontSize: '20px',
          fontWeight: 500,
        }}
      >
        {openEditedAdminId ? 'Խմբագրել' : 'Ավելացնել ադմինիստրատոր'}
      </Title>
      <Container>
        <Form.Item name='email' rules={[...emailRules]} label='Email'>
          <Input />
        </Form.Item>
        <Form.Item
          name='firstName'
          label='Անուն'
          rules={[
            {
              required: true,
              message: 'Անուն դաշտը պարտադիր է',
            },
            { min: 3, message: 'Անուն դաշտը պետք է ունենա առնվազն 3 նիշ' },
            { max: 50, message: 'Անուն դաշտը պետք է ունենա առավելագույնը 50 նիշ' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='lastName'
          label='Ազգանուն'
          rules={[
            {
              required: true,
              message: 'Ազգանուն դաշտը պարտադիր է',
            },
            { min: 3, message: 'Ազգանուն դաշտը պետք է ունենա առնվազն 3 նիշ' },
            { max: 50, message: 'Ազգանուն դաշտը պետք է ունենա առավելագույնը 50 նիշ' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='organization'
          label='Կազմակերպություն'
          rules={[
            {
              required: true,
              message: 'Կազմակերպություն դաշտը պարտադիր է',
            },
            { min: 3, message: 'Կազմակերպություն դաշտը պետք է ունենա առնվազն 3 նիշ' },
            { max: 100, message: 'Կազմակերպություն դաշտը պետք է ունենա առավելագույնը 100 նիշ' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='permission'
          rules={[
            {
              required: true,
              message: 'Դերը դաշտը պարտադիր է',
            },
          ]}
        >
          <Radio.Group
            style={{
              width: '100%',
            }}
          >
            <RadioContainer direction='horizontal'>
              <CustomRadio value={'EDIT'}>
                <EditedIcon /> Խմբագիր
              </CustomRadio>
              <CustomRadio value={'VIEW'}>
                <EyeIcon />
                Դիտորդ
              </CustomRadio>
            </RadioContainer>
          </Radio.Group>
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '24px',
          }}
        >
          <AsnButton type='primary' htmlType='submit'>
            Պահպանել
          </AsnButton>
        </div>
      </Container>
    </div>
  );
};
export default AddAdminModalContent;
