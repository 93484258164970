import React from 'react';
import CountUp from 'react-countup';
import { Typography, Row, Space, Statistic } from 'antd';

import { IStatisticCard } from '../../types/components';
import { COLORS } from '../../constants';

const { Paragraph } = Typography;
const { TEXT_COLOR } = COLORS;

const StatisticCard: React.FC<IStatisticCard> = ({ svg, title, count, rightSvg }) => {
  const formatter = (value: number): JSX.Element => (
    <CountUp start={0} end={value} separator='' duration={8} style={{ color: `${TEXT_COLOR}` }} />
  );
  return (
    <Row style={{ borderRadius: '10px', border: '1px solid rgba(31, 130, 255, 0.20)', padding: 8 }}>
      <Space direction='horizontal' style={{ width: '100%', justifyContent: 'space-between' }}>
        <Space direction='vertical' size={16}>
          {svg}
          <Paragraph style={{ marginBottom: 0, height: 44 }}>{title}</Paragraph>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Statistic value={count} formatter={(val): JSX.Element => formatter(val as number)} />
            {title === 'Հաջողած մասնակիցներ' && <div style={{ fontSize: '24px' }}>%</div>}
          </div>
        </Space>
        {rightSvg}
      </Space>
    </Row>
  );
};

export default StatisticCard;
