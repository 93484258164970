import { Col, Row, Space } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';
const { WATER, AZURE } = COLORS;

export const AsnRow = styled(Row)`
  border: 0.5px solid ${WATER};
  border-radius: 10px;
  padding: 8px;
  margin-top: 20px;
  :hover {
    border: 0.5px solid ${AZURE};
  }
  &.ant-row {
    .more {
      visibility: hidden;
    }
    &:hover {
      .more {
        visibility: visible;
      }
    }
  }
`;

export const SpaceContainer = styled(Space)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 667px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Content = styled(Col)`
  padding-left: 1rem;
  @media (max-width: 667px) {
    padding-left: 0;
    padding-top: 8px;
  }
`;
