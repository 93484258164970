import styled from 'styled-components';
import { Button } from 'antd';

import { COLORS } from '../../constants';

const { WHITE, AZURE } = COLORS;

const CircleButton = styled(Button)`
  &.ant-btn {
    padding: 0px;
  }
  &.ant-btn-primary:disabled {
    background-color: ${WHITE};
    border-color: ${AZURE};
    svg {
      path {
        fill: ${AZURE};
      }
    }
  }
  &.ant-btn-primary {
    svg {
      path {
        fill: ${WHITE};
      }
    }
  }
`;

export default CircleButton;
