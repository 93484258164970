import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../../client';
import { ISuccesMessage } from '../../../types/global';
import { IParamsStories } from '../types/stories/types';
export const url = 'api/admin/success-stories';

export type TUseCreateStories = () => UseMutationResult<
  AxiosResponse<ISuccesMessage>,
  AxiosError,
  IParamsStories,
  unknown
>;

const useCreateStories: TUseCreateStories = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, IParamsStories, unknown>({
    mutationFn: (params) => {
      return client.post(url, params);
    },
  });

  return mutation;
};

export default useCreateStories;
