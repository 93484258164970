import styled from 'styled-components';
import { Button } from 'antd';

import { COLORS } from '../../constants';

const { AZURE, ALICE_BLUE, GRAY, DARK_GRAY } = COLORS;

const AsnButton = styled(Button)`
  display: flex;
  align-items: center;
  &.ant-btn-default {
    border-color: ${AZURE};
    color: ${AZURE};
    :hover {
      background-color: ${(props): string => (props.color ? props.color : ALICE_BLUE)};
    }
  }
  &.add {
    &.ant-btn-default {
      border-color: ${GRAY};
      color: ${DARK_GRAY};
    }
    span {
      width: 100%;
    }
  }
`;

export default AsnButton;
