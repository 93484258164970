import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/hy_AM';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { antTheme } from './assets/antd';
import { AuthProvider } from './context';
import { routers } from './routes';
import 'antd/dist/reset.css';

dayjs.extend(utc);

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <ConfigProvider theme={antTheme} locale={locale}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={routers} />
        </QueryClientProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
