import styled from 'styled-components';
import { Card } from 'antd';

const CourseCard = styled(Card)`
  &.ant-card-hoverable {
    min-width: 224px;
    border: 1px solid rgba(85, 150, 157, 0.2);
    padding-top: 1px;
  }
  &.ant-card .ant-card-body {
    padding: 8px;
  }
`;

export default CourseCard;
