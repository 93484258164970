import React from 'react';
import { TabsProps } from 'antd';

import AsnTab from '../../../components/Tab';
import Pending from '../../../components/Admin/Requests/Pending';
import Rejected from '../../../components/Admin/Requests/Rejected';

import { RequestsContainer } from './styled';

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Ընթացիկ',
    children: <Pending />,
  },
  {
    key: '2',
    label: 'Մերժված',
    children: <Rejected />,
  },
];

const Requests: React.FC = () => {
  return (
    <RequestsContainer>
      <AsnTab width='100%' defaultActiveKey='1' items={items} />
    </RequestsContainer>
  );
};

export default Requests;
