import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetStatementById } from '../../../../api';
import { OtherStatementView as OtherViewComponent, Spinner } from '../../../../components';

const OtherView: React.FC = () => {
  const { id } = useParams();
  const { data: statement, isFetching } = useGetStatementById(id as string, { enabled: Boolean(id) });

  if (isFetching) {
    return <Spinner />;
  }

  return <OtherViewComponent other={statement} isPrivate={true} />;
};

export default OtherView;
