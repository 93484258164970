import styled from 'styled-components';

export const ViewLecturersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 1.5rem 5rem 3.5rem 2.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
