import React from 'react';
import { Typography } from 'antd';

import { IInsertedStatistics } from '../../../api/admin/types/statistics';

import { CardContainer } from './styled';
const { Text, Title } = Typography;

const StatisticsCard: React.FC<{ insertedStatistics: IInsertedStatistics }> = ({ insertedStatistics }) => {
  return (
    <CardContainer>
      <Text
        style={{
          fontSize: '16px',
        }}
      >
        {insertedStatistics?.title === 'Այլ' ? 'Ծառայություն' : insertedStatistics.title}
      </Text>
      <Title level={2}>{insertedStatistics?.value}</Title>
    </CardContainer>
  );
};

export default StatisticsCard;
