import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
export const url = 'api/admin/success-stories';

const useUpdateStatusStories = (options = {}): UseMutationResult<void, unknown, string, unknown> => {
  return useMutation(
    async (id: string) => {
      await client.patch(`${url}/${id}`);
    },
    {
      ...options,
    },
  );
};

export default useUpdateStatusStories;
