import { Radio, Space } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../../../constants';

const { GRAY, DARK_GRAY } = COLORS;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item-control-input-content {
    max-width: 460px;
  }
  .ant-form-item-label {
    padding: 0 0 4px;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-form-item-label {
    color: ${DARK_GRAY};
  }
  input {
    border: 1px solid ${GRAY};
  }
`;

export const CustomRadio = styled(Radio)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid ${GRAY};
  padding: 0.5rem 1rem;
  width: 100%;
  min-height: 44px;
  span {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 8px;
  }
  ::after {
    content: none;
  }
`;

export const RadioContainer = styled(Space)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .ant-space-item {
    width: 100%;
  }
`;
