import styled from 'styled-components';

export const AnnouncementsContainer = styled.div`
  padding: 20px;
  width: 100%;
`;

export const PostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  .ant-pagination-options-size-changer {
    display: none;
  }
`;
