import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { OtherStatementView, Spinner } from '../../../components';
import { useGetStatementById } from '../../../api';

const OtherViewPublic: React.FC = () => {
  const [filterParams] = useSearchParams();
  const id = filterParams.get('id');
  const { data: statement, isFetching } = useGetStatementById(id as string, { enabled: Boolean(id) });
  if (isFetching) {
    return <Spinner />;
  }
  return <OtherStatementView other={statement} />;
};

export default OtherViewPublic;
