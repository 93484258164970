import { createBrowserRouter } from 'react-router-dom';

import { PATHS } from '../constants';
import SignIn from '../pages/public/Auth/SignIn';
import Landing from '../pages/public/Landing';
import ForgotPassword from '../pages/public/Auth/ForgotPassword';
import SignUp from '../pages/public/Auth/SignUp';
import MyInfo from '../pages/company/MyInfo';
import CompanyAnnouncements from '../pages/company/Announcements';
import Lecturers from '../pages/company/Lecturers';
import RestorePassword from '../pages/public/Auth/RestorePassword';
import ChangePassword from '../pages/public/Auth/ChangePassword';
import Users from '../pages/admin/users';
import Announcements from '../pages/admin/announcements';
import Requests from '../pages/admin/requests';
import SuccessStories from '../pages/admin/stories';
import Statistics from '../pages/admin/statistics';
import VerifyEmail from '../pages/public/Auth/VerifyEmail';
import CreateLecturers from '../pages/company/Lecturers/CreateLecturers';
import EditLecturers from '../pages/company/Lecturers/EditLecturers';
import ViewLecturers from '../pages/company/Lecturers/ViewLecturers/Index';
import EditStories from '../pages/admin/stories/edit';
import CreateStories from '../pages/admin/stories/create';
import CourseForm from '../pages/company/Announcements/course/Course';
import JobForm from '../pages/company/Announcements/work/Job';
import OtherForm from '../pages/company/Announcements/other/Other';
import CourseView from '../pages/company/Announcements/course/CourseView';
import WorkView from '../pages/company/Announcements/work/JobView';
import OtherView from '../pages/company/Announcements/other/OtherView';
import CourseEdit from '../pages/company/Announcements/course/CourseEdit';
import JobEdit from '../pages/company/Announcements/work/JobEdit';
import OtherEdit from '../pages/company/Announcements/other/OtherEdit';
import AcceptInvitation from '../pages/public/Auth/AcceptInvitation';
import StoriesView from '../pages/admin/stories/view';
import CompanyViewAdmin from '../pages/admin/users/company-view';
import AdminCourseView from '../pages/admin/announcements/courseView';
import AdminOtherView from '../pages/admin/announcements/otherView';
import AdminWorkView from '../pages/admin/announcements/workView';
import EditMyInfo from '../pages/company/MyInfo/EditMyInfo';
import CoursePublic from '../pages/public/Course/Index';
import OtherPublic from '../pages/public/Other/Index';
import WorkPublic from '../pages/public/Work/Index';
import CompanyPublic from '../pages/public/Company/Index';
import CourseViewPublic from '../pages/public/Course/CourseView';
import OtherViewPublic from '../pages/public/Other/OtherView';
import WorkViewPublic from '../pages/public/Work/WorkView';
import CompanyViewPublic from '../pages/public/Company/View';
import Homepage from '../pages/company/Homepage';
import CompanyActiveStatements from '../pages/public/CompanyActiveStatements';
import AdminMyInfo from '../pages/admin/myInfo';
import FrequentlyQuestions from '../pages/public/FrequentlyQuestions';

import ErrorBoundary from './ErrorBoundary';
import { CompanyRoutes } from './CompanyRoutes';
import { PublicRoutesAuth } from './PublicRoutesAuth';
import { AdminRoutes } from './AdminRoutes';
import { PublicRoutes } from './PublicRoutes';

export const routers = createBrowserRouter([
  {
    element: <PublicRoutes />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: PATHS.HOMEPAGE,
        element: <Homepage />,
      },
      {
        path: PATHS.COURSE,
        element: <CoursePublic />,
      },
      {
        path: PATHS.COURSE_VIEW_PUBLIC,
        element: <CourseViewPublic />,
      },
      {
        path: PATHS.OTHER,
        element: <OtherPublic />,
      },
      {
        path: PATHS.OTHER_VIEW_PUBLIC,
        element: <OtherViewPublic />,
      },
      {
        path: PATHS.WORK,
        element: <WorkPublic />,
      },
      {
        path: PATHS.WORK_VIEW_PUBLIC,
        element: <WorkViewPublic />,
      },
      {
        path: PATHS.COMPANY,
        element: <CompanyPublic />,
      },
      {
        path: PATHS.COMPANY_VIEW_PUBLIC,
        element: <CompanyViewPublic />,
      },
      {
        path: PATHS.COMPANY_ACTIVE_STATEMENTS,
        element: <CompanyActiveStatements />,
      },
      {
        path: PATHS.HELP,
        element: <FrequentlyQuestions />,
      },
    ],
  },
  {
    element: <PublicRoutesAuth />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: PATHS.LANDING,
        element: <Landing />,
      },
      {
        path: PATHS.SIGNIN,
        element: <SignIn />,
      },
      {
        path: PATHS.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: PATHS.SIGNUP,
        element: <SignUp />,
      },
      {
        path: PATHS.RESTORE_PASSWORD,
        element: <RestorePassword />,
      },
      {
        path: PATHS.VERIFY_EMAIL,
        element: <VerifyEmail />,
      },
      {
        path: PATHS.ACCEPT_INVITATION,
        element: <AcceptInvitation />,
      },
    ],
  },
  {
    element: <AdminRoutes />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: PATHS.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
      {
        path: PATHS.ADMIN,
        element: <Users />,
      },
      {
        path: PATHS.ADMIN_COMPANY_VIEW,
        element: <CompanyViewAdmin />,
      },
      {
        path: PATHS.ANNOUNCEMENTS,
        element: <Announcements />,
      },
      {
        path: PATHS.ANNOUNCEMENTS_COURSE_VIEW,
        element: <AdminCourseView />,
      },
      {
        path: PATHS.ANNOUNCEMENTS_OTHER_VIEW,
        element: <AdminOtherView />,
      },
      {
        path: PATHS.ANNOUNCEMENTS_WORK_VIEW,
        element: <AdminWorkView />,
      },
      {
        path: PATHS.REQUESTS,
        element: <Requests />,
      },
      {
        path: PATHS.STORIES,
        element: <SuccessStories />,
      },
      {
        path: PATHS.STORIES_CREATE,
        element: <CreateStories />,
      },
      {
        path: PATHS.STORIES_EDIT,
        element: <EditStories />,
      },
      {
        path: PATHS.STATISTICS,
        element: <Statistics />,
      },
      {
        path: PATHS.STORIES_VIEW,
        element: <StoriesView />,
      },
      {
        path: PATHS.ADMIN_MYINFO,
        element: <AdminMyInfo />,
      },
    ],
  },
  {
    element: <CompanyRoutes />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: PATHS.CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
      { path: PATHS.COMPANY_MYINFO, element: <MyInfo /> },
      {
        path: PATHS.COMPANY_ANNOUNCMENTS,
        element: <CompanyAnnouncements />,
      },
      {
        path: PATHS.COMPANY_LECTURERS,
        element: <Lecturers />,
      },
      {
        path: PATHS.COMPANY_LECTURERS_CREATE,
        element: <CreateLecturers />,
      },
      {
        path: PATHS.COMPANY_LECTURERS_EDIT,
        element: <EditLecturers />,
      },
      {
        path: PATHS.COMPANY_LECTURER_VIEW,
        element: <ViewLecturers />,
      },
      {
        path: PATHS.COMPANY_CREATE_COURSE,
        element: <CourseForm />,
      },
      {
        path: PATHS.COMPANY_CREATE_JOB,
        element: <JobForm />,
      },
      {
        path: PATHS.COMPANY_CREATE_OTHER,
        element: <OtherForm />,
      },
      {
        path: PATHS.COURSE_VIEW,
        element: <CourseView />,
      },
      {
        path: PATHS.JOB_VIEW,
        element: <WorkView />,
      },
      {
        path: PATHS.OTHER_VIEW,
        element: <OtherView />,
      },
      {
        path: PATHS.COURSE_EDIT,
        element: <CourseEdit />,
      },
      {
        path: PATHS.WORK_EDIT,
        element: <JobEdit />,
      },
      {
        path: PATHS.OTHER_EDIT,
        element: <OtherEdit />,
      },
      {
        path: PATHS.COMPANY_MYINFO_EDIT,
        element: <EditMyInfo />,
      },
    ],
  },
]);
