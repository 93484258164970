import { useSwiper } from 'swiper/react';
import { ArrowRightOutlined } from '@ant-design/icons';

import { CircleButton } from '../../../components';

const SwiperButtonNextCourse: React.FC = () => {
  const swiper = useSwiper();

  return (
    <CircleButton
      type='primary'
      shape='circle'
      icon={<ArrowRightOutlined />}
      onClick={(): void => swiper.slideNext()}
    />
  );
};

export default SwiperButtonNextCourse;
