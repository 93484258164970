import { Menu } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { TEXT_COLOR } = COLORS;

const AsnMenu = styled(Menu)`
  &.ant-menu {
    justify-content: end;
  }
  font-size: 16px;
  .ant-menu-title-content {
    color: ${TEXT_COLOR};
    font-weight: 600;
  }
  &.ant-menu-horizontal {
    border-bottom: none;
  }
`;

export default AsnMenu;
