import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { IData, TUseGetStories } from '../types/stories/types';

export const url = 'api/admin/success-stories/all';

const useGetAllStories: TUseGetStories = (params, options) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.post(url, params),
    ...options,
    select: (data) => data?.data,
  });
  const { data, isLoading, isSuccess, isFetching } = result;

  return {
    data: isSuccess ? data : ({} as IData),
    isLoading,
    isFetching,
  };
};
export default useGetAllStories;
