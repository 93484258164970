import React from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { MainLayout } from '../components';

import ScrollToTop from './ScrollToTop';

export const PublicRoutes: React.FC = () => {
  const { user } = useAuth();
  return (
    <ScrollToTop>
      <MainLayout homepage={true} loginHomepage={user?.id ? true : false}>
        <Outlet />
      </MainLayout>
    </ScrollToTop>
  );
};
