import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/charts';

import { IStatementsByMonths } from '../../../api/admin/types/statistics';

const AnnouncementsByCount: React.FC<{ statementsByMonths: IStatementsByMonths[] }> = ({ statementsByMonths }) => {
  const [data, setData] = useState<IStatementsByMonths[]>([]);

  useEffect(() => {
    setData(statementsByMonths);
  }, [statementsByMonths]);

  const config = {
    data,
    xField: 'month',
    yField: 'count',
    seriesField: 'type',
    isGroup: true,
    color: ['#4472C4', '#FF882F', '#A5A5A5'],
    dodgePadding: 0.1,
    columnStyle: {
      radius: [2, 2, 0, 0],
    },
  };
  return <Column {...config} />;
};

export default AnnouncementsByCount;
