import React from 'react';
import { Modal, Typography, Space } from 'antd';

import { ReactComponent as SuccessSvg } from '../../assets/icons/success.svg';
import { ISuccessModal } from '../../types/components';
const { Paragraph } = Typography;

const SuccessModal: React.FC<ISuccessModal> = ({ openSuccessModal, onCloseModal, content, children, width }) => {
  return (
    <Modal
      open={openSuccessModal}
      centered
      footer={false}
      onCancel={(): void => onCloseModal()}
      bodyStyle={{ padding: '40px 0px' }}
      style={{ maxWidth: 750 }}
      width={width ?? '35vw'}
    >
      <Space direction='vertical' align={'center'} style={{ width: '100%' }}>
        <SuccessSvg
          style={{
            width: '60px',
            height: '60px',
            marginBottom: '10px',
          }}
        />
        <Paragraph style={{ fontSize: '16px', margin: '4px', textAlign: 'center' }}>{content}</Paragraph>
        {children}
      </Space>
    </Modal>
  );
};

export default SuccessModal;
