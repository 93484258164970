import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../client';
import { TUseSignUp, SignUpVariables } from '../../types/api/auth';
import { ISuccesMessage } from '../../types/global';

const url = '/api/auth/sign-up/company';

const useSignUp: TUseSignUp = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, SignUpVariables, unknown>({
    mutationFn: (user) => {
      return client.post(url, user);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default useSignUp;
