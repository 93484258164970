import styled from 'styled-components';

export const RootContainer = styled.div`
  padding: 1.5rem 8vw 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h5 {
    font-weight: 400;
  }
`;

export const InsertedStatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  @media (max-width: 1300px) {
    gap: 1rem;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;
