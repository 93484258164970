import React, { useState } from 'react';
import { Input, Modal, Table } from 'antd';
import { TableProps } from 'antd/lib';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { PATHS, STATUS } from '../../../../constants';
import ActionModal from '../../../ActionModal';
import { IBlockCompany, IPagination, ISortCompany } from '../../../../types/admin/users/company';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import useUpdateStatusCompany from '../../../../api/admin/users/useUpdateStatus';
import useGetCompany from '../../../../api/admin/users/useGetCompanys';
import { ICompanyListPending } from '../../../../api/admin/types/users/company';
import useResendAdmin from '../../../../api/admin/users/useResendAdmin';
import { useAuth } from '../../../../hooks/useAuth';

import { CompanyContainer } from './style';
import { columns } from './columns';

const CompanyTable: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [openIsResendCompany, setOpenIsResendCompany] = useState<string>('');
  const [blockCompany, setBlockCompany] = useState<IBlockCompany>({
    id: '',
    title: '',
    status: '',
  });
  const [statusCompany, setStatusCompany] = useState<string[]>([STATUS.ACTIVE, STATUS.INACTIVE, STATUS.BLOCKED]);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [sortCompany, setSortCompany] = useState<ISortCompany>({
    column: '',
    direction: '',
  });
  const [tablePagination, setTablePagination] = useState<IPagination>({
    current: 1,
    pageSize: 10,
  });

  const { data, isLoading } = useGetCompany({
    limit: tablePagination.pageSize,
    offset: (tablePagination.current - 1) * 10,
    statuses: statusCompany,
    search: searchCompany === '' ? undefined : searchCompany,
    orderBy: sortCompany.column === '' && sortCompany.direction === '' ? undefined : { ...sortCompany },
  });

  const { mutate: resendCompanyByEmail } = useResendAdmin();

  const { mutate: updateStatusCompanyById } = useUpdateStatusCompany({
    onSuccess: () => {
      setBlockCompany({
        id: '',
        title: '',
        status: '',
      });
      void queryClient.invalidateQueries(['api/company/all']);
    },
  });

  const handleTableChange: TableProps<ICompanyListPending>['onChange'] = (pagination, filters, sorter) => {
    if ((sorter as { order: string }).order !== undefined) {
      setSortCompany({
        column: (sorter as { field: string }).field,
        direction: (sorter as { order: string }).order === 'descend' ? 'DESC' : 'ASC',
      });
    } else {
      setSortCompany({
        column: '',
        direction: '',
      });
    }
    if (filters.status !== null) {
      setStatusCompany(filters.status as string[]);
    } else {
      setStatusCompany([STATUS.ACTIVE, STATUS.INACTIVE, STATUS.BLOCKED]);
    }
    setTablePagination({
      current: pagination.current as number,
      total: pagination.total,
      pageSize: 10,
    });
  };

  const onChangeCompanyStatus = (row: ICompanyListPending): void => {
    setBlockCompany({ id: row?.id, title: row.name, status: row?.checkStatus ?? '' });
  };

  const onCloseModal = (): void => {
    setBlockCompany({
      id: '',
      title: '',
      status: '',
    });
  };

  const onBlockCompany = (): void => {
    updateStatusCompanyById({ id: blockCompany.id });
  };

  const onSearchCompanyEnter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchCompany((e.target as HTMLInputElement).value.trim());
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const onResendCompany = (): void => {
    resendCompanyByEmail(
      { email: openIsResendCompany },
      {
        onSuccess: () => {
          setOpenIsResendCompany('');
        },
      },
    );
  };
  const onCloseCompanyModal = (): void => {
    setOpenIsResendCompany('');
  };

  const permissionColumns =
    user?.permission === 'VIEW' && user.role === 'ADMIN'
      ? columns(onChangeCompanyStatus, setOpenIsResendCompany).filter((item) => item.key !== 'operation')
      : columns(onChangeCompanyStatus, setOpenIsResendCompany);
  return (
    <CompanyContainer>
      <Input
        placeholder='Օրինակ'
        prefix={<SearchIcon />}
        style={{ width: '40%' }}
        onPressEnter={onSearchCompanyEnter}
      />
      <Table
        columns={permissionColumns}
        dataSource={data?.result}
        rowKey={(record): string => record?.id}
        loading={isLoading}
        pagination={{
          ...tablePagination,
          position: ['bottomCenter'],
          total: data?.count,
        }}
        onChange={handleTableChange}
        onRow={(
          record,
        ): {
          onClick: (event: React.SyntheticEvent) => void;
        } => {
          return {
            onClick: (event: React.SyntheticEvent<Element, Event>): void => {
              const target = event.target as Element;
              if (!target.closest('svg')) navigate(PATHS.ADMIN_COMPANY_VIEW.replace(':id', record.id));
            },
          };
        }}
      />
      <Modal centered open={!!blockCompany.id} onCancel={onCloseModal} footer={false}>
        <ActionModal
          text={
            blockCompany.status === STATUS.ACTIVE
              ? `Համոզվա՞ծ եք, որ ցանկանում եք Արգելափակել "${blockCompany.title}" կազմակերպությանը`
              : `Համոզվա՞ծ եք, որ ցանկանում եք Ապարգելափակել "${blockCompany.title}" կազմակերպությանը`
          }
          resolveFn={onBlockCompany}
          rejectFn={onCloseModal}
        />
      </Modal>
      <Modal centered open={openIsResendCompany !== ''} onCancel={onCloseCompanyModal} footer={false}>
        <ActionModal
          text={`Համոզվա՞ծ եք, որ ցանկանում եք կրկին ուղարկել Հարթակին միանալու հրավերը "${openIsResendCompany}" էլեկտրոնային հասցեին`}
          resolveFn={onResendCompany}
          rejectFn={onCloseCompanyModal}
        />
      </Modal>
    </CompanyContainer>
  );
};

export default CompanyTable;
