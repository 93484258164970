import React, { useEffect, useState } from 'react';
import { Pagination, Row } from 'antd';
import { useSearchParams } from 'react-router-dom';

import useGetAllStatementsPublic from '../../../api/public/useGetAllStatementsPublic';
import { Spinner } from '../../../components';
import PublicCard from '../../../components/PublicListCard';
import { ListContainer } from '../Styled/styled';
import PublicFilters from '../../../components/PublicFilters';
import { IFilterParams, IParamsGetAllStatementsPublicList } from '../../../types/api/public';

const WorkPublic: React.FC = () => {
  const [filterParamsPage, setFilterParamsPage] = useSearchParams();
  const pageParams = filterParamsPage.get('page');
  const [params, setParams] = useState<IParamsGetAllStatementsPublicList>({
    offset: 0,
    search: '',
  });
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    regionIds: [],
    formatIds: [],
    levelIds: [],
    filedStudyIds: [],
    employmentIds: [],
  });
  const { data, isLoading } = useGetAllStatementsPublic(
    {
      ...params,
      ...filterParams,
      limit: 10,
      type: 'WORK',
    },
    { enabled: true },
  );

  useEffect(() => {
    if (pageParams) {
      setParams({
        ...params,
        offset: (+pageParams - 1) * 10,
      });
    }
  }, [pageParams]);

  const handlePagination = (page: number): void => {
    setParams({
      ...params,
      offset: (page - 1) * 10,
    });
    setFilterParamsPage({
      page: String(page),
    });
  };

  return (
    <ListContainer>
      <PublicFilters
        params={params}
        setParams={setParams}
        filterData={{
          regions: data.filterData?.regions,
          courseLevel: data.filterData?.workLevel,
          filedOfStudy: data.filterData?.filedOfWork,
          employment: data.filterData?.employment,
        }}
        isLoading={isLoading}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        type='WORK'
      />
      {isLoading ? <Spinner /> : <>{data.result?.map((item) => <PublicCard key={item.id} info={item} />)}</>}
      {data?.count <= 10 ? null : (
        <Row justify={'center'} style={{ padding: '32px 0px' }}>
          <Pagination
            total={data?.count}
            showSizeChanger={false}
            current={params?.offset / 10 + 1}
            onChange={handlePagination}
          />
        </Row>
      )}
    </ListContainer>
  );
};

export default WorkPublic;
