import { COLORS } from '../../constants';

const { AZURE, WHITE, TEXT_COLOR } = COLORS;

export const antTheme = {
  components: {
    Typography: {
      colorText: TEXT_COLOR,
      colorTextHeading: TEXT_COLOR,
    },
    Button: {
      colorPrimary: AZURE,
      borderRadius: 6,
      fontSize: 16,
      defaultBorderColor: WHITE,
      colorBorder: WHITE,
      colorText: TEXT_COLOR,
      colorBgTextHover: WHITE,
      colorBgTextActive: WHITE,
    },
    Input: {
      colorPrimary: AZURE,
      colorPrimaryHover: AZURE,
      borderRadius: 6,
      controlHeight: 40,
    },
    Select: {
      colorPrimary: AZURE,
      colorPrimaryHover: AZURE,
      borderRadius: 6,
      controlHeight: 40,
    },
    DatePicker: {
      colorPrimary: AZURE,
      colorPrimaryHover: AZURE,
      borderRadius: 6,
      controlHeight: 40,
    },
    Tabs: {
      colorPrimary: AZURE,
      colorPrimaryHover: AZURE,
      fontSize: 16,
      colorText: TEXT_COLOR,
    },
    Collapse: {
      colorBgContainer: WHITE,
      colorFillAlter: WHITE,
      colorText: TEXT_COLOR,
      colorBorder: WHITE,
    },
  },
  token: {
    fontFamily: 'Noto Sans Armenian',
  },
};
