import React, { useState } from 'react';
import { Checkbox, Space, TabsProps } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useSearchParams } from 'react-router-dom';

import { IParamsGetAllCompany } from '../../../types/api/public';
import AsnTab from '../../../components/Tab';
import PublicActiveLists from '../../../components/Companyslist/Activelists';
import PublicAllLists from '../../../components/Companyslist/AllLists';
import useGetAllCompanyPublic from '../../../api/public/useGetAllCompanyPublic';

import { CompanyContainer, ItemContainer } from './styled';

const CompanyPublic: React.FC = () => {
  const [filterParams, setFilterParams] = useSearchParams();
  const pageParams = filterParams.get('page');
  const [params, setParams] = useState<IParamsGetAllCompany>({
    offset: 0,
    search: '',
  });
  const [companyType, setCompanyType] = useState<CheckboxValueType[]>([]);
  const [tabKey, setTabKey] = useState('1');

  const { data, isLoading } = useGetAllCompanyPublic(
    { ...params, type: companyType, hasActiveStatements: tabKey === '1' ? false : true },
    { enabled: true },
  );
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Բոլորը',
      children: (
        <PublicAllLists
          company={data.result}
          isFetching={isLoading}
          params={params}
          setParams={setParams}
          count={data.count}
        />
      ),
    },
    {
      key: '2',
      label: 'Ակտիվ',
      children: (
        <PublicActiveLists
          company={data.result}
          isFetching={isLoading}
          params={params}
          setParams={setParams}
          count={data.count}
        />
      ),
    },
  ];

  const onTypeChange = (value: CheckboxValueType[]): void => {
    setCompanyType(value);
    setParams({
      ...params,
      offset: 0,
    });
  };

  const onTabChange = (activeKey: string): void => {
    setTabKey(activeKey);
    setParams({
      ...params,
      offset: 0,
    });
    if (pageParams) {
      filterParams.delete('page');
      setFilterParams(filterParams);
    }
  };

  return (
    <CompanyContainer>
      <div
        style={{
          minWidth: '275px',
        }}
      >
        <Checkbox.Group onChange={onTypeChange} value={companyType}>
          <Space direction='vertical'>
            <Checkbox value={'WORK'}>Գործատու ընկերություն ({data.companiesByType?.work})</Checkbox>
            <Checkbox value={'TRINING'}>Ուսուցում և վերապատրաստում ({data.companiesByType?.training}) </Checkbox>
            <Checkbox value={'OTHER'}>Այլ ({data.companiesByType?.other}) </Checkbox>
          </Space>
        </Checkbox.Group>
      </div>
      <ItemContainer>
        <AsnTab width='100%' defaultActiveKey='1' items={items} onChange={onTabChange} />
      </ItemContainer>
    </CompanyContainer>
  );
};

export default CompanyPublic;
