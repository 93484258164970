import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Table } from 'antd/lib';

import { IAnnouncementItem } from '../../../api/admin/types/announcemenst/types';
import { STATUS, tableDateFormat } from '../../../constants';

export const announcementColumns: ColumnsType<IAnnouncementItem> = [
  {
    title: 'Ընկերության անվանում',
    dataIndex: 'companyName',
  },
  {
    title: 'Հայտարարության անվանում',
    dataIndex: 'statementTitle',
  },
  {
    title: 'Կարգավիճակ',
    dataIndex: 'status',
    filters: [
      {
        text: 'Նոր',
        value: STATUS.ACTIVE,
      },
      {
        text: 'Ընթացիկ',
        value: STATUS.INACTIVE,
      },
      {
        text: 'Արգելափակված',
        value: STATUS.BLOCKED,
      },
      {
        text: 'Ավարտված',
        value: STATUS.DONE,
      },
    ],
    render: (status: string): React.ReactNode => (
      <div>
        {status === STATUS.ACTIVE
          ? 'Նոր'
          : status === STATUS.INACTIVE
          ? 'Ընթացիկ'
          : status === STATUS.BLOCKED
          ? 'Արգելափակված'
          : 'Ավարտված'}
      </div>
    ),
  },
  Table.EXPAND_COLUMN,
  {
    title: 'Ստեղծման ամսաթիվ',
    dataIndex: 'createdAt',
    sorter: true,
    render: (createdAt: string): React.ReactNode => <div>{dayjs(createdAt).format(tableDateFormat)}</div>,
  },
];
