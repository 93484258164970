import React, { useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Layout, Typography, Row, Space, Button, Popover, Modal, Tooltip } from 'antd';
import styled from 'styled-components';

import { IMainLayout } from '../../../types/components';
import { COLORS, PATHS, menuItemsCompany, adminItems, menuItems, AUTH_KEYS } from '../../../constants';
import AsnMenu from '../../Menu';
import AsnButton from '../../Button';
import { useAuth } from '../../../hooks/useAuth';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { ReactComponent as EmailSvg } from '../../../assets/icons/email.svg';
import { ReactComponent as EmailSvgWhite } from '../../../assets/icons/emailWhite.svg';
import { ReactComponent as SecuritySvg } from '../../../assets/icons/security.svg';
import { ReactComponent as SecuritySvgWhite } from '../../../assets/icons/securityWhite.svg';
import { ReactComponent as PrivacySvg } from '../../../assets/icons/privacy.svg';
import { ReactComponent as PrivacySvgWhite } from '../../../assets/icons/privacyWhite.svg';
import { ReactComponent as CopyRightSvg } from '../../../assets/icons/copyright.svg';
import { ReactComponent as CopyWhiteSvg } from '../../../assets/icons/copyWhite.svg';
import { ReactComponent as UserSvg } from '../../../assets/icons/user.svg';
import { ReactComponent as BurgerSvg } from '../../../assets/icons/burger.svg';
import './media.css';
import AsnLeftMenu from '../../LeftMenu';
import ActionModal from '../../ActionModal';
import { IUserDetails } from '../../../types/auth';

const { Header, Content, Footer, Sider } = Layout;
const { WHITE, CREAM, GRAY, DARK_GRAY, TEXT_COLOR, BLACK } = COLORS;
const { Text, Paragraph, Link } = Typography;

const AsnHeader = styled(Header)`
  &.ant-layout-header {
    line-height: initial;
    background: ${WHITE};
    padding: 15px 4%;
    height: auto;
    border-bottom: 0.5px solid rgba(31, 130, 255, 0.2);
    .ant-menu {
      line-height: 40px;
    }
  }
`;
const width = window.innerWidth;

const MainLayout: React.FC<IMainLayout> = ({ children, logged, homepage, loginHomepage }) => {
  const [filterParams, setFilterParams] = useSearchParams();
  const page = filterParams.get('page');
  const [showPopover, setShowPoppover] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const [showPopoverMobail, setShowPoppoverMobail] = useState<boolean>(false);
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const { pathname } = useLocation();
  const localStorageUser = JSON.parse(localStorage.getItem(AUTH_KEYS.USER) as string) as IUserDetails;
  const role = localStorageUser?.role;

  const handleMenuChange = (info: { key: string }): void => {
    const { key } = info;
    navigate(key);
  };

  const navigateHomepage = (): void => {
    if (!user?.id) {
      navigate(PATHS.LANDING);
    } else {
      navigate(PATHS.HOMEPAGE);
    }
  };

  const selectedKey = pathname?.split('/');

  const handleTopMenuChange = (info: { key: string }): void => {
    const { key } = info;
    navigate(key);
    if (page) {
      filterParams.delete('page');
      setFilterParams(filterParams);
    }
  };

  const handleLogout = (): void => {
    logout();
    navigate(PATHS.LANDING);
  };

  return (
    <>
      <Layout style={{ overflowY: 'auto', minHeight: '100%' }}>
        <AsnHeader>
          <Space direction='horizontal' style={{ width: '100%', justifyContent: 'space-between' }}>
            <Space wrap>
              <div onClick={navigateHomepage} style={{ cursor: 'pointer' }}>
                <Logo style={{ width: width <= 667 ? 67 : 'auto', height: width <= 667 ? 24 : 'auto' }} />
              </div>
              {width > 667 && (
                <Paragraph style={{ fontWeight: 600, paddingTop: 7, marginBottom: 0, textAlign: 'center' }}>
                  ՄԻԱՍԻՆ ՈՒԺԵՂ ԵՆՔ ԵՎ ԿԱՐՈՂ
                </Paragraph>
              )}
            </Space>
            <Space>
              {(!logged || homepage) && width > 667 && (
                <AsnMenu
                  expandIcon={false}
                  mode='horizontal'
                  items={menuItems}
                  style={{ width: '50vw' }}
                  onSelect={(info): void => handleTopMenuChange(info)}
                  selectedKeys={['/' + selectedKey[1]]}
                />
              )}
              {(!logged || homepage) && width <= 667 && (
                <Popover
                  content={
                    <Space direction='vertical' onClick={(): void => setShowPoppoverMobail(false)}>
                      <Paragraph
                        style={{ cursor: 'pointer', marginBottom: 0 }}
                        onClick={(): void => navigate(PATHS.COURSE)}
                      >
                        ՈՒՍՈՒՑՈՒՄ
                      </Paragraph>
                      <Paragraph
                        style={{ cursor: 'pointer', marginBottom: 0 }}
                        onClick={(): void => navigate(PATHS.WORK)}
                      >
                        ԱՇԽԱՏԱՆՔ
                      </Paragraph>
                      <Paragraph
                        style={{ cursor: 'pointer', marginBottom: 0 }}
                        onClick={(): void => navigate(PATHS.OTHER)}
                      >
                        ԱՅԼ
                      </Paragraph>
                      <Paragraph
                        style={{ cursor: 'pointer', marginBottom: 0 }}
                        onClick={(): void => navigate(PATHS.COMPANY)}
                      >
                        ԸՆԿԵՐՈՒԹՅՈՒՆՆԵՐ
                      </Paragraph>
                    </Space>
                  }
                  trigger='click'
                  arrow={false}
                  open={showPopoverMobail}
                  onOpenChange={(open: boolean): void => setShowPoppoverMobail(open)}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <BurgerSvg />
                  </div>
                </Popover>
              )}
              {logged && <Text>{user?.name ?? `${user?.firstName} ${user?.lastName}`}</Text>}
              {(logged || loginHomepage) && (
                <Popover
                  content={
                    <Space direction='vertical' onClick={(): void => setShowPoppover(false)}>
                      {pathname !== '/company/myinfo' && (
                        <Paragraph
                          style={{ cursor: 'pointer', marginBottom: 0 }}
                          onClick={(): void =>
                            role === 'COMPANY'
                              ? navigate(PATHS.COMPANY_MYINFO)
                              : navigate(PATHS.ADMIN_MYINFO.replace(':id', localStorageUser.id))
                          }
                        >
                          Իմ էջ
                        </Paragraph>
                      )}
                      <Paragraph
                        style={{ cursor: 'pointer', marginBottom: 0 }}
                        onClick={(): void => setLogoutModal(true)}
                      >
                        Դուրս գալ
                      </Paragraph>
                      {role === 'COMPANY' && (
                        <Paragraph
                          style={{ cursor: 'pointer', marginBottom: 0 }}
                          onClick={(): void => navigate(PATHS.CHANGE_PASSWORD)}
                        >
                          Գաղտնաբառ
                        </Paragraph>
                      )}
                    </Space>
                  }
                  trigger='click'
                  arrow={false}
                  open={showPopover}
                  onOpenChange={(open: boolean): void => setShowPoppover(open)}
                >
                  <Row>
                    <UserSvg style={{ cursor: 'pointer' }} />
                  </Row>
                </Popover>
              )}
            </Space>
          </Space>
        </AsnHeader>
        <Layout hasSider={logged}>
          {logged && (
            <Sider width={'270px'} breakpoint='lg'>
              <AsnLeftMenu
                selectedKeys={['/' + selectedKey[1] + '/' + selectedKey[2]]}
                items={user?.role === 'COMPANY' ? menuItemsCompany : adminItems}
                onSelect={(info): void => handleMenuChange(info)}
              />
            </Sider>
          )}
          <Content>{children}</Content>
        </Layout>
        <Footer
          style={{
            paddingInline: '4%',
            background: width <= 667 ? `${DARK_GRAY}` : `${CREAM}`,
            borderTop: `0.5px solid ${GRAY}`,
          }}
        >
          <Space
            direction='horizontal'
            style={{ width: '100%', alignItems: 'flex-start' }}
            size={32}
            className='flex-wrap-footer'
          >
            <Logo style={{ width: width <= 667 ? 67 : 'auto', height: width <= 667 ? 24 : 'auto' }} />
            {!logged && !loginHomepage && width <= 667 && (
              <Space direction='horizontal' className='flex-wrap'>
                <AsnButton
                  type='default'
                  onClick={(): void => navigate(PATHS.SIGNIN)}
                  style={{ borderColor: 'transparent' }}
                >
                  Մուտք
                </AsnButton>
                <Button type='primary' onClick={(): void => navigate(PATHS.SIGNUP)}>
                  Գրանցել ընկերություն
                </Button>
              </Space>
            )}
            <Paragraph style={{ fontSize: 12, color: width <= 667 ? `${WHITE}` : `${TEXT_COLOR}` }}>
              ԶԻՆՀԱԲ-ը ստեղծվել է ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության, ԶԻՆԱՊԱՀ - Զինծառայողների
              ապահովագրության ազգային հիմնադրամի, «Ձեռնարկությունների ինկուբատոր» հիմնադրամի (ՁԻՀ) և ՁԻՀ կողմից
              իրականացվող «Հայաստանում աշխատուժի զարգացման» ծրագրի համատեղ նախաձեռնությամբ։ Սա համագործակցության հարթակ
              է մի շարք հասարակական կազմակերպությունների, հիմնադրամների, ինչպես նաև մասնավոր գործընկերների հետ։
            </Paragraph>
            <Space direction='vertical' size={4}>
              <Text
                className='wrap'
                underline
                style={{ fontSize: 12, cursor: 'pointer', color: width <= 667 ? `${WHITE}` : `${TEXT_COLOR}` }}
                onClick={(): Window | null => window.open('https://www.mlsa.am/', '_blank')}
              >
                ՀՀ Աշխատանքի և սոցիալական հարցերի նախարարություն
              </Text>
              <Text
                underline
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  color: width <= 667 ? `${WHITE}` : `${TEXT_COLOR}`,
                }}
                onClick={(): Window | null => window.open(' https://www.zinapah.am/hy/', '_blank')}
              >
                Զինապահ
              </Text>
              <Text
                underline
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  color: width <= 667 ? `${WHITE}` : `${TEXT_COLOR}`,
                }}
                onClick={(): Window | null => window.open('https://eif.am/', '_blank')}
              >
                Ձեռնարկությունների ինկուբատոր հիմնադրամ
              </Text>
              <Text
                underline
                style={{
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                  color: width <= 667 ? `${WHITE}` : `${TEXT_COLOR}`,
                }}
                onClick={(): Window | null => window.open('  https://www.usaid.gov/armenia', '_blank')}
              >
                USAID
              </Text>
              <Text className='wrap' style={{ fontSize: 12, color: width <= 667 ? `${WHITE}` : `${TEXT_COLOR}` }}>
                Հայաստանում աշխատուժի զարգացման ծրագիր
              </Text>
            </Space>
            <Space direction='vertical' size={16}>
              {!logged && !loginHomepage && width > 667 && (
                <Space direction='horizontal' className='flex-wrap'>
                  <AsnButton type='default' onClick={(): void => navigate(PATHS.SIGNIN)}>
                    Մուտք
                  </AsnButton>
                  <Button type='primary' onClick={(): void => navigate(PATHS.SIGNUP)}>
                    Գրանցել ընկերություն
                  </Button>
                </Space>
              )}
              <Space direction='horizontal'>
                <Tooltip
                  placement='topLeft'
                  title={'Կապ'}
                  arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: WHITE,
                    color: BLACK,
                  }}
                >
                  <Link href={`mailto: info@zinhub.am`}>{width <= 667 ? <EmailSvgWhite /> : <EmailSvg />}</Link>
                </Tooltip>
                <Tooltip
                  placement='topLeft'
                  title={'Գաղտնիության քաղաքականություն'}
                  arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: WHITE,
                    color: BLACK,
                  }}
                >
                  <Link
                    download='https://apizinhub.analysed.ai/files/non-disclosure-policy.pdf'
                    target='_blank'
                    rel='noreferrer'
                    href='https://apizinhub.analysed.ai/files/non-disclosure-policy.pdf'
                  >
                    {width <= 667 ? <SecuritySvgWhite /> : <SecuritySvg />}
                  </Link>
                </Tooltip>
                <Tooltip
                  placement='topLeft'
                  title={'Հաճախ տրվող հարցեր'}
                  arrow={false}
                  overlayInnerStyle={{
                    backgroundColor: WHITE,
                    color: BLACK,
                  }}
                >
                  <Text style={{ cursor: 'pointer' }} onClick={(): void => navigate(PATHS.HELP)}>
                    {width <= 667 ? <PrivacySvgWhite /> : <PrivacySvg />}
                  </Text>
                </Tooltip>
              </Space>
              <Space direction='horizontal'>
                {width <= 667 ? <CopyWhiteSvg /> : <CopyRightSvg />}
                <Text
                  underline
                  style={{
                    fontSize: 12,
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    color: width <= 667 ? `${WHITE}` : `${TEXT_COLOR}`,
                  }}
                  onClick={(): Window | null => window.open(' https://analysed.ai/', '_blank')}
                >
                  Analysed LLC 2023
                </Text>
              </Space>
            </Space>
          </Space>
        </Footer>
      </Layout>
      {logoutModal && (
        <Modal centered open={logoutModal} onCancel={(): void => setLogoutModal(false)} footer={false}>
          <ActionModal
            text={`Վստա՞հ եք, որ ցանկանում եք դուրս գալ համակարգից`}
            resolveFn={(): void => handleLogout()}
            rejectFn={(): void => setLogoutModal(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default MainLayout;
