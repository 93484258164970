import React from 'react';
import { useParams } from 'react-router-dom';

import LecturersForm from '../../../../components/LecturersForm';

const EditLecturers: React.FC = () => {
  const { id } = useParams();

  return <LecturersForm edit={id} />;
};

export default EditLecturers;
