import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { ISuccesMessage } from '../../types/global';
import { TFinishStatement, TFinishStatementVariables } from '../../types/api/company';

const url = '/api/statements/:id/finish';

const useFinishStatement: TFinishStatement = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TFinishStatementVariables, unknown>({
    mutationFn: (params) => {
      return client.patch(url.replace(':id', params.id), params.values);
    },
  });

  return mutation;
};

export default useFinishStatement;
