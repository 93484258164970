import { Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import AsnButton from '../Button';
import error from '../../assets/icons/error403.png';

const { Title } = Typography;
export const ImageContainer = styled.img`
  width: 30vw;
  height: calc(100vw - 75vw);
  margin-bottom: 2%;
`;

const Error403: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImageContainer src={error}></ImageContainer>
      <Title level={1}>Մուտքն արգելված է</Title>
      <AsnButton
        type='primary'
        onClick={(): void => navigate('/')}
        style={{
          marginTop: '2rem',
        }}
      >
        Վերադառնալ գլխավոր էջ
      </AsnButton>
    </div>
  );
};

export default Error403;
