import { useSwiper } from 'swiper/react';
import { ArrowRightOutlined } from '@ant-design/icons';

import { CircleButton } from '../../../components';

const SwiperNextStory: React.FC<{ handleNext: () => void }> = ({ handleNext }) => {
  const swiper = useSwiper();
  const handleClick = (): void => {
    handleNext();
    swiper?.slideNext();
  };
  return <CircleButton type='primary' shape='circle' icon={<ArrowRightOutlined />} onClick={handleClick} />;
};

export default SwiperNextStory;
