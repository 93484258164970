import { useSwiper } from 'swiper/react';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { CircleButton } from '../../../components';

const SwiperPrevButton: React.FC<{ handleBack: () => void }> = ({ handleBack }) => {
  const swiper = useSwiper();

  const handleClick = (): void => {
    handleBack();
    swiper?.slidePrev();
  };
  return <CircleButton type='primary' shape='circle' onClick={handleClick} icon={<ArrowLeftOutlined />} />;
};

export default SwiperPrevButton;
