import React from 'react';
import { Row, Space, Typography } from 'antd';

import { ReactComponent as SocapSvg } from '../../../assets/icons/socap.svg';
import { ReactComponent as ZinapahSvg } from '../../../assets/icons/zinapah.svg';
import { ReactComponent as EifSvg } from '../../../assets/icons/eif.svg';
import { ReactComponent as UsaidSvg } from '../../../assets/icons/usaid.svg';
import { ReactComponent as WorkForceSvg } from '../../../assets/icons/workforce.svg';

const { Link } = Typography;

const CoWorkers: React.FC<{ width: number }> = ({ width }) => {
  return (
    <div style={{ padding: '32px 4% 10px 4%' }}>
      <Row
        style={{ background: 'rgba(206, 136, 17, 0.02)', width: '100%', padding: '16px 0px', marginBottom: 24 }}
        align={'middle'}
      >
        <Space
          direction='horizontal'
          style={{ width: '100%', justifyContent: width <= 667 ? 'center' : 'space-between' }}
          align='center'
          wrap
          size={16}
        >
          <Link href='https://www.mlsa.am/' target='_blank'>
            <SocapSvg style={{ width: '100%' }} />
          </Link>
          <Link href='https://www.zinapah.am/hy/' target='_blank'>
            <ZinapahSvg style={{ width: '100%' }} />
          </Link>

          <Link href='https://eif.am/' target='_blank'>
            <EifSvg style={{ width: '100%' }} />
          </Link>
          <Link href='https://www.usaid.gov/armenia' target='_blank'>
            <UsaidSvg style={{ width: '100%' }} />
          </Link>
          <WorkForceSvg style={{ width: '100%' }} />
        </Space>
      </Row>
    </div>
  );
};

export default CoWorkers;
