import React from 'react';
import { useParams } from 'react-router-dom';

import StoriesForm from '../../../../components/Admin/Stories/StoriesForm';

const EditStories: React.FC = () => {
  const { id } = useParams();
  return <StoriesForm edit={id} />;
};

export default EditStories;
