import { Form } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { TEXT_COLOR } = COLORS;

const AsnForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 32px;
    .ant-form-item-label > label {
      color: ${TEXT_COLOR};
    }
  }
  .ant-form-item-required:before {
    position: absolute;
    right: 2px;
    color: ${TEXT_COLOR}!important;
    margin-top: -4px;
  }

  .ant-form-item-label {
    padding: 0 0 4px;
    > label {
      white-space: nowrap;
    }
  }
`;

export default AsnForm;
