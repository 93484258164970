import styled from 'styled-components';

import { COLORS } from '../../constants';

const { GRAY } = COLORS;

export const FormSpace = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;
  gap: 8px;
  .ant-form-item {
    width: 100%;
    margin: 0px;
  }
  svg {
    cursor: pointer;
  }
`;
export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  width: 100%;
`;
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;
export const FormContent = styled.div`
  input {
    border: 1px solid ${GRAY};
  }
  textarea {
    border: 1px solid ${GRAY};
  }

  .ant-upload {
    width: 160px;
    height: 160px;
    border: 1px solid ${GRAY};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
`;
