import React from 'react';
import { Row, Spin } from 'antd';

const Spinner: React.FC = () => {
  return (
    <Row justify={'center'} align={'middle'} style={{ height: '65vh' }}>
      <Spin />
    </Row>
  );
};

export default Spinner;
