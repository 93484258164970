import React from 'react';
import { Typography, Row, Col } from 'antd';

import { PrincipalCard } from '../../../components';
import { ReactComponent as PrincipalSvg1 } from '../../../assets/icons/principal1.svg';
import { ReactComponent as PrincipalSvg2 } from '../../../assets/icons/principal2.svg';
import { ReactComponent as PrincipalSvg3 } from '../../../assets/icons/principal3.svg';
import { ReactComponent as PrincipalSvg4 } from '../../../assets/icons/principal4.svg';

const { Title } = Typography;

const Principals: React.FC = () => {
  return (
    <Row style={{ width: '100%', padding: '32px 4% 10px 4%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Title level={5} style={{ marginBottom: 24 }}>
          ՄԵՐ ՍԿԶԲՈՒՆՔՆԵՐԸ
        </Title>
      </div>
      <Row gutter={[32, 32]}>
        <Col
          span={6}
          xxl={{ span: 6 }}
          xl={{ span: 8 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <PrincipalCard
            svg={<PrincipalSvg1 style={{ width: 40, height: 40 }} />}
            title={'ՄԻԱՍՆՈՒԹՅՈՒՆ'}
            description='Հաջողության բանաձևը միասնականության մեջ է, երբ պետական մարմինները քաղ․ հասարակությունն ու մասնավոր ընկերությունները միավորում են իրենց առաքելությունն ու ներուժը։'
          />
        </Col>
        <Col
          span={6}
          xxl={{ span: 6 }}
          xl={{ span: 8 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <PrincipalCard
            svg={<PrincipalSvg2 style={{ width: 40, height: 40 }} />}
            title={'ՄԱՐԴԱԿԵՆՏՐՈՆՈՒԹՑՈՒՆ'}
            description='Առանցքում մարդն է, որպես անհատ, ով ազատ է ընտրելու իր նախասիրություններին և կարիքներին համապատասխան ծառայություններ՝ ուղղված իր տնտեսական ակտիվացմանը և զարգացմանը։'
          />
        </Col>
        <Col
          span={6}
          xxl={{ span: 6 }}
          xl={{ span: 8 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <PrincipalCard
            svg={<PrincipalSvg3 style={{ width: 40, height: 40 }} />}
            title={'ԱՆԿՈՂՄՆԱԿԱԼՈՒԹՑՈՒՆ'}
            description='Հարթակում առաջարկվող ծառայություններից օգտվելու համար չկա խտրականություն։ Աջակցությունը հիմնված է անձի կարիքների առաջնահերթության վրա՝ ի նպաստ նրա անձնային և մասնագիտական աճի։'
          />
        </Col>
        <Col
          span={6}
          xxl={{ span: 6 }}
          xl={{ span: 8 }}
          lg={{ span: 12 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <PrincipalCard
            svg={<PrincipalSvg4 style={{ width: 40, height: 40 }} />}
            title={'ՀԱՇՎԵՏՎՈՂԱԿԱՆՈՒԹՅՈՒՆ'}
            description='Հարթակում գործող կազմակերպությունների աշխատանքը, առաջարկվող ծրագրերն ու միջոցառումները թափանցիկ են, նպատակային և գնահատելի:'
          />
        </Col>
      </Row>
    </Row>
  );
};

export default Principals;
