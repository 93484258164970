import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';

import { PATHS } from '../../../constants';
import useCheckToken from '../../../api/auth/useCheckToken';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { AsnButton } from '../../../components';
import useResendEmail from '../../../api/auth/useReasendEmail';
import useVerifyEmail from '../../../api/auth/useConfirmEmail';

const { Text } = Typography;

const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;
  const [email, setEmail] = useState<string>('');

  const { mutate: checkToken } = useCheckToken();
  const { mutate: verifyEmail } = useVerifyEmail();
  const { mutate: resendEmail } = useResendEmail();

  useEffect(() => {
    if (token) {
      checkToken(
        { token: token },
        {
          onSuccess: (value) => {
            if (value.data.valid) {
              verifyEmail(
                { token: token },
                {
                  onSuccess: () => navigate(PATHS.SIGNIN),
                },
              );
            } else {
              setEmail(value.data.email);
            }
          },
        },
      );
    }
  }, [token]);

  return (
    <>
      <Row style={{ padding: '22px 80px' }}>
        <Logo onClick={(): void => navigate(PATHS.LANDING)} style={{ cursor: 'pointer' }} />
      </Row>
      <Row
        style={{
          backgroundImage: 'url(./assets/auth_left.png)',
          backgroundSize: 'contain',
          height: 'calc(100vh - 100px)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Col
          span={20}
          push={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row
            align={'middle'}
            justify={'center'}
            style={{
              height: 'calc(90vh - 100px)',
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <Text>Հղման վավերականության ժամկետը սպառվել է։ Նոր հղում ստանալու համար սեղմեք</Text>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <AsnButton type='primary' onClick={(): void => resendEmail({ email: email })}>
                Կրկին ուղարկել
              </AsnButton>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default VerifyEmail;
