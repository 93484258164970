import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { IGetAllStatements, TUseGetAllStatements } from '../../types/api/company';

const url = '/api/statements/all';

const useGetAllStatements: TUseGetAllStatements = (params, options) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.post(url, { ...params, limit: 10 }),
    ...options,
    select: (data) => data?.data,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as IGetAllStatements),
    isLoading,
    isFetching,
  };
};

export default useGetAllStatements;
