import React from 'react';
import { Space, Typography } from 'antd';

import { COLORS } from '../../../constants';
import { ISuccessStory } from '../../../types/landing';

const { Title, Link, Paragraph } = Typography;
const { ORANGE_DARK, TEXT_COLOR } = COLORS;

const SuccessStory: React.FC<ISuccessStory> = ({ title, companyLink, companyName, story, link }) => {
  return (
    <Space
      direction='vertical'
      align='center'
      id='comment'
      style={{ position: 'absolute', width: '60vw' }}
      className='space_top'
    >
      <Title level={4} style={{ textAlign: 'center' }}>
        {title}
      </Title>
      <div style={{ textAlign: 'center', marginBottom: 12 }}>
        <Link href={companyLink} target='_blank' underline style={{ color: `${ORANGE_DARK}` }}>
          {companyName}
        </Link>
      </div>
      <Paragraph style={{ textAlign: 'center' }}>
        {story}{' '}
        {link && (
          <Link href={link} target='_blank' underline style={{ color: `${TEXT_COLOR}` }}>
            Տեսնել ավելին
          </Link>
        )}
      </Paragraph>
    </Space>
  );
};
export default SuccessStory;
