import styled from 'styled-components';
export const CardContainer = styled.div`
  border: 0.5px solid rgba(31, 130, 255, 0.2);
  display: flex;
  padding: 0.5rem 1rem 0.75rem 1rem;
  flex-direction: column;
  justify-content: center;
  min-width: 23%;
  .ant-typography {
    margin-bottom: 0;
  }
  .ant-typography + h1.ant-typography {
    margin-top: 0;
  }
`;
