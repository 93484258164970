import axios from 'axios';

import { AUTH_KEYS, PATHS } from '../constants';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_KEYS.TOKEN) || '';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
client.interceptors.response.use(
  async function (response) {
    return await Promise.resolve(response);
  },
  async function (error: { response: { status: number; data: { message: string } } }) {
    if (error?.response?.status === 401) {
      localStorage.removeItem(AUTH_KEYS.USER);
      localStorage.removeItem(AUTH_KEYS.TOKEN);
      window.location.href = PATHS.SIGNIN;
    }
    return Promise.reject(error?.response?.data?.message);
  },
);

export default client;
