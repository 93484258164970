import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Row, Image, PaginationProps } from 'antd';
import { SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, FreeMode } from 'swiper';

import { COLORS, PATHS } from '../../../constants';
import { AsnSwiper, CourseCard, SwiperPagination } from '../../../components';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ILandingCourses } from '../../../types/landing';
import { TGetTrainings } from '../../../types/api/public';

import SwiperButtonPrevCourse from './SwiperButtonPrevCourse';
import SwiperButtonNextCourse from './SwiperButtonNextCourse';

const { Title, Paragraph } = Typography;

// import styled from 'styled-components';
// const TextButton = styled(Button)`
//   span {
//     text-decoration: underline;
//   }
// `;

const { AZURE } = COLORS;

const Courses: React.FC<ILandingCourses> = ({ courses, count, width }) => {
  const navigate = useNavigate();

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <SwiperButtonPrevCourse />;
    }
    if (type === 'next') {
      return <SwiperButtonNextCourse />;
    }

    return originalElement;
  };

  const handleCardClick = (res: TGetTrainings): void => {
    switch (res?.statementType) {
      case 'TRINING':
        navigate(`${PATHS.COURSE_VIEW_PUBLIC}?id=${res?.id}`);
        break;
      case 'WORK':
        navigate(`${PATHS.WORK_VIEW_PUBLIC}/?id=${res?.id}`);
        break;
      case 'OTHER':
        navigate(`${PATHS.OTHER_VIEW_PUBLIC}/?id=${res?.id}`);
        break;
    }
  };

  return (
    <Row style={{ padding: '32px 4% 10px 4%', position: 'relative' }}>
      <Title level={5} style={{ marginBottom: 24 }}>
        Հայտարարություններ
      </Title>
      <AsnSwiper
        spaceBetween={width <= 667 ? 20 : 40}
        pagination={{
          clickable: true,
          type: 'custom',
        }}
        modules={[Pagination, Navigation, FreeMode]}
        style={{ paddingBottom: 40, width: '100%' }}
        className={courses?.length < count ? 'flex' : ''}
        breakpoints={{
          300: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          530: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          830: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          1120: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          1300: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
        }}
        allowTouchMove={width <= 667 ? true : false}
        freeMode={width <= 667 ? true : false}
        // freeMode={{
        //   enabled: true,
        //   // sticky: true,
        //   // momentumBounce: false,
        //   // momentumVelocityRatio: 0.5,
        // }}
      >
        {courses?.map((res) => (
          <SwiperSlide key={res?.id} style={{ maxWidth: width <= 667 ? 280 : 'auto' }}>
            <CourseCard
              hoverable
              cover={<Image src={res?.companyPhoto} height={140} style={{ objectFit: 'contain' }} preview={false} />}
              onClick={(): void => handleCardClick(res as TGetTrainings)}
            >
              <Paragraph
                style={{ fontSize: 16, marginBottom: 8, minHeight: 51 }}
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
              >
                {res?.companyName}
              </Paragraph>
              <Paragraph
                style={{ fontSize: 14, color: `${AZURE}`, marginBottom: 0, minHeight: 44 }}
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
              >
                {res?.trainingTitle}
              </Paragraph>
            </CourseCard>
          </SwiperSlide>
        ))}
        {courses?.length > count && width > 667 && (
          <Row justify={'center'} style={{ width: '100%' }}>
            <SwiperPagination
              total={courses?.length}
              showSizeChanger={false}
              itemRender={itemRender}
              defaultPageSize={count}
            />
          </Row>
        )}
      </AsnSwiper>
      {/* <Row justify={width <= 667 ? 'center' : 'end'} style={{ width: '100%' }}>
        <TextButton type='text' onClick={(): void => navigate(PATHS.COURSE)}>
          Տեսնել բոլորը
        </TextButton>
      </Row> */}
    </Row>
  );
};

export default Courses;
