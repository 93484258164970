import { Tabs } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { AZURE, BLACK } = COLORS;

interface IStyle {
  width: string;
}

const AsnTab = styled(Tabs)<IStyle>`
  width: ${(props): string => props.width};
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: ${AZURE} !important;
    }
  }
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: ${BLACK};
      font-size: 16px;
    }
  }
`;

export default AsnTab;
