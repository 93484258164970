import React from 'react';
import { TabsProps } from 'antd';

import AsnTab from '../../../components/Tab';
import AllAnnouncements from '../../../components/Admin/Announcements/AllAnnouncements';
import Training from '../../../components/Admin/Announcements/Training';
import Work from '../../../components/Admin/Announcements/Work';
import Other from '../../../components/Admin/Announcements/Other';

import { AnnouncementsContainer } from './styled';

const Announcements: React.FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Բոլորը',
      children: <AllAnnouncements />,
    },
    {
      key: '2',
      label: 'Ուսուցում',
      children: <Training />,
    },
    {
      key: '3',
      label: 'Աշխատանք',
      children: <Work />,
    },
    {
      key: '4',
      label: 'Այլ',
      children: <Other />,
    },
  ];

  return (
    <AnnouncementsContainer>
      <AsnTab width='100%' defaultActiveKey='1' items={items} />
    </AnnouncementsContainer>
  );
};

export default Announcements;
