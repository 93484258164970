import { useQuery } from '@tanstack/react-query';

import client from '../client';
import { IGetLandingPageData, TUseGetLandingPageData } from '../../types/api/public';

const url = '/api/statements/public/home-page';

const useGetLandingPageData: TUseGetLandingPageData = (options) => {
  const result = useQuery({
    queryKey: [url],
    queryFn: () => client.get(url),
    ...options,
    select: (data) => data?.data,
  });

  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as IGetLandingPageData),
    isLoading,
    isFetching,
  };
};

export default useGetLandingPageData;
