import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { IDataLecturers } from '../../../api/admin/types/lecturers.tsx/types';
import { PATHS } from '../../../constants';

export const columnsLecturers = (
  setDeleteLecturersId: React.Dispatch<React.SetStateAction<{ id: string; name: string }>>,
): ColumnsType<IDataLecturers> => {
  const navigate = useNavigate();
  return [
    {
      title: 'Անուն Ազգանուն',
      render: (row: IDataLecturers): React.ReactNode => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <img
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
            }}
            src={row.photo}
          />
          {row.fullName}
        </div>
      ),
    },
    {
      title: 'Մասնագիտություն',
      dataIndex: 'profession',
    },
    {
      title: 'Գործողություն',
      key: 'operation',
      render: (row: IDataLecturers) => (
        <div>
          <button
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            onClick={(): void => {
              navigate(PATHS.COMPANY_LECTURERS_EDIT.replace(':id', row.id));
            }}
          >
            <EditIcon />
          </button>
          <button
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            onClick={(): void => setDeleteLecturersId({ id: row.id, name: row.fullName })}
          >
            <DeleteIcon />
          </button>
        </div>
      ),
    },
  ];
};
