import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { useGetStatementById } from '../../../../api';
import { OtherStatementView } from '../../../../components';

const AdminOtherView: React.FC = () => {
  const { id } = useParams();
  const { data: statement, isFetching } = useGetStatementById(id as string, { enabled: Boolean(id) });
  return (
    <>
      {!isFetching ? (
        <OtherStatementView other={statement} />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin tip='Loading' size='large' />
        </div>
      )}
    </>
  );
};

export default AdminOtherView;
