import React from 'react';
import { Form, Input, Space, Typography } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../../../constants';
import AsnButton from '../../../Button';
interface IActionModal {
  rejectFn: () => void;
  disabledButton: boolean;
  title: string;
}

const RejectionContainer = styled.div`
  padding: 40px 3.5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  .ant-form-item {
    width: 100%;
  }
`;

const { Text } = Typography;
const { TextArea } = Input;

const RejectionReasonContent: React.FC<IActionModal> = ({ rejectFn, disabledButton, title }) => {
  return (
    <RejectionContainer>
      <Text
        style={{
          display: 'flex',
          textAlign: 'center',
          color: COLORS.TEXT_COLOR,
          fontWeight: '500',
          fontSize: '16px',
        }}
      >
        {title}
      </Text>
      <Form.Item
        name='rejectionText'
        label='Նշել պատճառը'
        rules={[
          {
            required: true,
            message: 'Նշել արգելափակման պատճառը դաշտը պարտադիր է',
          },
          { min: 3, message: 'Նշել արգելափակման պատճառը դաշտը պետք է ունենա առնվազն 3 նիշ' },
          { max: 100, message: 'Նշել արգելափակման պատճառը դաշտը պետք է ունենա առավելագույնը 100 նիշ' },
        ]}
      >
        <TextArea />
      </Form.Item>
      <Space
        style={{
          display: 'flex',
          gap: '40px',
          justifyContent: 'center',
        }}
      >
        <AsnButton onClick={rejectFn} disabled={disabledButton}>
          Չեղարկել
        </AsnButton>
        <AsnButton type='primary' htmlType='submit' disabled={disabledButton}>
          Մերժել
        </AsnButton>
      </Space>
    </RejectionContainer>
  );
};

export default RejectionReasonContent;
