import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/charts';

import { IStatementsByStatuses } from '../../../api/admin/types/statistics';

const AnnouncementsByStatus: React.FC<{ statementsByStatuses: IStatementsByStatuses[] }> = ({
  statementsByStatuses,
}) => {
  const [data, setData] = useState<IStatementsByStatuses[]>([]);

  useEffect(() => {
    setData(statementsByStatuses);
  }, [statementsByStatuses]);

  const config = {
    data,
    xField: 'status',
    yField: 'count',
    seriesField: 'type',
    isGroup: true,
    color: ['#4472C4', '#FF882F', '#A5A5A5'],
    dodgePadding: 0.9,
    maxColumnWidth: 40,
    columnStyle: {
      radius: [2, 2, 0, 0],
    },
  };
  return <Column {...config} />;
};

export default AnnouncementsByStatus;
