import React from 'react';
import { Row, Col, Image, Typography, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import { COLORS, PATHS } from '../../constants';
import { ReactComponent as LocationSvg } from '../../assets/icons/locationOrange.svg';
import { TGetAllStatementsPublic } from '../../types/api/public';

import { AsnRow, Content, HideContainer, TextContent } from './styled';

const { Paragraph, Text } = Typography;
const { AZURE } = COLORS;
export interface IMainCard {
  info: TGetAllStatementsPublic;
}

const PublicCard: React.FC<IMainCard> = ({ info }) => {
  const navigate = useNavigate();

  const handleClickStatement = (id: string, type: string): void => {
    if (type === 'TRINING') {
      navigate(`${PATHS.COURSE_VIEW_PUBLIC}/?id=${id}`);
    }
    if (type === 'WORK') {
      navigate(`${PATHS.WORK_VIEW_PUBLIC}/?id=${id}`);
    }
    if (type === 'OTHER') {
      navigate(`${PATHS.OTHER_VIEW_PUBLIC}/?id=${id}`);
    }
  };

  return (
    <>
      <AsnRow>
        <Col
          md={{ span: 6 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          onClick={(): void => handleClickStatement(info?.id, info?.type)}
        >
          <Image
            preview={false}
            src={info?.companyPhoto}
            style={{ borderRadius: 4, objectFit: 'contain', cursor: 'pointer' }}
            height={'140px'}
            width={'100%'}
          />
        </Col>
        <Content md={{ span: 14 }} xs={{ span: 24 }} sm={{ span: 24 }}>
          <Paragraph style={{ fontSize: 14, fontWeight: 600, marginBottom: 0 }} ellipsis={{ rows: 2 }}>
            {info?.statementTitle}
          </Paragraph>
          <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Paragraph
              style={{ marginBottom: 0, color: AZURE, cursor: 'pointer' }}
              ellipsis={{ rows: 2 }}
              onClick={(): void =>
                navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${info?.companyId}`, {
                  state: {
                    isListType: info?.type,
                  },
                })
              }
            >
              {info.companyName}
            </Paragraph>
          </Space>
          <TextContent>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: '4px',
              }}
            >
              <div
                style={{
                  height: '17px',
                }}
              >
                <LocationSvg />
              </div>
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                {info?.statementLocation}
              </Paragraph>
            </Row>
            <Row>
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                {info?.statementFiled}
              </Paragraph>
            </Row>
          </TextContent>
        </Content>
        <HideContainer md={{ span: 4 }} xs={{ span: 24 }} sm={{ span: 24 }}>
          <Text
            underline
            style={{
              color: `${AZURE}`,
              fontWeight: 600,
              position: 'absolute',
              bottom: 20,
              right: 9,
              cursor: 'pointer',
            }}
            className='more'
            onClick={(): void => handleClickStatement(info?.id, info?.type)}
          >
            Տեսնել ավելին
          </Text>
        </HideContainer>
      </AsnRow>
    </>
  );
};
export default PublicCard;
