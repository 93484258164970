import styled from 'styled-components';

import { COLORS } from '../../../constants';

const { GRAY } = COLORS;

export const FormContentOther = styled.div`
  .ant-upload {
    width: 20vw;
    height: 228px;
    border: 1px solid ${GRAY};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`;
