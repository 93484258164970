import { Checkbox, Space } from 'antd';

import { AsnForm } from '../../../components';
import { TStaticValues } from '../../../types/api/static';
import { statuses } from '../../../constants';

interface IFilterItems {
  value: string;
  label: string;
  children: React.ReactNode;
}

export const filterItems = (staticData: TStaticValues, type: string): IFilterItems[] => {
  const items = [
    {
      value: '1',
      label: 'Ոլորտ',
      children: (
        <AsnForm.Item name='filedStudyIds' initialValue={[]} style={{ marginBottom: 0 }}>
          <Checkbox.Group>
            <Space direction='vertical' style={{ maxHeight: 150, overflow: 'auto' }}>
              {type === 'WORK'
                ? staticData?.filedOfWork?.map((field) => (
                    <Checkbox key={field.value} value={field?.value}>
                      {field?.label}
                    </Checkbox>
                  ))
                : staticData?.filedOfStudy?.map((field) => (
                    <Checkbox key={field.value} value={field?.value}>
                      {field?.label}
                    </Checkbox>
                  ))}
            </Space>
          </Checkbox.Group>
        </AsnForm.Item>
      ),
    },
    {
      value: '2',
      label: type === 'WORK' ? 'Զբաղվածություն' : 'Ձևաչափ',
      children: (
        <AsnForm.Item
          name={type === 'WORK' ? 'employmentIds' : 'formatIds'}
          initialValue={[]}
          style={{ marginBottom: 0 }}
        >
          <Checkbox.Group>
            <Space direction='vertical' style={{ maxHeight: 150, overflow: 'auto' }}>
              {type === 'WORK'
                ? staticData?.employment?.map((field) => (
                    <Checkbox key={field.value} value={field?.value}>
                      {field?.label}
                    </Checkbox>
                  ))
                : staticData?.courseFormat?.map((field) => (
                    <Checkbox key={field.value} value={field?.value}>
                      {field?.label}
                    </Checkbox>
                  ))}
            </Space>
          </Checkbox.Group>
        </AsnForm.Item>
      ),
    },
    {
      value: '3',
      label: 'Մակարդակ',
      children: (
        <AsnForm.Item name='levelIds' initialValue={[]} style={{ marginBottom: 0 }}>
          <Checkbox.Group>
            <Space direction='vertical' style={{ maxHeight: 150, overflow: 'auto' }}>
              {type === 'WORK'
                ? staticData?.workLevel?.map((field) => (
                    <Checkbox key={field.value} value={field?.value}>
                      {field?.label}
                    </Checkbox>
                  ))
                : staticData?.courseLevel?.map((field) => (
                    <Checkbox key={field.value} value={field?.value}>
                      {field?.label}
                    </Checkbox>
                  ))}
            </Space>
          </Checkbox.Group>
        </AsnForm.Item>
      ),
    },
    {
      value: '4',
      label: 'Կարգավիճակ',
      children: (
        <AsnForm.Item name='statuses' initialValue={[]} style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }}>
            <Space direction='vertical' style={{ maxHeight: 150, overflow: 'auto', width: '100%' }}>
              {statuses?.map((field) => (
                <Checkbox key={field.value} value={field?.value}>
                  {field?.label}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </AsnForm.Item>
      ),
    },
  ];
  if (type === 'OTHER') {
    return items.splice(3, 1);
  }
  return items;
};
