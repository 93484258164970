import styled from 'styled-components';

import { COLORS } from '../../constants';

const { TEXT_COLOR } = COLORS;

export const AboutCompany = styled.div`
  font-size: 16px;
  color: ${TEXT_COLOR};
  word-break: break-word;
  margin-bottom: 60px;
  line-height: 22px;
`;
