import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { IData, TUseGetSLecturersData } from '../../admin/types/lecturers.tsx/types';

export const url = 'api/teacher/all';

const useGetLecturersList: TUseGetSLecturersData = (params, options) => {
  const result = useQuery({
    queryKey: [url, params],
    queryFn: () => client.post(url, params),
    ...options,
    select: (data) => data?.data,
  });
  const { data, isLoading, isSuccess, isFetching } = result;

  return {
    data: isSuccess ? data : ({} as IData),
    isLoading,
    isFetching,
  };
};
export default useGetLecturersList;
