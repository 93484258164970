import { Form, Input, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { TableProps } from 'antd/lib';
import { useQueryClient } from '@tanstack/react-query';

import { TablesContainer } from '../../../../pages/admin/requests/styled';
import useGetCompany from '../../../../api/admin/users/useGetCompanys';
import { IPaginationRequests, ISortRequests } from '../../../../types/admin/requests';
import { ICompanyListPending } from '../../../../api/admin/types/users/company';
import ActionModal from '../../../ActionModal';
import RejectionReasonContent from '../RejectionReasonContent';
import useApproveRequests from '../../../../api/admin/requests/useApproveRequests';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import useRejectRequests from '../../../../api/admin/requests/useRejectRequests';
import { useAuth } from '../../../../hooks/useAuth';

import { columnsPending } from './columns';

const Pending: React.FC = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [openApprove, setOpenApprove] = useState<string>('');
  const [openReject, setOpenReject] = useState<string>('');
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [tablePagination, setTablePagination] = useState<IPaginationRequests>({
    current: 1,
    pageSize: 10,
  });
  const [sortCompany, setSortCompany] = useState<ISortRequests>({
    column: '',
    direction: '',
  });

  const { data, isLoading } = useGetCompany({
    limit: tablePagination.pageSize,
    offset: (tablePagination.current - 1) * 10,
    statuses: ['PENDING'],
    search: searchCompany === '' ? undefined : searchCompany,
    orderBy: sortCompany.column === '' && sortCompany.direction === '' ? undefined : { ...sortCompany },
  });
  const { mutate: rejectCompany } = useRejectRequests({
    onSuccess: () => {
      onClosRejectModal();
      void queryClient.invalidateQueries(['api/company/all']);
      setDisabledButton(false);
      form.resetFields();
    },
  });

  const onFinish = (value: { rejectionText: string }): void => {
    setDisabledButton(true);
    rejectCompany({
      id: openReject,
      ...value,
    });
  };

  const { mutate: approveCompany } = useApproveRequests({
    onSuccess: () => {
      setOpenApprove('');
      void queryClient.invalidateQueries(['api/company/all']);
    },
  });
  const onSearchCompanyEnter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchCompany((e.target as HTMLInputElement).value.trim());
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const handleTableChange: TableProps<ICompanyListPending>['onChange'] = (pagination, filters, sorter) => {
    if ((sorter as { order: string }).order !== undefined) {
      setSortCompany({
        column: (sorter as { field: string }).field,
        direction: (sorter as { order: string }).order === 'descend' ? 'DESC' : 'ASC',
      });
    } else {
      setSortCompany({
        column: '',
        direction: '',
      });
    }
    setTablePagination({
      current: pagination.current as number,
      total: pagination.total,
      pageSize: 10,
    });
  };

  const onCloseApproveModal = (): void => {
    setOpenApprove('');
  };
  const onApproveModal = (): void => {
    approveCompany({ id: openApprove });
  };

  const onClosRejectModal = (): void => {
    setOpenReject('');
    form.resetFields();
  };

  const permissionColumns =
    user?.permission === 'VIEW' && user.role === 'ADMIN'
      ? columnsPending(setOpenApprove, setOpenReject).filter((item) => item.key !== 'operation')
      : columnsPending(setOpenApprove, setOpenReject);

  return (
    <TablesContainer>
      <Input
        placeholder='Օրինակ'
        prefix={<SearchIcon />}
        style={{ width: '40%' }}
        onPressEnter={onSearchCompanyEnter}
      />
      <Table
        columns={permissionColumns}
        dataSource={data?.result}
        rowKey={(record): string => record?.id}
        loading={isLoading}
        pagination={{
          ...tablePagination,
          position: ['bottomCenter'],
          total: data?.count,
        }}
        onChange={handleTableChange}
      />
      <Modal centered open={openApprove !== ''} onCancel={onCloseApproveModal} footer={false}>
        <ActionModal
          text={`Համոզվա՞ծ եք, որ ցանկանում եք հաստատել դիմում-հայտը`}
          resolveFn={onApproveModal}
          rejectFn={onCloseApproveModal}
        />
      </Modal>
      <Modal centered open={openReject !== ''} onCancel={onClosRejectModal} footer={false} width={'40vw'}>
        <Form
          name='form_items'
          onFinish={onFinish}
          form={form}
          autoComplete='off'
          layout='vertical'
          style={{
            width: '100%',
            margin: '2rem 0 3rem',
          }}
        >
          <RejectionReasonContent
            rejectFn={onClosRejectModal}
            disabledButton={disabledButton}
            title=' Համոզվա՞ծ եք, որ ուզում եք մերժել դիմում հայտը'
          />
        </Form>
      </Modal>
    </TablesContainer>
  );
};

export default Pending;
