import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { PATHS } from '../constants';
import { MainLayout } from '../components';

import ScrollToTop from './ScrollToTop';

export const AdminRoutes: React.FC = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  if (!user) {
    return <Navigate to={PATHS.LANDING} />;
  }

  return (
    <ScrollToTop>
      {(user?.role === 'SUPER_ADMIN' || pathname === '/change-password' || user?.role === 'ADMIN') &&
      pathname !== '/homepage' ? (
        <MainLayout logged={true}>
          <Outlet />
        </MainLayout>
      ) : (
        <Navigate to={PATHS.COMPANY_MYINFO} />
      )}
    </ScrollToTop>
  );
};
