import { Pagination } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../constants';

const { AZURE } = COLORS;

const SwiperPagination = styled(Pagination)`
  &.ant-pagination .ant-pagination-item-active a {
    display: none;
  }
  &.ant-pagination .ant-pagination-item-active {
    background: ${AZURE} !important;
    min-width: 18px !important;
    height: 3px !important;
    border-radius: 1px;
    pointer-events: none;
  }
  &.ant-pagination .ant-pagination-item a {
    display: none;
  }
  &.ant-pagination .ant-pagination-item {
    background: #e7e7e7;
    min-width: 14px;
    height: 3px;
    border-radius: 1px;
    pointer-events: none;
  }
`;
export default SwiperPagination;
