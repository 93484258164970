import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import client from '../client';
import { ISuccesMessage } from '../../types/global';
import { TUseUpdateStatement, TUseUpdateStatementVariables } from '../../types/api/company';

const url = '/api/statements/:id';

const useUpdateStatement: TUseUpdateStatement = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TUseUpdateStatementVariables, unknown>({
    mutationFn: (params) => {
      return client.put(url.replace(':id', params.id), params.values);
    },
  });

  return mutation;
};

export default useUpdateStatement;
