import styled from 'styled-components';

export const ListContainer = styled.div`
  padding: 40px 15vw;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 40px 10vw;
  }
  @media (max-width: 1024px) {
    padding: 40px 5vw;
  }
`;
