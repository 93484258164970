import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Typography, Input, Button } from 'antd';

import { AsnButton, AsnForm } from '../../../components';
import { useChangePassword } from '../../../api';
import { rulesPassword } from '../../../helpers';
import { passwordRules, validate_messages, PATHS, AUTH_KEYS } from '../../../constants';
import { ChangePasswordVariables } from '../../../types/api/auth';
import { IUserDetails } from '../../../types/auth';

const { Title } = Typography;

const ChangePassword: React.FC = () => {
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: changePassword } = useChangePassword();
  const localStorageUser = JSON.parse(localStorage.getItem(AUTH_KEYS.USER) as string) as IUserDetails;
  const role = localStorageUser?.role;

  const onFinish = (values: ChangePasswordVariables): void => {
    changePassword(values);
  };

  return (
    <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
      <AsnForm
        form={form}
        layout='vertical'
        onFinish={(values): void => onFinish(values as ChangePasswordVariables)}
        style={{ maxWidth: '460px', width: '42vw' }}
        validateMessages={validate_messages}
      >
        <Title level={2} style={{ marginBottom: 30 }}>
          Փոխել գաղտնաբառը
        </Title>
        <AsnForm.Item
          name='oldPassword'
          label='Գաղտնաբառ'
          rules={[{ required: true }]}
          normalize={(value: string): string => value.trim()}
        >
          <Input.Password />
        </AsnForm.Item>
        <AsnForm.Item
          name='newPassword'
          label='Նոր գաղտնաբառ'
          rules={[...passwordRules]}
          normalize={(value: string): string => value.trim()}
        >
          <Input.Password />
        </AsnForm.Item>
        <AsnForm.Item
          name='repeatPassword'
          label='Կրկնել գաղտնաբառը'
          rules={rulesPassword('newPassword')}
          dependencies={['newPassword']}
          normalize={(value: string): string => value.trim()}
        >
          <Input.Password />
        </AsnForm.Item>
        <Row justify={'end'} style={{ gap: 16 }}>
          <AsnButton
            type='default'
            onClick={(): void =>
              role === 'COMPANY'
                ? navigate(PATHS.COMPANY_MYINFO)
                : navigate(PATHS.ADMIN_MYINFO.replace(':id', localStorageUser.id))
            }
          >
            Չեղարկել
          </AsnButton>
          <Button type='primary' htmlType='submit'>
            Պահպանել
          </Button>
        </Row>
      </AsnForm>
    </Row>
  );
};

export default ChangePassword;
