import { Collapse, CollapseProps, Typography } from 'antd';
import React from 'react';
import styled, { CSSProperties } from 'styled-components';
const { Title, Link } = Typography;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
  h4 {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  a {
    margin-left: 1rem;
  }
  .ant-collapse {
    background: none;
  }
  .ant-collapse-header-text {
    font-size: 18px;
    color: #0f2b61;
    font-weight: 600;
  }
  .downloadText {
    width: 60vw;
  }

  @media (max-width: 1024px) {
    .downloadText {
      width: 70vw;
    }
  }
  @media (max-width: 667px) {
    .downloadText {
      width: 90vw;
    }
  }
`;

const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
  {
    key: '1',
    label: 'Ի՞նչ նպատակով է ստեղծվել հարթակը',
    children: (
      <p>
        ԶԻՆՀԱԲ հարթակը նպատակ ունի համատեղել բոլորիս ներուժը զինծառայողների և նրանց ընտանիքների վերասոցիալականացմանը,
        մասնավորապես, աջակցելու նրանց կրթության և ուսուցման, աշխատանքի և զբաղվածության, հարակից այլ ծառայություններ
        ստանալու գործում։
      </p>
    ),
    style: panelStyle,
  },
  {
    key: '2',
    label: 'Ու՞մ համար է նախատեսված ZINHUB-ը',
    children: (
      <p>
        ԶԻՆՀԱԲ հարթակում առաջարկվող ծառայություններից կարող են օգտվել ինչպես ԶԻՆԱՊԱՀ-ի շահառուները, այնպես էլ պատերազմի
        մասնակիցները և պարտադիր զինվորական ծառայությունից զորացրված երիտասարդները։
      </p>
    ),
    style: panelStyle,
  },
  {
    key: '3',
    label: 'Ի՞նչ կարող եք գտնել հարթակում ',
    children: (
      <p>
        ԶԻՆՀԱԲ հարթակում կարող եք գտնել հայտարարություններ՝ մասնագիտական ուսուցումների, դասընթացների, վերապատրաստումների
        մասին, կարող եք փնտրել Ձեր նախընտրելի կազմակերպությունը և ծանոթանալ առաջարկվող միջոցառումներին։ Կարող եք նաև
        տեսնել թափուր աշխատատեղերի մասին հայտարարությունները և դիմել Ձեզ հետաքրքրող և Ձեր կարողություններին համապատասխան
        աշխատանքի համար։ ԶԻՆՀԱԲ հարթակը հնարավորություն է տալիս անմիջապես՝ առցանց դիմել և դառնալ դասընթացի, միջոցառման
        մասնակից։
      </p>
    ),
    style: panelStyle,
  },
  {
    key: '4',
    label: 'Ինչպե՞ս կարող եմ մասնակցել առաջարկվող դասընթացին',
    children: (
      <p>
        ԶԻՆՀԱԲ հարթակում ներկայացված մասնագիտական ուսուցումներին, դասընթացներին, վերապատրաստումներին մասնակցելու համար
        հարթակում գրանցվելու անհրաժշտություն չկա։ Կարող եք դիմել անմիջապես ծառայությունը մատուցող կազմակերպությանը՝
        հայտարարության մեջ նշված եղանակով։
      </p>
    ),
    style: panelStyle,
  },
  {
    key: '5',
    label: 'Ինչպե՞ս կարող եմ դիմել աշխատանքի համար',
    children: (
      <p>
        ԶԻՆՀԱԲ հարթակում ներկայացված թափուր հաստիքների համալրման աշխատանքների հայտարարություններին դիմելու համար
        հարթակում գրանցվելու անհրաժշտություն չկա։ Կարող եք դիմել անմիջապես աշխատանք առաջարկող կազմակերպությանը՝
        հայտարարության մեջ նշված եղանակով։
      </p>
    ),
    style: panelStyle,
  },
  {
    key: '6',
    label: 'Ինչպե՞ս կարող եմ գրանցվել Հարթակում հայտարարություններին ծանոթանալու համար',
    children: (
      <p>ԶԻՆՀԱԲ հարթակում ներկայացված տարաբնույթ հայտարարություններին ծանոթանալու համար գրանցում անհրաժեշտ չէ։</p>
    ),
    style: panelStyle,
  },
  {
    key: '7',
    label: 'Ինչպե՞ս կարող եմ դառնալ ZINHUB-ի գործընկեր',
    children: <p>ԶԻՆՀԱԲ-ի գործընկեր դառնալու համար անհրաժեշտ է գրանցվել հարթակում։</p>,
    style: panelStyle,
  },
  {
    key: '8',
    label: 'Ինչպե՞ս գրանցել ընկերություն',
    children: (
      <p>
        Գրանցումն իրականացվում է Հարթակի գլխավոր էջից՝ Գրանցել Ընկերություն կոճակով։ Անհրաժեշտ է լրացնել Կազմակերպության
        անվանումը, ՀՎՀՀ-ն, Էլ․ հասցե, հեռախոսահամար և ստեղծել գաղտնաբառ համակարգ մուտք գործելու համար։ Գրանցման
        վերաբերյալ կստանաք նամակ էլ. հասցեին, որում առկա ակտվացման հղումը սեղմելով՝ կկարողանաք մուտք գործել Հարթակ։
      </p>
    ),
    style: panelStyle,
  },
  {
    key: '9',
    label: 'Ինչպե՞ս տեղադրել հայտարարություն',
    children: <p> Տե՛ս Օգտվողի ձեռնարկ ՝ էջ 1-2</p>,
    style: panelStyle,
  },
];

const FrequentlyQuestions: React.FC = () => {
  const panelStyle: React.CSSProperties = {
    marginBottom: 0,
    border: 'none',
  };

  return (
    <Container>
      <div className='downloadText'>
        <Title level={4}>Հաճախ տրվող հարցեր</Title>
        <Collapse bordered={false} items={getItems(panelStyle)} />
        <Link
          style={{
            fontSize: '18px',
            fontWeight: 600,
            cursor: 'pointer',
            color: '#0f2b61',
            textDecoration: 'underline',
          }}
          download='https://apizinhub.analysed.ai/files/user-manual.pdf'
          target='_blank'
          rel='noreferrer'
          href='https://apizinhub.analysed.ai/files/user-manual.pdf'
        >
          Օգտվողի ձեռնարկ
        </Link>
      </div>
    </Container>
  );
};
export default FrequentlyQuestions;
