import React, { useState } from 'react';
import { Row, Col, Button, Typography, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

import { AsnForm, AsnButton, Spinner, SuccessModal } from '../../../../components';
import { useGetStatementById, useGetStaticInfoByType, useUpdateStatement } from '../../../../api';
import { validate_messages, PATHS } from '../../../../constants';
import { ICreateTrainingVariables } from '../../../../types/company';
import { ReactComponent as EditSvg } from '../../../../assets/icons/edit.svg';
import JobForm from '../JobForm';

const { Title } = Typography;

const JobEdit: React.FC = () => {
  const [error, setError] = useState({
    responsibilities: false,
    skills: false,
  });
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const { id } = useParams();
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();

  const { mutate: updateStatement } = useUpdateStatement();
  const { data, isFetching } = useGetStaticInfoByType('WORK', { enabled: true, staleTime: 1000 * 60 * 60 });
  const { data: statement, isFetching: isFetchingStatement } = useGetStatementById(id as string, {
    enabled: Boolean(id),
  });

  const onFinish = (values: ICreateTrainingVariables): void => {
    const changedFields = form.getFieldsValue(true, (meta) => meta.touched === true) as ICreateTrainingVariables;
    const requestData = {
      ...changedFields,
      ...(values?.responsibilities !== statement?.workStatement?.responsibilities && {
        responsibilities: values?.responsibilities,
      }),
      ...(values?.skills !== statement?.workStatement?.skills && { skills: values?.skills }),
    };
    if (Object.keys(requestData).length) {
      updateStatement(
        { id: id as string, values: requestData },
        {
          onSuccess: () => {
            setOpenSuccessModal(true);
          },
          onError: (error) => {
            void message.error(error as unknown as string, 2);
          },
        },
      );
    } else {
      setOpenSuccessModal(true);
    }
  };

  const onFinishFailed = (): void => {
    const responsibilities = form.getFieldError('responsibilities');
    const skills = form.getFieldError('skills');
    if (responsibilities?.length || skills?.length) {
      setError({ responsibilities: !!responsibilities?.length, skills: !!skills?.length });
    }
  };
  if (isFetching || isFetchingStatement) {
    return <Spinner />;
  }

  return (
    <>
      <Row style={{ paddingTop: 20 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 20, pull: 2, push: 2 }}>
          <Title level={5}>
            ԱՇԽԱՏԱՆՔ
            <EditSvg style={{ width: 16, height: 16 }} />
          </Title>
          <AsnForm
            layout='vertical'
            validateMessages={validate_messages}
            onFinish={(val): void => onFinish(val as ICreateTrainingVariables)}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <JobForm statement={statement} data={data} error={error} setError={setError} />
            <Row justify={'end'} style={{ padding: '24px 0px', gap: 16 }}>
              <AsnButton onClick={(): void => navigate(-1)}> Չեղարկել</AsnButton>
              <Button type='primary' htmlType='submit'>
                Հրապարակել
              </Button>
            </Row>
          </AsnForm>
        </Col>
      </Row>
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => setOpenSuccessModal(false)}
          content='Ձեր հայտարարությունը հաջողությամբ խմբագրվել է'
        >
          <AsnButton style={{ marginTop: 20 }} onClick={(): void => navigate(PATHS.COMPANY_ANNOUNCMENTS)}>
            Վերադառնալ իմ էջ
          </AsnButton>
        </SuccessModal>
      )}
    </>
  );
};

export default JobEdit;
