import React from 'react';
import { Typography, Row, Col, Space } from 'antd';

import { StatisticCard } from '../../../components';
import { ReactComponent as Statistic1Svg } from '../../../assets/icons/statistics1.svg';
import { ReactComponent as StatisticBlueSvg } from '../../../assets/icons/statisticsBlue.svg';
import { ReactComponent as Statistic2Svg } from '../../../assets/icons/statistics2.svg';
import { ReactComponent as StatisticOrangeSvg } from '../../../assets/icons/statisticOrange.svg';
import { ReactComponent as Statistic3Svg } from '../../../assets/icons/statistics3.svg';
import { ReactComponent as Statistic4Svg } from '../../../assets/icons/statistics4.svg';
import { IStatistics } from '../../../types/landing';

const { Title } = Typography;

const Statistics: React.FC<IStatistics> = ({ statistics }) => {
  return (
    <Space direction='vertical' style={{ width: '100%', padding: '32px 4% 48px 4%' }}>
      <Title level={5} style={{ marginBottom: 24 }}>
        Վիճակագրություն
      </Title>
      <Row gutter={[32, 32]}>
        <Col xxl={{ span: 6 }} xl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <StatisticCard
            svg={<Statistic1Svg />}
            title={statistics[0]?.title}
            count={statistics[0]?.count}
            rightSvg={<StatisticBlueSvg style={{ width: '100%' }} />}
          />
        </Col>
        <Col xxl={{ span: 6 }} xl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <StatisticCard
            svg={<Statistic2Svg />}
            title={statistics[1]?.title}
            count={statistics[1]?.count}
            rightSvg={<StatisticOrangeSvg style={{ width: '100%' }} />}
          />
        </Col>
        <Col xxl={{ span: 6 }} xl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <StatisticCard
            svg={<Statistic3Svg />}
            title={statistics[2]?.title}
            count={statistics[2]?.count}
            rightSvg={<StatisticBlueSvg style={{ width: '100%' }} />}
          />
        </Col>
        <Col xxl={{ span: 6 }} xl={{ span: 6 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <StatisticCard
            svg={<Statistic4Svg />}
            title={statistics[3]?.title}
            count={Number((statistics[3]?.count as string).replace(/%/g, ''))}
            rightSvg={<StatisticOrangeSvg style={{ width: '100%' }} />}
          />
        </Col>
      </Row>
    </Space>
  );
};

export default Statistics;
