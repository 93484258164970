import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import { PATHS } from '../../constants';
import client from '../client';
import { ISuccesMessage } from '../../types/global';
import { TCompanyVariables, TUpdateCompany } from '../../types/api/company';

const url = '/api/company/:id';

const uesUpdateCompany: TUpdateCompany = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, TCompanyVariables, unknown>({
    mutationFn: (params) => {
      return client.put(url.replace(':id', params.id), params.values);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(['/api/company/:id']);
      navigate(PATHS.COMPANY_MYINFO);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 2);
    },
  });

  return mutation;
};

export default uesUpdateCompany;
