import styled from 'styled-components';

export const MyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 420px;
  gap: 2rem;

  span {
    font-size: 16px;
  }
`;
