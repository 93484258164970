import React from 'react';
import { Row, Col, Input, Select, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { AsnForm } from '../../../components';
import { emailRules } from '../../../constants';
import { ICompanyUpdateForm } from '../../../types/company';
import { handleEditorErrors, trimAllSpaces } from '../../../helpers';

const CompanyUpdateForm: React.FC<ICompanyUpdateForm> = ({ staticValues, company, error, setError }) => {
  const form = AsnForm.useFormInstance();

  return (
    <>
      <Row gutter={[80, 0]}>
        <Col span={12}>
          <AsnForm.Item
            name='phone'
            label='Հեռախոսահամար'
            rules={[{ required: true }, { min: 8 }]}
            normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
            initialValue={company?.phone}
          >
            <Input />
          </AsnForm.Item>
        </Col>
        <Col span={12}>
          <AsnForm.Item
            name='numberOfEmployees'
            label='Աշխատակիցների քանակ'
            rules={[{ required: true }, { max: 4 }]}
            initialValue={company?.numberOfEmployees?.toString()}
            normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
          >
            <Input />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={[80, 0]}>
        <Col span={12}>
          <AsnForm.Item name='email' label='Էլ․հասցե' rules={[...emailRules]} initialValue={company?.user?.email}>
            <Input disabled />
          </AsnForm.Item>
        </Col>
        <Col span={12}>
          <AsnForm.Item
            name='typeId'
            label='Ընկերության տեսակը'
            rules={[{ required: true, message: 'Խնդրում ենք լրացնել Ընկերության տեսակը' }]}
            initialValue={company?.typeId}
          >
            <Select options={staticValues?.companyTypes} />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={[80, 0]}>
        <Col span={12}>
          <AsnForm.Item
            name='regionId'
            label='Մարզը'
            rules={[{ required: true, message: 'Խնդրում ենք լրացնել Մարզը' }]}
            initialValue={company?.regionId}
          >
            <Select options={staticValues?.regions} />
          </AsnForm.Item>
        </Col>
        <Col span={12}>
          <AsnForm.Item
            name='creationDate'
            label='Ստեղծման Տարեթիվը'
            rules={[{ required: true, message: 'Խնդրում ենք լրացնել Ստեղծման Տարեթիվը' }]}
            initialValue={company?.creationDate ? dayjs(company?.creationDate) : undefined}
          >
            <DatePicker
              picker='year'
              style={{ width: '100%' }}
              placeholder=''
              disabledDate={(current: Dayjs): boolean => current > dayjs().subtract(1, 'day')}
            />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={[80, 0]} style={{ marginBottom: 18 }}>
        <Col span={12}>
          <AsnForm.Item
            name='location'
            label='Գտնվելու վայրը'
            rules={[
              { required: true, message: 'Խնդրում ենք լրացնել Գտնվելու վայրը' },
              { max: 100, message: 'Գտնվելու վայրը պետք է պարունակի մինչև 100 նիշ' },
            ]}
            initialValue={company?.location}
            normalize={(value: string): string => value?.trimStart()}
          >
            <Input />
          </AsnForm.Item>
        </Col>
        <Col span={12}>
          <AsnForm.Item
            name='website'
            label='Կայքը'
            initialValue={company?.website}
            normalize={(value: string): string => trimAllSpaces(value)}
            rules={[
              {
                pattern: new RegExp(/^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s/$.?#]+[^\s]*$/i),
                message: 'Կայքը դաշտը պետք է ունենա հետեվյալ ձևաչափը http://example.com կամ https://example.com',
              },
            ]}
          >
            <Input />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: 18 }}>
        <Col span={24}>
          <AsnForm.Item
            name='description'
            label='Ընկերության մասին'
            rules={[{ max: 2048, message: 'Դաշտը պետք է պարունակի մինչև 2048 նիշ' }]}
            initialValue={company?.description}
          >
            <div className={error?.description ? 'editor_error' : 'editor'}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bulletedList'],
                }}
                onChange={(event, editor): void => {
                  const data = editor.getData();
                  form.setFieldValue('description', data?.trimStart().replace(/(&nbsp;|\s)+/g, ' '));
                  const hasError = handleEditorErrors(form, 'description', data, 0, 2048);
                  setError({ ...error, description: hasError });
                }}
                data={company?.description}
              />
            </div>
          </AsnForm.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: 18 }}>
        <Col span={24}>
          <AsnForm.Item
            name='companyValues'
            label='Ընկերության Արժեքները'
            rules={[{ max: 2048, message: 'Դաշտը պետք է պարունակի մինչև 2048 նիշ' }]}
            initialValue={company?.companyValues}
          >
            <div className={error?.companyValues ? 'editor_error' : 'editor'}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bulletedList'],
                }}
                onChange={(event, editor): void => {
                  const data = editor.getData();
                  form.setFieldValue('companyValues', data?.trimStart().replace(/(&nbsp;|\s)+/g, ' '));
                  const hasError = handleEditorErrors(form, 'companyValues', data, 0, 2048);
                  setError({ ...error, companyValues: hasError });
                }}
                data={company?.companyValues}
              />
            </div>
          </AsnForm.Item>
        </Col>
      </Row>
    </>
  );
};

export default CompanyUpdateForm;
