import React from 'react';
import { Row, Col, Select, Input, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { handleEditorErrors } from '../../../helpers';
import { AsnForm } from '../../../components';
import { IJobForm } from '../../../types/company';

const JobForm: React.FC<IJobForm> = ({ data, error, setError, statement }) => {
  const form = AsnForm.useFormInstance();
  return (
    <>
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Հայտարարության վերնագիրը'
            name={'title'}
            rules={[{ required: true }, { min: 2, max: 50 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.workStatement?.title}
          >
            <Input style={{ maxWidth: 630 }} />
          </AsnForm.Item>
          <AsnForm.Item
            label='Ոլորտ'
            name={'filedWorkId'}
            rules={[{ required: true }]}
            initialValue={statement?.workStatement?.filedWork?.id}
          >
            <Select style={{ maxWidth: 630 }} options={data?.filedOfWork} />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={8}>
          <AsnForm.Item
            label='Դիմելու վերջնաժամկետ'
            name={'applicationDeadline'}
            rules={[{ required: true }]}
            initialValue={
              statement?.workStatement?.applicationDeadline
                ? dayjs(statement?.workStatement?.applicationDeadline)
                : undefined
            }
          >
            <DatePicker
              style={{ width: '100%' }}
              placeholder=''
              disabledDate={(current: Dayjs): boolean => current < dayjs().subtract(1, 'day')}
            />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Տևողությունը'
            name={'duration'}
            rules={[{ required: true }, { min: 2, max: 50 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.workStatement?.duration}
          >
            <Input />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Մակարդակ'
            name={'levelId'}
            rules={[{ required: true }]}
            initialValue={statement?.workStatement?.level?.id}
          >
            <Select options={data?.workLevel} />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={8}>
          <AsnForm.Item
            label='Հասցե'
            name={'location'}
            rules={[{ required: true }, { min: 9, max: 100 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.workStatement?.location}
          >
            <Input />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Աշխատավարձ'
            name={'salary'}
            rules={[{ max: 10 }]}
            normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
            initialValue={statement?.workStatement?.salary}
          >
            <Input />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Զբաղվածություն'
            name={'employmentId'}
            rules={[{ required: true }]}
            initialValue={statement?.workStatement?.employment?.id}
          >
            <Select options={data?.employment} />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={8}>
          <AsnForm.Item
            label='Գտնվելու վայրը (Մարզ)'
            name={'regionId'}
            rules={[{ required: true }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.workStatement?.region?.id}
          >
            <Select options={data?.regions} />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Էլ․հասցե'
            name={'registrationLink'}
            rules={[
              { required: true },
              { type: 'email', message: 'Խնդրում ենք մուտքագրել էլ․ հասցեն հետևյալ ֆորմատով՝ yourname@domain.com' },
            ]}
            initialValue={statement?.workStatement?.registrationLink}
          >
            <Input style={{ maxWidth: 630 }} />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Հեռախոս'
            name={'phone'}
            rules={[{ required: true }, { min: 8, max: 15 }]}
            normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
            initialValue={statement?.workStatement?.phone}
          >
            <Input />
          </AsnForm.Item>
        </Col>
      </Row>
      <div
        style={{
          borderRadius: '2px',
          background:
            'linear-gradient(90deg, rgba(31, 130, 255, 0.20) 0%, #1F82FF 51.2%, rgba(31, 130, 255, 0.20) 100%)',
          height: '2px',
          marginBottom: 32,
          marginTop: 16,
        }}
      />
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Աշխատանքի նկարագրություն'
            name={'description'}
            rules={[{ required: true }, { min: 20, max: 1000 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.workStatement?.description}
          >
            <Input.TextArea style={{ maxWidth: 630 }} />
          </AsnForm.Item>
          <AsnForm.Item
            label='Պարտականություններ'
            name={'responsibilities'}
            rules={[{ required: true }, { min: 20, max: 1300 }]}
            initialValue={statement?.workStatement?.responsibilities}
          >
            <div style={{ maxWidth: 630 }} className={error?.responsibilities ? 'editor_error' : 'editor'}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bulletedList'],
                }}
                onChange={(event, editor): void => {
                  const data = editor.getData();
                  form.setFieldValue('responsibilities', data.trimStart().replace(/(&nbsp;|\s)+/g, ' '));
                  const hasError = handleEditorErrors(form, 'responsibilities', data, 20, 1300);
                  setError({ ...error, responsibilities: hasError });
                }}
                data={statement?.workStatement?.responsibilities}
              />
            </div>
          </AsnForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Անհրաժեշտ հմտություներ'
            name={'skills'}
            rules={[{ required: true }, { min: 20, max: 1000 }]}
            initialValue={statement?.workStatement?.skills}
          >
            <div style={{ maxWidth: 630 }} className={error?.skills ? 'editor_error' : 'editor'}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bulletedList'],
                }}
                onChange={(event, editor): void => {
                  const data = editor.getData();
                  form.setFieldValue('skills', data.trimStart().replace(/(&nbsp;|\s)+/g, ' '));
                  const hasError = handleEditorErrors(form, 'skills', data, 20, 1000);
                  setError({ ...error, skills: hasError });
                }}
                data={statement?.workStatement?.skills}
              />
            </div>
          </AsnForm.Item>
          <AsnForm.Item
            label='Հավելյալ նշումներ'
            name={'additionalNotes'}
            rules={[{ max: 500 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.workStatement?.additionalNotes}
          >
            <Input.TextArea style={{ maxWidth: 630 }} />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Ինչ ենք մենք առաջարկում (Ընկերության մասին)'
            name={'whatWeOffer'}
            rules={[{ max: 500 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.workStatement?.whatWeOffer}
          >
            <Input.TextArea style={{ maxWidth: 630 }} />
          </AsnForm.Item>
        </Col>
      </Row>
    </>
  );
};
export default JobForm;
