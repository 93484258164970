import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import dayjs from 'dayjs';

import { PATHS, validate_messages } from '../../../../constants';
import { trimSpaces } from '../../../../helpers';
import { AsnForm, Spinner, AsnButton } from '../../../../components';
import { useGetCompanyById, useGetCompanyInfo, useUpdateCompany } from '../../../../api';
import { useAuth } from '../../../../hooks/useAuth';
import { TGetCompany } from '../../../../types/api/company';
import { IUserDetails } from '../../../../types/auth';
import Header from '../Header';
import CompanyUpdateForm from '../CompanyUpdateForm';

const EditMyInfo: React.FC = () => {
  const [error, setError] = useState({
    description: false,
    companyValues: false,
  });
  const [urlBg, setUrlBg] = useState<string | undefined>(undefined);
  const [urlPhoto, setUrlPhoto] = useState<string | undefined>(undefined);

  const [editCompanyName, setEditCompanyName] = useState<boolean>(false);

  const [form] = AsnForm.useForm();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [formCompanyName] = AsnForm.useForm();
  const navigate = useNavigate();

  const { user, setUser } = useAuth();
  const { data: company, isFetching: isFetchingCompany } = useGetCompanyById(user?.id as string, {
    enabled: Boolean(user?.id),
  });
  const { data: staticValues, isFetching: isFetchingRegions } = useGetCompanyInfo({ enabled: true });
  const { mutate: updateCompany, isLoading } = useUpdateCompany();

  const onFinish = (values: TGetCompany): void => {
    const errorName = formCompanyName.getFieldError('name');

    if (errorName.length) return;
    if (editCompanyName) {
      const el = scrollRef?.current;
      el?.scrollIntoView({ behavior: 'smooth' });
      return formCompanyName.setFields([
        {
          name: ['name'],
          errors: [`Խնդրում ենք հաստատել`],
        },
      ]);
    }

    const changedFields = form.getFieldsValue(true, (meta) => meta.touched === true) as TGetCompany;
    const keys = Object.keys(changedFields);
    const name = formCompanyName.getFieldValue('name') as string;
    const requsetData = {
      ...(keys?.includes('phone') && company?.phone !== values?.phone && { phone: values?.phone }),
      ...(keys?.includes('numberOfEmployees') &&
        company?.numberOfEmployees !== Number(values?.numberOfEmployees) && {
          numberOfEmployees: Number(values?.numberOfEmployees),
        }),
      ...(keys?.includes('typeId') && company?.typeId !== values?.typeId && { typeId: values?.typeId }),
      ...(keys?.includes('regionId') && company?.regionId !== values?.regionId && { regionId: values?.regionId }),
      ...(keys?.includes('creationDate') &&
        dayjs(company?.creationDate).format('YYYY') !== dayjs(values?.creationDate).format('YYYY') && {
          creationDate: values?.creationDate,
        }),
      ...(keys?.includes('location') &&
        company?.location !== trimSpaces(values?.location) && {
          location: trimSpaces(values?.location),
        }),
      ...(keys?.includes('website') && company?.website !== values?.website && { website: values?.website }),
      ...{ description: trimSpaces(values?.description) },
      ...{ companyValues: trimSpaces(values?.companyValues) },
      ...(urlBg && urlBg !== company?.backgroundPhoto ? { backgroundPhoto: urlBg } : {}),
      ...(urlPhoto && urlPhoto !== company?.photo ? { photo: urlPhoto } : {}),
      ...(name && name?.trimEnd() !== company?.name ? { name: name?.trimEnd() } : {}),
    };
    if (Object.keys(requsetData).length) {
      updateCompany({ id: company?.id, values: requsetData as TGetCompany });
      setUser({ ...user, name: name?.trimEnd() ?? company?.name } as IUserDetails);
    }
    if (!Object.keys(requsetData).length) {
      navigate(PATHS.COMPANY_MYINFO);
    }
  };

  const cancelCompanyUpdate = (): void => {
    navigate(PATHS.COMPANY_MYINFO);
  };

  const onFinishFailed = (): void => {
    const description = form.getFieldError('description');
    const companyValues = form.getFieldError('companyValues');
    if (description?.length || companyValues?.length) {
      setError({ description: !!description?.length, companyValues: !!companyValues?.length });
    }
  };

  if (isFetchingCompany || isFetchingRegions) {
    return <Spinner />;
  }

  return (
    <>
      <div ref={scrollRef}>
        <AsnForm form={formCompanyName}>
          <Header
            setUrlBg={setUrlBg}
            urlBg={urlBg}
            urlPhoto={urlPhoto}
            setUrlPhoto={setUrlPhoto}
            backgroundImage={company?.backgroundPhoto}
            photo={company?.photo}
            name={company?.name}
            editCompanyName={editCompanyName}
            setEditCompanyName={setEditCompanyName}
          />
        </AsnForm>
      </div>
      <Row>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          <AsnForm
            form={form}
            layout='vertical'
            style={{ width: '100%' }}
            validateMessages={validate_messages}
            onFinish={(values): void => onFinish(values as TGetCompany)}
            onFinishFailed={onFinishFailed}
          >
            <CompanyUpdateForm staticValues={staticValues} company={company} error={error} setError={setError} />
            <Row justify={'end'} style={{ paddingBottom: 30, gap: 16 }}>
              {company?.typeId && <AsnButton onClick={(): void => cancelCompanyUpdate()}>Չեղարկել</AsnButton>}
              <AsnForm.Item>
                <Button type='primary' htmlType='submit' loading={isLoading}>
                  Հաստատել և շարունակել
                </Button>
              </AsnForm.Item>
            </Row>
          </AsnForm>
        </Col>
      </Row>
    </>
  );
};

export default EditMyInfo;
