import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'antd';

import { useGetStaticInfoByType, useCreateStatement } from '../../../../api';
import { AsnForm, AsnButton, Spinner, SuccessModal, OtherStatementView } from '../../../../components';
import { validate_messages, PATHS, COLORS } from '../../../../constants';
import ChooseForm from '../ChooseForm';
import { ICreateTrainingVariables } from '../../../../types/company';
import OtherForm from '../OtherForm';
import { IOtherStatement, TGetStatement } from '../../../../types/api/company';

const { AZURE } = COLORS;

const Other: React.FC = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [urlPhoto, setUrlPhoto] = useState<string | undefined>(undefined);
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();

  const { data, isFetching } = useGetStaticInfoByType('OTHER', { enabled: true, staleTime: 1000 * 60 * 60 });
  const { mutate: createStatement, isLoading } = useCreateStatement();

  const onFinish = (values: ICreateTrainingVariables): void => {
    createStatement(
      { statementData: { ...values, photo: urlPhoto ? urlPhoto : null }, type: 'OTHER' },
      {
        onSuccess: (): void => {
          setOpenSuccessModal(true);
        },
      },
    );
  };
  const handleAdd = (): void => {
    setOpenSuccessModal(false);
    setUrlPhoto(undefined);
    form.resetFields();
  };

  const otherViewModalData = {
    otherStatement: {
      ...(form.getFieldsValue() as IOtherStatement),

      region: {
        title: data?.regions?.filter((region) => region.value === form.getFieldValue('regionId'))?.[0]?.label,
      },
    },
    company: {
      name: data?.company?.name,
      photo: data?.company?.photo,
    },
  };
  const handleClose = (): void => {
    setOpenSuccessModal(false);
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
    form.resetFields();
  };

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <Row style={{ paddingTop: 20 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 20, pull: 2, push: 2 }}>
          <ChooseForm radioValue={'OTHER'} />
          <AsnForm
            layout='vertical'
            validateMessages={validate_messages}
            onFinish={(val): void => onFinish(val as ICreateTrainingVariables)}
            form={form}
          >
            <OtherForm data={data} urlPhoto={urlPhoto} setUrlPhoto={setUrlPhoto} />
            <Row justify={'end'} style={{ padding: '24px 0px', gap: 16 }}>
              <AsnButton onClick={(): void => setOpenViewModal(true)}>Դիտել</AsnButton>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Հրապարակել
              </Button>
            </Row>
          </AsnForm>
        </Col>
      </Row>
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => handleClose()}
          content={
            <>
              Շնորհավորում ենք Ձեր հայտարարությունը հրապարակվել է <span style={{ color: `${AZURE}` }}>ZINHUB.am</span>{' '}
              հարթակում
            </>
          }
          width='auto'
        >
          <Row style={{ marginTop: 20, gap: 16 }}>
            <AsnButton onClick={(): void => navigate(PATHS.COMPANY_ANNOUNCMENTS)}>Վերադառնալ իմ էջ</AsnButton>
            <Button type='primary' onClick={(): void => handleAdd()}>
              Տեղադրել այլ հայտարարություն
            </Button>
          </Row>
        </SuccessModal>
      )}
      {openViewModal && (
        <Modal
          centered
          open={openViewModal}
          onCancel={(): void => setOpenViewModal(false)}
          footer={false}
          width={'80vw'}
          bodyStyle={{ height: '80vh', overflow: 'auto', marginTop: 20 }}
        >
          <OtherStatementView other={otherViewModalData as TGetStatement} modal={true} />
        </Modal>
      )}
    </>
  );
};

export default Other;
