import { Space, Typography, Row } from 'antd';
import React from 'react';

import { IPrincipalCard } from '../../types/components';

const { Paragraph } = Typography;
const PrincipalCard: React.FC<IPrincipalCard> = ({ svg, title, description }) => {
  return (
    <Row style={{ borderRadius: '10px', border: '1px solid rgba(31, 130, 255, 0.20)', padding: 8 }}>
      <Space direction='vertical' align='center'>
        {svg}
        <Paragraph style={{ fontWeight: 600, marginBottom: 0 }}>{title}</Paragraph>
        <Paragraph style={{ textAlign: 'center', minHeight: 132, marginBottom: 0 }}>{description}</Paragraph>
      </Space>
    </Row>
  );
};

export default PrincipalCard;
