import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

import { tableDateFormat } from '../../../../constants';
import { ICompanyListPending } from '../../../../api/admin/types/users/company';

export const columnsRejected = (): ColumnsType<ICompanyListPending> => {
  return [
    {
      title: 'Անվանում',
      dataIndex: 'name',
    },
    {
      title: 'ՀՎՀՀ',
      dataIndex: 'taxAccount',
    },
    {
      title: 'Ստեղծման ամսաթիվ',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: string): React.ReactNode => <div>{dayjs(createdAt).format(tableDateFormat)}</div>,
    },
    {
      title: 'Մերժման ամսաթիվ',
      dataIndex: 'rejectDate',
      sorter: true,
      render: (rejectDate: string): React.ReactNode => <div>{dayjs(rejectDate).format(tableDateFormat)}</div>,
    },
    {
      title: ' Պատճառ',
      dataIndex: 'reasonForRejection',
      ellipsis: true,
    },
    {
      title: ' Ում կողմից',
      dataIndex: 'rejectedAdminName',
    },
  ];
};
