import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'antd';

import CourseForm from '../CourseForm';
import { useCreateStatement, useGetStaticInfoByType } from '../../../../api';
import { AsnForm, AsnButton, Spinner, SuccessModal, CourseView } from '../../../../components';
import { validate_messages, PATHS, COLORS } from '../../../../constants';
import ChooseForm from '../ChooseForm';
import { ICreateTrainingVariables } from '../../../../types/company';
import { ITrainingStatement, TGetStatement } from '../../../../types/api/company';

const { AZURE } = COLORS;

const Course: React.FC = () => {
  const [error, setError] = useState({
    program: false,
    description: false,
  });
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);

  const [form] = AsnForm.useForm();
  const navigate = useNavigate();
  const { mutate: createStatement, isLoading } = useCreateStatement();
  const { data, isFetching } = useGetStaticInfoByType('TRINING', { enabled: true, staleTime: 1000 * 60 * 60 });

  const onFinishFailed = (): void => {
    const description = form.getFieldError('description');
    const program = form.getFieldError('program');
    if (description?.length || program?.length) {
      setError({ program: !!program?.length, description: !!description?.length });
    }
  };

  const onFinish = (values: ICreateTrainingVariables): void => {
    const filteredTeacherIds = values.teacherIds?.filter((item) => item.length > 0);
    const statementData = {
      ...values,
      topics: values?.topics?.filter((item) => item?.length > 0),
    };
    if (!filteredTeacherIds?.length) {
      delete statementData.teacherIds;
    } else {
      statementData.teacherIds = filteredTeacherIds;
    }
    createStatement(
      {
        statementData,
        type: 'TRINING',
      },
      {
        onSuccess: (): void => {
          setOpenSuccessModal(true);
        },
      },
    );
  };

  const handleAdd = (): void => {
    setOpenSuccessModal(false);
    form.resetFields();
  };

  const handleClose = (): void => {
    setOpenSuccessModal(false);
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
    form.resetFields();
  };

  const courseViewModalData = {
    trainingStatement: {
      ...(form.getFieldsValue() as ITrainingStatement),
      format: {
        title: data?.courseFormat?.filter((course) => course.value === form.getFieldValue('formatId'))?.[0]?.label,
      },
      region: {
        title: data?.regions?.filter((region) => region.value === form.getFieldValue('regionId'))?.[0]?.label,
      },
      level: {
        title: data?.courseLevel?.filter((level) => level.value === form.getFieldValue('levelId'))?.[0]?.label,
      },
      language: {
        title: data?.courseLanguage?.filter((language) => language.value === form.getFieldValue('languageId'))?.[0]
          ?.label,
      },
      teachers: data?.teachers?.filter(
        (teacher) => (form.getFieldValue('teacherIds') as string[])?.includes(teacher?.id),
      ),
    },
    company: {
      name: data?.company?.name,
      photo: data?.company?.photo,
    },
  };

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <Row style={{ paddingTop: 20 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 20, pull: 2, push: 2 }}>
          <ChooseForm radioValue={'TRINING'} />
          <AsnForm
            layout='vertical'
            validateMessages={validate_messages}
            onFinish={(val): void => onFinish(val as ICreateTrainingVariables)}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <CourseForm data={data} error={error} setError={setError} />
            <Row justify={'end'} style={{ padding: '24px 0px', gap: 16 }}>
              <AsnButton onClick={(): void => setOpenViewModal(true)}>Դիտել</AsnButton>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                Հրապարակել
              </Button>
            </Row>
          </AsnForm>
        </Col>
      </Row>
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => handleClose()}
          content={
            <>
              Շնորհավորում ենք Ձեր հայտարարությունը հրապարակվել է <span style={{ color: `${AZURE}` }}>ZINHUB.am</span>{' '}
              հարթակում
            </>
          }
          width='auto'
        >
          <Row style={{ marginTop: 20, gap: 16 }}>
            <AsnButton onClick={(): void => navigate(PATHS.COMPANY_ANNOUNCMENTS)}>Վերադառնալ իմ էջ</AsnButton>
            <Button type='primary' onClick={(): void => handleAdd()}>
              Տեղադրել այլ հայտարարություն
            </Button>
          </Row>
        </SuccessModal>
      )}
      {openViewModal && (
        <Modal
          centered
          open={openViewModal}
          onCancel={(): void => setOpenViewModal(false)}
          footer={false}
          width={'80vw'}
          bodyStyle={{ height: '80vh', overflow: 'auto', marginTop: 20 }}
        >
          <CourseView course={courseViewModalData as TGetStatement} modal={true} />
        </Modal>
      )}
    </>
  );
};

export default Course;
