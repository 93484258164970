import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

import useGetStatisticsInfo from '../../../api/admin/statistics';
import StatisticsCard from '../../../components/Statistics/Card';
import AnnouncementsByCount from '../../../components/Statistics/AnnouncementsCount';
import AnnouncementsByStatus from '../../../components/Statistics/AnnouncementsByStatus';
import ChartPie from '../../../components/Statistics/ChartPie';
import CompanyByRegion from '../../../components/Statistics/ChartPie/companyByRegion';
import CompaniesByStatus from '../../../components/Statistics/CompaniesByStatus';
import { Spinner } from '../../../components';
import { ReactComponent as DropdownTop } from '../../../assets/icons/dropdown-top.svg';
import { ReactComponent as DropdownBottom } from '../../../assets/icons/dropdown-bottom.svg';
import InvolvementCard from '../../../components/Statistics/Card/involvementCard';
import { IStatusesByMonths } from '../../../api/admin/types/statistics';

import { ChartContainer, CustomButton, InsertedStatisticsContainer, RootContainer, TitleContainer } from './styled';
const { Title } = Typography;

const Statistics: React.FC = () => {
  const [trainingChartHide, setTrainingChartHide] = useState(true);
  const [workChartHide, setWorkChartHide] = useState(true);
  const [serviceChartHide, setServiceChartHide] = useState(true);
  const [companyChartHide, setCompanyChartHide] = useState(true);
  const [companyStatusesByMonths, setCompanyStatusesByMonths] = useState<IStatusesByMonths[]>([]);
  const { data, isFetching } = useGetStatisticsInfo({ enabled: true });
  useEffect(() => {
    setCompanyStatusesByMonths(
      data.companyStatusesByMonths?.map((item) => {
        return {
          ...item,
          type:
            item.type === 'ACTIVE'
              ? 'Ակտիվ'
              : item.type === 'INACTIVE'
              ? 'Չակտիվացված'
              : item.type === 'BLOCKED'
              ? 'Արգելափակված'
              : item.type === 'PENDING'
              ? 'Ընթացքի մեջ'
              : 'Մերժված',
        };
      }),
    );
  }, [data]);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <RootContainer>
      <ChartContainer>
        <Title level={4}>Ընդամենը</Title>
        <InsertedStatisticsContainer>
          {data.insertedStatistics?.map((item) => <StatisticsCard key={item.title} insertedStatistics={item} />)}
        </InsertedStatisticsContainer>
      </ChartContainer>
      <ChartContainer>
        <Title level={4}>Ներգրավվածություն</Title>
        <InsertedStatisticsContainer>
          {data.participationStatistic?.map((item) => <InvolvementCard key={item.title} item={item} />)}
        </InsertedStatisticsContainer>
      </ChartContainer>

      <ChartContainer>
        <TitleContainer>
          <Title level={4}>Հայտարարություններ (Քանակ)</Title>
        </TitleContainer>
        <AnnouncementsByCount statementsByMonths={data.statementsByMonths} />
      </ChartContainer>

      <ChartContainer>
        <TitleContainer>
          <Title level={4}>Հայտարարությունները՝ ըստ կարգավիճակի</Title>
        </TitleContainer>
        <AnnouncementsByStatus statementsByStatuses={data.statementsByStatuses} />
      </ChartContainer>

      <ChartContainer>
        <TitleContainer>
          <Title level={4}>Ուսուցում</Title>
          {!trainingChartHide ? (
            <CustomButton onClick={(): void => setTrainingChartHide(!trainingChartHide)}>
              <DropdownTop />
            </CustomButton>
          ) : (
            <CustomButton onClick={(): void => setTrainingChartHide(!trainingChartHide)}>
              <DropdownBottom />
            </CustomButton>
          )}
        </TitleContainer>
        {!trainingChartHide ? (
          <div>
            <ChartContainer
              style={{
                marginBottom: '1rem',
              }}
            >
              <TitleContainer>
                <Title level={5}>Դասընթացների բաշխվածությունը՝ ըստ մարզերի</Title>
              </TitleContainer>
              <ChartPie pieData={data.trainingStatisticByRegions} />
            </ChartContainer>
            <ChartContainer>
              <TitleContainer>
                <Title level={5}>Դասընթացների բաշխվածությունը՝ ըստ ոլորտի</Title>
              </TitleContainer>
              <ChartPie pieData={data.trainingStatisticByFields} />
            </ChartContainer>
          </div>
        ) : null}
      </ChartContainer>
      <ChartContainer>
        <TitleContainer>
          <Title level={4}>Աշխատանք</Title>
          {!workChartHide ? (
            <CustomButton onClick={(): void => setWorkChartHide(!workChartHide)}>
              <DropdownTop />
            </CustomButton>
          ) : (
            <CustomButton onClick={(): void => setWorkChartHide(!workChartHide)}>
              <DropdownBottom />
            </CustomButton>
          )}
        </TitleContainer>
        {!workChartHide ? (
          <div>
            <ChartContainer
              style={{
                marginBottom: '1rem',
              }}
            >
              <TitleContainer>
                <Title level={5}>Աշխատանքի բաշխվածությունը՝ ըստ մարզերի</Title>
              </TitleContainer>
              <ChartPie pieData={data.workStatisticByRegions} />
            </ChartContainer>
            <ChartContainer>
              <TitleContainer>
                <Title level={5}>Աշխատանքի բաշխվածությունը՝ ըստ ոլորտի</Title>
              </TitleContainer>
              <ChartPie pieData={data.workStatisticByFields} />
            </ChartContainer>
          </div>
        ) : null}
      </ChartContainer>
      <ChartContainer>
        <TitleContainer>
          <Title level={4}>Ծառայություն</Title>
          {!serviceChartHide ? (
            <CustomButton onClick={(): void => setServiceChartHide(!serviceChartHide)}>
              <DropdownTop />
            </CustomButton>
          ) : (
            <CustomButton onClick={(): void => setServiceChartHide(!serviceChartHide)}>
              <DropdownBottom />
            </CustomButton>
          )}
        </TitleContainer>
        {!serviceChartHide ? (
          <div>
            <ChartContainer
              style={{
                marginBottom: '1rem',
              }}
            >
              <TitleContainer>
                <Title level={5}>Ծառայության բաշխվածությունը՝ ըստ մարզերի</Title>
              </TitleContainer>
              <ChartPie pieData={data.otherStatisticByRegions} />
            </ChartContainer>
          </div>
        ) : null}
      </ChartContainer>
      <ChartContainer>
        <TitleContainer>
          <Title level={4}>Ընկերություն</Title>
          {!companyChartHide ? (
            <CustomButton onClick={(): void => setCompanyChartHide(!companyChartHide)}>
              <DropdownTop />
            </CustomButton>
          ) : (
            <CustomButton onClick={(): void => setCompanyChartHide(!companyChartHide)}>
              <DropdownBottom />
            </CustomButton>
          )}
        </TitleContainer>
        {!companyChartHide ? (
          <div>
            <ChartContainer
              style={{
                marginBottom: '1rem',
              }}
            >
              <TitleContainer>
                <Title level={5}>Ընկերություններն և հայտերը՝ ըստ կարգավիճակի</Title>
              </TitleContainer>
              <CompaniesByStatus companyStatusesByMonths={companyStatusesByMonths} />
            </ChartContainer>
            <ChartContainer>
              <TitleContainer>
                <Title level={5}>Ընկերությունները՝ ըստ գործունեության վայրի</Title>
              </TitleContainer>
              <CompanyByRegion pieData={data.companiesStatisticByRegions} />
            </ChartContainer>
          </div>
        ) : null}
      </ChartContainer>
    </RootContainer>
  );
};

export default Statistics;
