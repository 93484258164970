import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { message } from 'antd';

import client from '../client';
import { ForgotPasswordVariables, TUseForgotPassword } from '../../types/api/auth';
import { ISuccesMessage } from '../../types/global';

const url = '/api/auth/password/forget';

const useForgotPassword: TUseForgotPassword = () => {
  const mutation = useMutation<AxiosResponse<ISuccesMessage>, AxiosError, ForgotPasswordVariables, unknown>({
    mutationFn: (email) => {
      return client.post(url, email);
    },
    onError: (error) => {
      void message.error(error as unknown as string, 4);
    },
  });

  return mutation;
};

export default useForgotPassword;
