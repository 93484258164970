import React from 'react';
import { useRouteError } from 'react-router-dom';

import Error404 from '../components/Error404';
import Error403 from '../components/Error403';
import Error500 from '../components/Error500';

const ErrorBoundary: React.FC = () => {
  const error = useRouteError() as { status: number };
  return error?.status === 404 ? <Error404 /> : error?.status === 403 ? <Error403 /> : <Error500 />;
};

export default ErrorBoundary;
