import { useQuery } from '@tanstack/react-query';

import client from '../../client';
import { GetStoriesById, TUseGetStoriesById } from '../types/stories/types';
export const url = 'api/admin/success-stories/:id';

const useGetStoreById: TUseGetStoriesById = (id, options) => {
  const result = useQuery({
    queryKey: [url, id],
    queryFn: () => client.get(url.replace(':id', id)),
    ...options,
    select: (data) => data?.data,
  });
  const { data, isLoading, isSuccess, isFetching } = result;
  return {
    data: isSuccess ? data : ({} as GetStoriesById),
    isLoading,
    isFetching,
  };
};

export default useGetStoreById;
