import { UseMutationResult, useMutation } from '@tanstack/react-query';

import client from '../../client';
export const url = 'api/company';

interface IParams {
  id: string;
}

const useUpdateStatusCompany = (options = {}): UseMutationResult<void, unknown, IParams, unknown> => {
  return useMutation(
    async (params: IParams) => {
      await client.patch(`${url}/${params.id}`);
    },
    {
      ...options,
    },
  );
};

export default useUpdateStatusCompany;
