import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Modal, Input, Button, Space } from 'antd';

import AsnForm from '../Form';
import { useFinishStatement } from '../../api';
import { IFinishForm, IFinishModal } from '../../types/components';
import { validate_messages } from '../../constants';
import AsnButton from '../Button';

const FinishModal: React.FC<IFinishModal> = ({ open, setOpen, id, type }) => {
  const [form] = AsnForm.useForm();
  const queryClient = useQueryClient();
  const { mutate: finishStatement } = useFinishStatement();

  const onFinish = (values: IFinishForm): void => {
    if (Number(values?.participants) >= Number(values?.completedCourses)) {
      finishStatement(
        {
          id,
          values: {
            participants: Number(values?.participants),
            completedCourses: Number(values?.completedCourses),
          },
        },
        {
          onSuccess: (): void => {
            void queryClient.invalidateQueries(['/api/statements/:id']);
            void queryClient.invalidateQueries(['/api/statements/all']);
            setOpen(false);
          },
        },
      );
    } else {
      form?.setFields([
        {
          name: ['participants'],
          errors:
            type === 'WORK'
              ? ['Դիմորդների թիվը պետք է մեծ լինի ընդունվածների թվից']
              : ['Մասնակիցների թիվը պետք է մեծ լինի ավարտածների թվից'],
        },
      ]);
    }
  };

  return (
    <Modal
      open={open}
      centered
      footer={false}
      onCancel={(): void => setOpen(false)}
      bodyStyle={{ padding: '40px 30px 20px 30px' }}
      style={{ maxWidth: 650 }}
      width={'40vw'}
    >
      <AsnForm
        layout='vertical'
        form={form}
        validateMessages={validate_messages}
        onFinish={(val): void => onFinish(val as IFinishForm)}
      >
        <AsnForm.Item
          label={type === 'WORK' ? 'Դիմորդների թիվը' : 'Մասնակիցների թիվը'}
          name='participants'
          rules={[{ required: true }]}
          normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
        >
          <Input />
        </AsnForm.Item>
        <AsnForm.Item
          label={type === 'WORK' ? 'Ընդունվածների թիվը' : 'Ավարտածների թիվը'}
          name='completedCourses'
          rules={[{ required: true }]}
          normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
        >
          <Input />
        </AsnForm.Item>
        <Space direction='horizontal' style={{ width: '100%', justifyContent: 'center' }} align='center' size={16}>
          <AsnButton onClick={(): void => setOpen(false)}>Չեղարկել</AsnButton>
          <Button htmlType='submit' type='primary'>
            Ավարտել
          </Button>
        </Space>
      </AsnForm>
    </Modal>
  );
};

export default FinishModal;
